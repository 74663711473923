import { StoredSpinachSeries } from '@spinach-shared/utils';

import { useAIMeetingDashboardCombo, useWindowSize } from '../../../hooks';
import { Column } from '../../common';
import { IntegrationsSection } from './integrations';
import { MeetingSection } from './meetings';
import { CombinedMeetingSection } from './meetings/CombinedMeetingSection';
import { TranscriptsSection } from './summaries';
import { ActiveSectionComponent, DashboardSection } from './types';

export function ActiveSection({
    activeSection,
    setModalKind,
    storedSeriesList,
    isAddingAdditionalMeetings,
    setIsAddingAdditionalMeetings,
    setStoredSeriesList,
}: ActiveSectionComponent & {
    isAddingAdditionalMeetings: boolean;
    setIsAddingAdditionalMeetings: (value: boolean) => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
}): JSX.Element {
    const isNewDashboardEnabled = useAIMeetingDashboardCombo();
    let component: JSX.Element;
    const { width } = useWindowSize();
    const isDesktopView = width > 800;
    switch (activeSection) {
        case DashboardSection.Meetings:
            component = isNewDashboardEnabled ? (
                <CombinedMeetingSection
                    storedSeriesList={storedSeriesList}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            ) : (
                <MeetingSection
                    storedSeriesList={storedSeriesList}
                    isAddingAdditionalMeetings={isAddingAdditionalMeetings}
                    setIsAddingAdditionalMeetings={setIsAddingAdditionalMeetings}
                    setStoredSeriesList={setStoredSeriesList}
                    setModalKind={setModalKind}
                />
            );
            break;
        case DashboardSection.Integrations:
            component = <IntegrationsSection setModalKind={setModalKind} />;
            break;
        case DashboardSection.Transcripts:
        case DashboardSection.Summaries:
        case DashboardSection.Actions:
            component = <TranscriptsSection />;
            break;
    }
    return (
        <Column style={{ height: '100%', width: isDesktopView ? 'calc(100% - 350px)' : '100%' }}>{component}</Column>
    );
}
