import { TextField } from '@material-ui/core';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useLandingAnalytic, useWindowSize } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { withFullStoryMasking } from '../../../utils';
import { DropDown, Row, SlidingColumn, Spacing } from '../../common';
import { useSpinachInputStyles } from '../../input';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up/PrimaryButton';
import { Stationary, StepProps } from './common';

export type AboutProps = StepProps & {
    teamKind: string;
    setTeamKind: (teamKind: string) => void;
    role: string;
    setRole: (role: string) => void;
    otherRole: string;
    setOtherRole: (otherRole: string) => void;
    howDidYouHear: string;
    setHowDidYouHear: (howDidYouHear: string) => void;
    teamKindOther: string;
    setTeamKindOther: (str: string) => void;
    howDidYouHearOther: string;
    setHowDidYouHearOther: (str: string) => void;
};

const TeamKindList = [
    {
        label: 'Product/Engineering/Design',
        code: 'product-engineering-design',
    },
    {
        label: 'Operations/Project Management',
        code: 'operations-project-management',
    },
    {
        label: 'IT',
        code: 'it',
    },
    {
        label: 'Customer Support',
        code: 'customer-support',
    },
    {
        label: 'Sales/Marketing',
        code: 'sales/marketing',
    },
    {
        label: 'Finance/Legal/HR',
        code: 'finance-legal-hr',
    },
    {
        label: 'Management',
        code: 'management',
    },
];

const TeamRoleList = [
    {
        label: 'Individual Contributor',
        code: 'ic',
    },
    {
        label: 'Team Leader',
        code: 'team-leader',
    },
    {
        label: 'Director',
        code: 'director',
    },
    {
        label: 'Executive',
        code: 'executive',
    },
];

const HowDidYouHearList = [
    {
        label: 'Someone recommended',
        code: 'someone-recommended',
    },
    {
        label: 'Saw it on a meeting',
        code: 'saw-it-on-a-meeting',
    },
    {
        label: 'Google search',
        code: 'google-search',
    },
    {
        label: 'LinkedIn',
        code: 'linkedin',
    },
    {
        label: 'TikTok',
        code: 'tiktok',
    },
    {
        label: 'Instagram',
        code: 'instagram',
    },
    {
        label: 'Newsletter',
        code: 'newsletter',
    },
    {
        label: 'YouTube',
        code: 'youtube',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

export function AboutStep({
    direction,
    loadingMessage,
    onSubmit,
    teamKind,
    setTeamKind,
    role,
    setRole,
    otherRole,
    setOtherRole,
    howDidYouHear,
    setHowDidYouHear,
    teamKindOther,
    setTeamKindOther,
    howDidYouHearOther,
    setHowDidYouHearOther,
}: AboutProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAboutStep);
    const track = useExperienceTracking();
    const isDisabled = !teamKind || !role || !howDidYouHear;
    const classes = useSpinachInputStyles({ value: '' });
    const { width } = useWindowSize();
    const dropDownButtonProps = {
        style: {
            marginTop: '5px',
            width: width < 400 ? '250px' : '330px',
            transform: width < 400 ? 'scale(0.8)' : 'unset',
            marginLeft: width < 400 ? '-25px' : 'unset',
        },
    };
    return (
        <Stationary style={{ minHeight: '490px' }}>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <HeaderThreeOnboard>Tell us about yourself</HeaderThreeOnboard>

                    <Spacing factor={1 / 2} />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>What team are you on?</BodyBigOnboard>
                    </Row>
                    <Row>
                        <DropDown
                            buttonProps={dropDownButtonProps}
                            title={'Select an option'}
                            values={TeamKindList}
                            handleSelection={(value) => {
                                setTeamKind(value);
                                track(ClientEventType.UserSelectedTeamKind, {
                                    TeamKind: value,
                                });
                            }}
                            selected={TeamKindList.find((t) => t.code === teamKind)?.label || undefined}
                        />
                    </Row>

                    {teamKind === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="What kind of team is it?"
                                    value={teamKindOther}
                                    onChange={(e) => {
                                        setTeamKindOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>What role are you in?</BodyBigOnboard>
                    </Row>
                    <Row>
                        <DropDown
                            title={'What role are you in?'}
                            buttonProps={dropDownButtonProps}
                            values={TeamRoleList}
                            handleSelection={(value) => {
                                setRole(value);
                                track(ClientEventType.UserSelectedTeamRole, {
                                    Role: value,
                                });
                            }}
                            selected={TeamRoleList.find((t) => t.code === role)?.label || undefined}
                        />
                    </Row>

                    {role === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="Your role"
                                    value={otherRole}
                                    onChange={(e) => {
                                        setOtherRole(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>How did you hear about us?</BodyBigOnboard>
                    </Row>

                    <Row>
                        <DropDown
                            buttonProps={dropDownButtonProps}
                            title={'Select an option'}
                            values={HowDidYouHearList}
                            handleSelection={(value) => {
                                track(ClientEventType.UserSelectedHowDidYouHear);
                                setHowDidYouHear(value);
                            }}
                            selected={HowDidYouHearList.find((t) => t.code === howDidYouHear)?.label || undefined}
                        />
                    </Row>

                    {howDidYouHear === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="How did you hear?"
                                    value={howDidYouHearOther}
                                    onChange={(e) => {
                                        setHowDidYouHearOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    className={withFullStoryMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                </SlidingColumn>
            </ScrollView>

            <Spacing />
            <PrimaryButton
                onClick={onSubmit}
                disabled={!!loadingMessage || isDisabled}
                isLoading={!!loadingMessage}
                title={'Next'}
            />
        </Stationary>
    );
}
