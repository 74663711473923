import { SpinachAPIPath } from '@spinach-shared/types';

import { ClientAiHistory, ViewableTranscriptLineData } from '@spinach-backend/types';

import { getSpinachAPI } from './getSpinachAPI';

export async function getAiHistories(): Promise<ClientAiHistory[] | undefined> {
    const response = await getSpinachAPI<{ histories: ClientAiHistory[] }>(SpinachAPIPath.AIHistory);

    return response?.histories;
}

export async function getAiTranscript({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<ViewableTranscriptLineData[] | undefined | null> {
    const response = await getSpinachAPI<{ results: ViewableTranscriptLineData[] | null }>(
        SpinachAPIPath.AITranscript,
        {
            params: {
                seriesId,
                botId,
            },
        }
    );

    return response?.results;
}

export async function getAiSummaryHtml({
    seriesId,
    botId,
}: {
    seriesId: string;
    botId: string;
}): Promise<string | undefined | null> {
    const response = await getSpinachAPI<{ results: string | null }>(SpinachAPIPath.AiSummaryHtml, {
        params: {
            seriesId,
            botId,
        },
    });

    return response?.results;
}
