// TODO - rename this file
import { CircularProgress, IconButton, TextField } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, SummaryJson, WebUrlQuery } from '@spinach-shared/types';
import { formatTime } from '@spinach-shared/utils';

import { ViewableTranscriptLineData } from '@spinach-backend/types';

import { getAiSummaryJson } from '../../../../apis';
import { getAiHistories, getAiSummaryHtml, getAiTranscript } from '../../../../apis/getAiHistories';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    usePaymentUrl,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, lightTheme } from '../../../../styles';
import { URLUtil, copyTextToClipboard, withFullStoryMasking } from '../../../../utils';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { DashboardSection } from '../types';
import { ActionsSectionContainer } from './ActionsSection';

const SELECTION_CODE_DELIMITER = '::';

function getHistorySelectionCode(seriesId: string, botId: string): string {
    return `${seriesId}${SELECTION_CODE_DELIMITER}${botId}`;
}

function getHistorySelectionCodeComponents(historySelectionCode: string): { seriesId: string; botId: string } {
    const [seriesId, botId] = historySelectionCode.split(SELECTION_CODE_DELIMITER);
    return { seriesId, botId };
}

export function TranscriptsSection(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const [searchParams, setSearchParams] = useSearchParams();
    const { width } = useWindowSize();
    const isDesktopView = width > 800;
    const { activeSection, setActiveSection, setToastText } = useGlobalAiDashboard();

    const [results, setResults] = useState<ViewableTranscriptLineData[] | null>(null);
    const [selection, setSelection] = useState<null | { code: string; label: string }>(null);
    const [options, setOptions] = useState<{ code: string; label: string }[]>([]);

    const [summaryHtml, setSummaryHtml] = useState('');
    const [summaryJson, setSummaryJson] = useState<SummaryJson | null>(null);

    const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
    const [isLoadingHistories, setIsLoadingHistories] = useState(false);
    const [isLoadingSummaryJson, setIsLoadingSummaryJson] = useState(false);

    const shouldShowTicketSuggestions = user.isActionItemTicketCreationEnabled;

    const isLoading =
        isLoadingHistories || isLoadingTranscript || (isLoadingSummaryJson && shouldShowTicketSuggestions);

    const hasProFeatures = useProFeatures();
    const freeOptionsCodes = options.slice(0, 3).map((option) => option.code);
    const isSelectedTranscriptLimited = !hasProFeatures && selection && !freeOptionsCodes.includes(selection.code);
    const paymentUrl = usePaymentUrl();

    async function fetchSelectedResults(selection: { code: string; label: string }) {
        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        setIsLoadingTranscript(true);
        setIsLoadingSummaryJson(true);
        const [transcriptResponse, summaryHtmlResponse, summaryJsonResponse] = await Promise.all([
            getAiTranscript({ seriesId, botId }),
            getAiSummaryHtml({ seriesId, botId }),
            shouldShowTicketSuggestions ? getAiSummaryJson(botId, false) : undefined,
        ]);
        setIsLoadingTranscript(false);
        setIsLoadingSummaryJson(false);

        if (summaryJsonResponse && 'summaryJson' in summaryJsonResponse) {
            setSummaryJson(summaryJsonResponse.summaryJson);
        } else {
            setSummaryJson(null);
        }

        if (transcriptResponse) {
            setResults(transcriptResponse);
        } else {
            setResults(null);
        }

        if (summaryHtmlResponse) {
            setSummaryHtml(summaryHtmlResponse);
        } else {
            setSummaryHtml('');
        }
    }

    function onCopySummary(summaryHtml: string) {
        if (!selection) {
            return;
        }

        if (!summaryHtml) {
            return;
        }

        const { seriesId, botId } = getHistorySelectionCodeComponents(selection.code);

        copyTextToClipboard(summaryHtml);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Copy Summary Button',
            BotId: botId,
            SeriesId: seriesId,
        });

        setToastText('Summary copied to clipboard');
    }

    useEffect(() => {
        async function exec() {
            setIsLoadingHistories(true);
            const histories = await getAiHistories();

            setIsLoadingHistories(false);

            if (!histories) {
                return;
            }

            const mapped = histories.map((h) => ({
                code: getHistorySelectionCode(h.seriesId, h.botId),
                label: `${h.meetingTitle} (${moment(h.createdAt).format('MM/DD/YYYY')})`,
            }));
            setOptions(mapped);

            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Transcripts Loaded',
                TranscriptCount: mapped.length,
            });

            const deepSeriesId = searchParams.get(WebUrlQuery.SeriesId);
            const deepBotId = searchParams.get(WebUrlQuery.BotId);
            const deepSelection =
                !!deepBotId && !!deepSeriesId
                    ? mapped.find((option) => option.code === getHistorySelectionCode(deepSeriesId, deepBotId))
                    : null;

            const isUnpaidTranscript =
                deepSelection?.code &&
                !hasProFeatures &&
                !mapped
                    .slice(0, 3)
                    .map((option) => option.code)
                    .includes(deepSelection.code);

            if (isUnpaidTranscript) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Limited Transcript',
                });
                setSelection(deepSelection);
                setResults(null);
            } else if (deepSelection && deepSeriesId && !!user.getSeriesById(deepSeriesId)) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Loaded Deep-Linked Transcript',
                });

                setSelection(deepSelection);
                await fetchSelectedResults(deepSelection);
            } else if (mapped[0]) {
                setSelection(mapped[0]);
                await fetchSelectedResults(mapped[0]);
            }

            searchParams.delete(WebUrlQuery.SeriesId);
            searchParams.delete(WebUrlQuery.BotId);
            searchParams.delete(WebUrlQuery.EditSuccess);
            setSearchParams(searchParams);
        }
        exec();
    }, []);

    return (
        <>
            <Spacing factor={2} />
            <Row vCenter>
                <Autocomplete
                    id="summaries-and-transcripts-id-selection"
                    options={options}
                    getOptionLabel={(option: { code: string; label: string }) => {
                        if (!hasProFeatures && !freeOptionsCodes.includes(option.code)) {
                            return `${option.label} (Spinach Pro Only)`;
                        }

                        return option.label;
                    }}
                    value={selection}
                    size="small"
                    style={{
                        minWidth: '60%',
                        marginBottom: '10px',
                        width: '100%',
                        maxWidth: '600px',
                    }}
                    autoComplete
                    onChange={async (event, selection) => {
                        setSelection(selection);

                        if (!selection) {
                            setSelection(null);
                            setResults(null);
                            setSummaryHtml('');
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Cleared Transcript Selection',
                            });
                            return;
                        }

                        if (!hasProFeatures && !freeOptionsCodes.includes(selection.code)) {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Selected Limited Transcript to Open',
                            });
                            setResults(null);
                            setSummaryHtml('');
                            return;
                        }

                        await fetchSelectedResults(selection);

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Selected Transcript to Open',
                        });
                    }}
                    className={withFullStoryMasking()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Choose a meeting"
                            placeholder="Choose a meeting"
                        />
                    )}
                />
                {isLoading ? (
                    <CircularProgress
                        size={'30px'}
                        style={{ marginLeft: '10px', marginBottom: '15px', color: lightTheme.primary.orangeDark }}
                    />
                ) : (
                    <span style={{ width: '40px' }} />
                )}
            </Row>
            {!hasProFeatures ? (
                <Row style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <BodyRegularOnboard>
                        Explore your recent summaries and transcripts with Spinach.{' '}
                        <Anchor
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Click Upgrade to Pro (Transcripts Section Subtitle)`,
                                });
                                URLUtil.openURL(paymentUrl, {
                                    redirectThroughBackendWhenOnZoom: true,
                                });
                            }}
                        >
                            Upgrade to Pro
                        </Anchor>{' '}
                        to view them all.
                    </BodyRegularOnboard>
                </Row>
            ) : null}
            <Row>
                <Row style={{ width: '100%', maxWidth: '640px', minWidth: 'calc(60% + 40px)' }}>
                    <Row style={{ width: 'unset', flexGrow: 1 }}>
                        {shouldShowTicketSuggestions ? (
                            <SecondaryButton
                                title="Actions"
                                buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Actions
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Action Tab' });
                                    setActiveSection(DashboardSection.Actions);
                                }}
                            />
                        ) : (
                            <></>
                        )}
                        <SecondaryButton
                            title="Summary"
                            buttonStyles={{ textDecoration: 'none', marginRight: '30px' }}
                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                            color={
                                activeSection === DashboardSection.Summaries
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summary Tab' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                        <SecondaryButton
                            title="Transcript"
                            buttonStyles={{ textDecoration: 'none' }}
                            labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                            color={
                                activeSection === DashboardSection.Transcripts
                                    ? lightTheme.primary.greenLight
                                    : lightTheme.primary.midnight
                            }
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Transcript Tab' });
                                setActiveSection(DashboardSection.Transcripts);
                            }}
                        />
                    </Row>
                    {activeSection === DashboardSection.Summaries ? (
                        <IconButton
                            onClick={() => onCopySummary(summaryHtml)}
                            disableRipple
                            disabled={!summaryHtml}
                            style={{ justifyContent: 'end' }}
                            size="small"
                        >
                            <FileCopyIcon
                                htmlColor={!!summaryHtml ? lightTheme.primary.greenLight : lightTheme.neutrals.grayDark}
                                fontSize="small"
                            />
                        </IconButton>
                    ) : null}
                    <span style={{ width: '40px' }} />
                </Row>
            </Row>
            {!!results?.length ? (
                <>
                    <Spacing factor={1 / 2} />
                    <ScrollArea sidePadding={0} style={{ width: isDesktopView ? '90%' : '100%', marginBottom: '20px' }}>
                        {activeSection === DashboardSection.Actions && selection ? (
                            <ActionsSectionContainer
                                summaryJson={summaryJson}
                                setSummaryJson={setSummaryJson}
                                botId={getHistorySelectionCodeComponents(selection.code).botId}
                            />
                        ) : (
                            <></>
                        )}
                        {activeSection === DashboardSection.Summaries ? (
                            <Column>
                                {summaryHtml ? (
                                    <div
                                        className={withFullStoryMasking()}
                                        style={{ fontFamily: 'Gilroy' }}
                                        dangerouslySetInnerHTML={{ __html: summaryHtml }}
                                    />
                                ) : (
                                    <Row>
                                        <BodyRegular>No summary found for this meeting.</BodyRegular>
                                    </Row>
                                )}
                            </Column>
                        ) : null}
                        {activeSection === DashboardSection.Transcripts &&
                            results.map((blurb, i) => {
                                return (
                                    <Column key={i} style={{ marginBottom: '10px' }}>
                                        <Row>
                                            <span style={{ color: 'gray', minWidth: '45px' }}>
                                                {formatTime(blurb.timestamp)}
                                            </span>
                                            <b
                                                style={{ marginLeft: '5px', minWidth: '150px', marginRight: '5px' }}
                                                className={withFullStoryMasking()}
                                            >
                                                {blurb.speaker}
                                            </b>
                                        </Row>
                                        <Row
                                            style={{ marginLeft: '50px', width: 'calc(100% -  50px)' }}
                                            className={withFullStoryMasking()}
                                        >
                                            {blurb.statement}
                                        </Row>
                                    </Column>
                                );
                            })}
                    </ScrollArea>
                </>
            ) : !options.length && !isLoadingHistories ? (
                <>
                    <Spacing factor={1 / 2} />
                    <Row>
                        <BodyRegular>No summaries or transcripts available.</BodyRegular>
                    </Row>
                </>
            ) : isSelectedTranscriptLimited ? (
                <>
                    <Spacing factor={1 / 2} />
                    <Row>
                        <BodyRegular>
                            You can access older summaries and transcripts by{' '}
                            <Anchor
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Click Upgrade to Pro (Opened Limited Transcript)`,
                                    });
                                    URLUtil.openURL(paymentUrl, {
                                        redirectThroughBackendWhenOnZoom: true,
                                    });
                                }}
                            >
                                Upgrading to Pro
                            </Anchor>
                            .
                        </BodyRegular>
                    </Row>
                </>
            ) : (!results || !results.length) && !isLoadingTranscript && !!options.length && !!selection ? (
                <>
                    <Spacing factor={1 / 2} />
                    <Row>
                        <BodyRegular>No summary or transcript found for this meeting.</BodyRegular>
                    </Row>
                </>
            ) : null}
        </>
    );
}
