import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import { ClientAiHistory } from '@spinach-backend/types';

import { getSpinachAPI } from '../../apis';
import { useExperienceTracking, useGlobalRouting } from '../../hooks';
import { FYI, FYIState, ViewContainer } from '../common';
import { DashboardSection } from './dashboard/types';

export function LoadLastSummary(): JSX.Element {
    const { routeToAIDashboard } = useGlobalRouting();
    const [params] = useSearchParams();
    const track = useExperienceTracking();

    useEffect(() => {
        async function exec() {
            const response = await getSpinachAPI<{ histories: ClientAiHistory[] }>(SpinachAPIPath.AIRecentHistory);

            params.delete(WebUrlQuery.Redirect);
            params.set(WebUrlQuery.Section, DashboardSection.Summaries);

            if (response?.histories.length) {
                const history = response.histories[0];
                await track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Deep Linked to Summary',
                    BotId: history.botId,
                    SeriesId: history.seriesId,
                    Version: 2,
                });

                params.set(WebUrlQuery.BotId, history.botId);
                params.set(WebUrlQuery.SeriesId, history.seriesId);
                routeToAIDashboard({ replace: true }, params);
            } else {
                await track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Deep Linked to Summary Failure',
                    Version: 2,
                });
                routeToAIDashboard({ replace: true }, params);
            }
        }
        exec();
    }, []);

    return (
        <ViewContainer>
            <FYI state={FYIState.Loading} />
        </ViewContainer>
    );
}
