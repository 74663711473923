import { Modal, TextField } from '@material-ui/core';
import { useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { SeriesCreationSource } from '@spinach-shared/types';

import { PrimaryButton, Spacing, useSpinachInputStyles } from '..';
import { getUser, postSeries } from '../../apis';
import { Titles } from '../../constants';
import { useGlobalAuthedUser, useGlobalNullableStoredSeries } from '../../hooks';
import { SetValue } from '../../types';
import { withFullStoryMasking } from '../../utils';
import SecondaryButton from '../stand-up/SecondaryButton';
import { CenteredRow, ErrorBodySubtitle, LeftBodyLarge, ModalContent } from './common';

export function CreateStandupModal({
    isOpen,
    onClose,
    user,
    setShouldShowInviteModal,
}: {
    isOpen: boolean;
    onClose: () => void;
    user: ClientUser;
    setShouldShowInviteModal: SetValue<boolean>;
}): JSX.Element {
    const [, setSeries] = useGlobalNullableStoredSeries();
    const [, setUser] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const [newSeriesName, setNewSeriesName] = useState('');
    const classes = useSpinachInputStyles({ value: newSeriesName });
    const userHasSeriesWithSameName = !!user.getSeriesByName(newSeriesName);

    const error = userHasSeriesWithSameName ? 'Please use a different name' : ' ';

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalContent>
                <LeftBodyLarge>Name your Standup</LeftBodyLarge>

                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    className={withFullStoryMasking(classes.root)}
                    fullWidth
                    placeholder="Dream Team's Standup"
                    value={newSeriesName}
                    autoFocus
                    onChange={(e) => {
                        setNewSeriesName(e.target.value.substring(0, 50));
                    }}
                />

                <ErrorBodySubtitle>{error}</ErrorBodySubtitle>

                <Spacing factor={2} />

                <PrimaryButton
                    title={Titles.SaveStandup}
                    isLoading={isLoading}
                    disabled={!newSeriesName || userHasSeriesWithSameName}
                    onClick={async () => {
                        setIsLoading(true);
                        const series = await postSeries({
                            createdBy: user.spinachUserId,
                            name: newSeriesName,
                            type: 'standup',
                            metadata: {
                                creationSource: SeriesCreationSource.LegacyDashboard,
                            },
                        });

                        const userResponse = await getUser();

                        if (userResponse.user) {
                            setUser(userResponse.user);
                            setSeries(series);
                        } else {
                            window.location.reload();
                        }

                        setShouldShowInviteModal(true);
                        setIsLoading(false);
                    }}
                />

                <Spacing factor={0.66} />

                <CenteredRow>
                    <SecondaryButton title={Titles.Cancel} onClick={onClose} />
                </CenteredRow>
            </ModalContent>
        </Modal>
    );
}
