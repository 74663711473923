import { icebreakerUpdateSectionTypeProps, sectionTypeList } from '@spinach-shared/constants';
import {
    BaseAgendaProps,
    BaseMeetingProps,
    BaseSeriesProps,
    YTBAgendaItemProps,
    YTBUpdateProps,
} from '@spinach-shared/models';
import {
    AuthoredUpdate,
    FeatureToggle,
    ISOString,
    ReservedAgendaTitle,
    ResolverMetadata,
    Sentiment,
    SpinachUpdateType,
    TICKET_SOURCE_MAP,
    Ticket,
    TicketSource,
    TypedUpdate,
    TypedUpdateWrapper,
    UpdateSectionTypeProps,
    UserMood,
} from '@spinach-shared/types';
import {
    doesUpdateSectionHaveTypedUpdates,
    formatTime,
    getFormattedDate,
    getTicketSource,
} from '@spinach-shared/utils';

const authorStyle =
    'list-style-type: none; width: fit-content; margin-left: 37px; font-weight: 700; font-size: 12px; line-height: 115%; flex-direction: row; align-items: center; color: #3E3E49; border: 1px solid #ebf4ff; border-radius: 2px;margin-top: 4px;padding-top: 0px;padding-bottom: 0px;padding-left: 2px;padding-right: 8px;background-color: #e0eeff;';

type WithUpdateListItemOpts = {
    sentiment?: Sentiment;
    ticket?: Ticket;
    ticketSource?: TicketSource;
    resolvers?: ResolverMetadata[];
    subItems?: TypedUpdate[];
    author?: string;
};

const sentimentEmojiMap: Record<Sentiment, string> = {
    [Sentiment.Good]: '🟢',
    [Sentiment.Ok]: '🟡',
    [Sentiment.Bad]: '🔴',
};

const userMoodEmojiMap: Record<Sentiment, string> = {
    [Sentiment.Good]: '💚',
    [Sentiment.Ok]: '💛',
    [Sentiment.Bad]: '🔴',
};

const convertSentimentIntoEmoji = (sentiment?: Sentiment) => (sentiment ? sentimentEmojiMap[sentiment] : '');

const withTeammateName = (richTextSummary: string, name: string, totalTime: number): string => `
    ${richTextSummary}
    <p>
        <b>${name}</b> (${formatTime(totalTime)})
    </p> 
`;

const withUpdateListItem = (richUpdateListItem: string, text: string, opts: WithUpdateListItemOpts): string => {
    return `
            ${richUpdateListItem}
            ${withUpdate(text, opts)}
            
        `;
};

const withUpdatelessSection = (richTextSummary: string, text: string): string => `
    ${richTextSummary}
    <ul>
        <li>
            <span>${text}</span>
        </li>
    </ul>
`;

const withUpdateSection = (richTextSummary: string, sectionTitle: string, updateListItems: string): string => `
    ${richTextSummary}
    <ul>
        <li>${sectionTitle}</li>
        <ul>
            ${updateListItems}
        </ul>
    </ul> 
`;

const withParkingLot = (richTextSummary: string, updateItems: YTBAgendaItemProps[], totalTime: number) => `
    ${richTextSummary}
    <p><b>${ReservedAgendaTitle.TeamTopics}</b> (${formatTime(totalTime)})</p>

    <ul>
        ${updateItems
            .map(
                (item) => `
            <li>${item.title}</li>
            <ul>
                ${item.standUpUpdate.updates
                    .filter((u) => u.updateType === SpinachUpdateType.ParkingLot)
                    .map((topic) => `<li>${topic.text}</li>`)
                    .join('')}
            </ul>
        `
            )
            .join('')}
    </ul>
    <br />
`;

const withLineBreak = (richTextSummary: string) => `
    ${richTextSummary}
    <br />
`;

const withStandUpHeader = (agendaStartedAt?: ISOString): string => `
    <h3><b>Stand Up Summary${agendaStartedAt ? ` - ${getFormattedDate(agendaStartedAt)}` : ''}</b></h3>
    <br />
`;

const withUserMoods = (richTextSummary: string, userMoods: UserMood[]): string => `
    ${richTextSummary}
    <p><b>Team Mood</b></p>
    <ul>
    ${userMoods
        .filter((userMood) => !!userMood.sentiment)
        .map(
            (userMood) => `
                <li>${userMood.displayName}</li> 
                <ul>
                    <li>${userMood.sentiment ? userMoodEmojiMap[userMood.sentiment] : ''}${
                userMood.details ? ` ${userMood.details}` : ''
            }
                    </li>
                </ul>`
        )
        .join('')}
    </ul>
    <br/>
`;

const withSpinachFooter = (richTextSummary: string, withFooter = true) => {
    if (withFooter) {
        return `
            ${richTextSummary}
            <b>Powered by Spinach.io</b>
        `;
    } else {
        return richTextSummary;
    }
};

function withUpdate(text: string, opts: WithUpdateListItemOpts) {
    const { ticket, sentiment, ticketSource, author } = opts;

    const subItemsToRender = opts.subItems?.filter((i) => !!i.text);

    if (ticket) {
        return `
            <li>
                ${ticketSource === TICKET_SOURCE_MAP.Jira ? convertSentimentIntoEmoji(sentiment) : ''} ${text}
            </li>
            ${
                subItemsToRender?.length
                    ? `<ul>
                ${subItemsToRender.map((item) => `<li>${item.text}</li>`).join('')}
                ${author ? `<li ${authorStyle}>${author}</li>` : ''}</ul>`
                    : ''
            }
            ${
                /**
                 * @NOTE Asana tickets operate differently than Jira ticktets,
                 * so this logic is still relevant for Asana tickets
                 * */
                ticketSource !== TICKET_SOURCE_MAP.Jira
                    ? `
                    <ul>
            <li>
             ${ticket.projectName || ''} ${ticket.title}
            </li></ul>`
                    : ''
            }
            ${
                opts.resolvers?.length
                    ? `<ul>
                    ${opts.resolvers
                        .map((resolver) => `<li><b>${resolver.preferredName}</b>:<i>${resolver.details}</i></li>`)
                        .join('')} </ul>`
                    : ''
            }
        `;
    } else {
        return `
            <li>
                ${!!sentiment ? convertSentimentIntoEmoji(sentiment) : ''} ${text}
            </li>
                ${
                    subItemsToRender?.length
                        ? `<ul>
                ${subItemsToRender.map((item) => `<li>${item.text}</li>`).join('')}</ul>`
                        : ''
                }
                ${
                    opts.resolvers?.length
                        ? `<ul>
                        ${opts.resolvers
                            .map((resolver) => `<li><b>${resolver.preferredName}</b>:<i>${resolver.details}</i></li>`)
                            .join('')} </ul>`
                        : ''
                }
        `;
    }
}

function withTopItems(summary: string, ytbAgendaItems: YTBAgendaItemProps[]): string {
    const blockers = ytbAgendaItems.filter((u) => u.standUpUpdate.hasSentimentBlockerItems);
    const blockerTypedUpdatesWithAuthor: AuthoredUpdate[] = blockers
        .map((b) =>
            b.standUpUpdate.updates
                .filter((u) => (u.sentiment && u.sentiment === Sentiment.Bad) || u.sentiment === Sentiment.Ok)
                .map((u) => ({ ...u, author: b.title }))
        )
        .flat();

    const sortedBlockerTypedUpdates = blockerTypedUpdatesWithAuthor.sort((u) =>
        u.sentiment === Sentiment.Bad ? -1 : 1
    );

    if (!sortedBlockerTypedUpdates.length) {
        return '';
    }
    return `<b>Top Items</b>
        <br/>
        ${sortedBlockerTypedUpdates
            .map((update) => {
                const ticketSource = getTicketSource(update);
                const wrapper = new TypedUpdateWrapper(update);
                return `
                ${withUpdate(update.text, {
                    ticket: wrapper.ticket,
                    ticketSource,
                    sentiment: wrapper.sentiment,
                    subItems: update.subItems,
                })}
                <p style="${authorStyle}" >${update.author}</p>`;
            })
            .join('\n')}
        ${summary}`;
}

const withIcebreakerResponses = (richTextSummary: string, meeting: BaseMeetingProps): string => {
    if (!meeting.isIcebreakerLockedIn) {
        return richTextSummary;
    }

    const icebreakerSections = meeting.agenda.YTBItems.reduce((prev, curr) => {
        if (curr.icebreakerUpdates.length) {
            return `
            ${prev}
            ${withUpdateSection(
                '',
                curr.title,
                getUpdatesListItems(curr.standUpUpdate, icebreakerUpdateSectionTypeProps)
            )}
            `;
        }

        return prev;
    }, '');

    return `
        ${richTextSummary}
        <b>${meeting.icebreakerQuestionPrompt}</b>
        ${icebreakerSections}
        <br />
    `;
};

function buildListItemsFromUpdates(updates: TypedUpdate[]): string {
    const listItems = updates.reduce((section, update) => {
        const ticketSource = getTicketSource(update);
        const wrapper = new TypedUpdateWrapper(update);
        section = withUpdateListItem(section, update.text, {
            ticket: wrapper.ticket,
            ticketSource,
            sentiment: wrapper.sentiment,
            subItems: update.subItems,
            resolvers: update.resolvers,
        });

        return section;
    }, '');

    return listItems;
}

function getUpdatesListItems(standUpUpdate: YTBUpdateProps, typeProps: UpdateSectionTypeProps): string {
    const updatesOfSection = standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType);

    return buildListItemsFromUpdates(updatesOfSection);
}

function withPrefacingText(agenda: BaseAgendaProps, withHeader = true): string {
    const header = withHeader ? withStandUpHeader(agenda.startedAt) : '';
    const summary = withTopItems(header, agenda.YTBItems);

    return agenda.YTBItemsWithParkingLotUpdates.length && agenda.parkingLotItem
        ? withParkingLot(summary, agenda.YTBItemsWithParkingLotUpdates, agenda.parkingLotItem.totalTime)
        : summary;
}

export type FormatSummaryHTMLOptions = {
    withHeader?: boolean;
    withFooter?: boolean;
};

const DEFAULT_OPTIONS: FormatSummaryHTMLOptions = {
    withHeader: true,
    withFooter: true,
};

export function formatSummaryHTML(
    meeting: BaseMeetingProps,
    series: BaseSeriesProps,
    options = DEFAULT_OPTIONS
): string {
    const agenda = meeting.agenda;
    let summary = agenda.YTBItems.reduce<string>((richTextSummary, item) => {
        richTextSummary = withTeammateName(richTextSummary, item.title, item.totalTime);

        const isUpdateSectionPopulated = doesUpdateSectionHaveTypedUpdates(item.standUpUpdate);
        const isUpdateEntirelyEmpty = !sectionTypeList.some(isUpdateSectionPopulated);
        const isIssueBasedEnabled = !!series.featureToggles?.[FeatureToggle.IssueBased];
        const isIssueBasedCheckinEmpty = isIssueBasedEnabled && item.standUpUpdate.hasNoIssueBasedUpdates;

        if (isUpdateEntirelyEmpty || isIssueBasedCheckinEmpty) {
            richTextSummary = withUpdatelessSection(richTextSummary, 'No updates today');
        } else {
            sectionTypeList
                .filter((s) => series.isComponentEnabled(s.spinachUpdateType))
                .filter(isUpdateSectionPopulated)
                .forEach((updateSectionTypePropsWithUpdates) => {
                    const listItems = getUpdatesListItems(item.standUpUpdate, updateSectionTypePropsWithUpdates);
                    richTextSummary = withUpdateSection(
                        richTextSummary,
                        updateSectionTypePropsWithUpdates.title ?? '',
                        listItems
                    );
                });

            series.enabledCustomRoundtableLists.forEach((l) => {
                const customUpdates = item.standUpUpdate.getCustomUpdatesForList(l.id);
                if (customUpdates.length) {
                    const listItems = buildListItemsFromUpdates(customUpdates);
                    richTextSummary = withUpdateSection(richTextSummary, l.title, listItems);
                }
            });
        }
        richTextSummary = withLineBreak(richTextSummary);

        return richTextSummary;
    }, withPrefacingText(agenda, options.withHeader));

    summary = withIcebreakerResponses(summary, meeting);

    if (meeting.userMoods.length && meeting.userMoods.some((userMood) => !!userMood.sentiment)) {
        summary = withUserMoods(summary, meeting.userMoods);
    }

    summary = withSpinachFooter(summary, options.withFooter);

    return summary;
}
