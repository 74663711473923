import { TextField } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import { ClientEventType, SlackTeamType } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { getUser, patchSeries } from '../../../../apis';
import { deleteSlackSeriesSettings } from '../../../../apis/deleteSlackSeriesSettings';
import { postSlackChannel } from '../../../../apis/postSlackChannel';
import { GlobalModal } from '../../../../atoms';
import {
    useEmailSummaryToInviterOnly,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useIntegrationDetection,
    usePaymentUrl,
    useProFeatures,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { BodyRegularOnboard, BodySmallOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { URLUtil, withFullStoryMasking } from '../../../../utils';
import { Anchor, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { EmailOptions } from '../EmailOptions';

export function MeetingCommunicationDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();

    const hasProFeatures = useProFeatures();
    const { slackState } = useGlobalSlack();
    const startDetection = useIntegrationDetection();
    const {
        slackState: { slackChannels },
    } = useGlobalSlack();

    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();
    const [, setGlobalModal] = useGlobalModal();
    const paymentUrl = usePaymentUrl();

    const track = useExperienceTracking();

    const [, setGlobalStoredSeries] = useGlobalNullableStoredSeries();
    let preseelectedChannel: { code: string; label: string } | null = null;
    if (storedSeries.slackChannel?.channelId && storedSeries.slackChannel?.channelName) {
        preseelectedChannel = {
            code: storedSeries.slackChannel?.channelId,
            label: storedSeries.slackChannel?.channelName,
        };
    }

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(preseelectedChannel);
    return (
        <>
            <Row>
                <BodyRegularOnboard>
                    <b>Email:</b>{' '}
                    <>
                        {/* if some folks received early access to email inviter only but havent paid yet, still give them access */}
                        {hasProFeatures || isEmailInviterSummaryInviterOnly ? (
                            <EmailOptions
                                isEmailingIcpOnlySetting={storedSeries.isEmailingIcpOnly}
                                series={storedSeries}
                                onSelect={async (isEmailingIcpOnly) => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Selected Email Recipients Option (Meeting Section)`,
                                    });

                                    await patchSeries(storedSeries.id, {
                                        metadata: {
                                            scribeMetadata: {
                                                isEmailingIcpOnly,
                                            },
                                        },
                                    });
                                }}
                            />
                        ) : (
                            <>
                                <span>All participants</span> (
                                <Anchor
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Click Upgrade Email Options (Meeting Section)`,
                                        });
                                        URLUtil.openURL(paymentUrl, {
                                            redirectThroughBackendWhenOnZoom: true,
                                        });
                                    }}
                                >
                                    Upgrade to Pro
                                </Anchor>{' '}
                                to limit)
                            </>
                        )}
                    </>
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 4} />
            <Row>
                <span style={{ width: '70px' }} />
                <SecondaryButton
                    size={ButtonSize.Small}
                    title={`Manage Additional Recipients (${storedSeries.dailySubscribedEmails.length})`}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Manage additional recipients (Meeting Section)',
                        });
                        setGlobalStoredSeries(storedSeries);
                        setGlobalModal(GlobalModal.SubscribeSeriesOutput);
                    }}
                />
            </Row>
            <Spacing />
            <Row style={{ alignItems: 'center' }}>
                <BodyRegularOnboard>
                    <b>Slack:</b>
                </BodyRegularOnboard>
                {user.isAuthedForSlack ? (
                    <Autocomplete
                        id="slack-channel-id-selection"
                        className={withFullStoryMasking()}
                        options={slackChannels}
                        getOptionLabel={(option: { code: string; label: string }) => option.label}
                        value={selectedChannel}
                        size="small"
                        style={{
                            width: '300px',
                            marginBottom: '2px',
                            transform: 'scale(0.8)',
                        }}
                        ListboxProps={{ style: { transform: 'scale(0.8)' } }}
                        autoComplete
                        onChange={async (event, channel) => {
                            setSelectedChannel(channel);

                            track(ClientEventType.SelectSlackChannelInSettingsClick, {
                                Action: !!channel ? 'setting-channel' : 'removing-channel',
                            });

                            let slackSettings = user.slackSettings;

                            if (!slackSettings?.teamId || !slackSettings?.teamType) {
                                const freshUserRes = await getUser();
                                if (freshUserRes.user?.integrationSettings?.slackSettings) {
                                    slackSettings = freshUserRes.user?.integrationSettings?.slackSettings;
                                } else {
                                    return;
                                }
                            }

                            let updatedSeries;

                            if (channel) {
                                updatedSeries = await postSlackChannel(
                                    slackSettings.teamId,
                                    slackSettings.teamType as SlackTeamType,
                                    channel.code,
                                    channel.label.replaceAll('#', ''),
                                    storedSeries.slug
                                );
                            } else {
                                updatedSeries = await deleteSlackSeriesSettings(storedSeries.slug);
                            }

                            if (updatedSeries) {
                                const instance = new StoredSpinachSeries(updatedSeries);
                                const newList = storedSeriesListState.storedSeriesList.map((series) => {
                                    if (series.id === instance.id) {
                                        return instance;
                                    } else {
                                        return series;
                                    }
                                });
                                setStoredSeriesList(newList);
                            }

                            const updatedUser = await getUser();

                            if (updatedUser?.user) {
                                setUser(updatedUser.user);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Pick a channel"
                                placeholder="Pick a channel"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        borderRadius: '20px',
                                        padding: '4px 20px',
                                        borderColor: `${lightTheme.primary.green} !important`,
                                    },
                                }}
                            />
                        )}
                    />
                ) : (
                    <OutlinedButton
                        containerStyles={{ marginLeft: '10px' }}
                        title={'Connect'}
                        disabled={!slackState.installUrl}
                        onClick={() => {
                            if (!slackState.installUrl) {
                                return;
                            }
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Connect to Slack via Meeting Details`,
                            });
                            startDetection();
                            URLUtil.openURL(slackState.installUrl);
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>
            {user.isAuthedForSlack ? (
                <Row>
                    <BodySmallOnboard style={{ marginLeft: '10px' }}>
                        Don’t see your channel? To connect a private channel refer to{' '}
                        <Anchor
                            onClick={() => {
                                track(ClientEventType.HowToConnectSlackPrivateChannelClick);
                                URLUtil.openURL(`${INTERCOM_SUPPORT_URL}/articles/7967741-slack-integration`, {
                                    redirectThroughBackendWhenOnZoom: true,
                                });
                            }}
                        >
                            these instructions
                        </Anchor>
                    </BodySmallOnboard>
                </Row>
            ) : null}
        </>
    );
}
