export enum CalendarSource {
    Google = 'googleCalendar',
    Microsoft = 'microsoftCalendar',
}

export enum CalendarEventType {
    Default = 'default',
    FocusTime = 'focusTime',
    outOfOffice = 'outOfOffice',
    workingLocation = 'workingLocation',
}
export type CalendarEventListOpts = {
    alwaysIncludeEmail?: boolean;
    calendarId?: string;
    iCalUID?: string;
    maxAttendees?: number;
    maxResults?: number;
    userIp?: string;
    orderBy?: string;
    pageToken?: string;
    privateExtendedProperty?: string[];
    q?: string;
    sharedExtendedProperty?: string[];
    showDeleted?: boolean;
    singleEvents?: boolean;
    showHiddenInvitations?: boolean;
    syncToken?: string;
    timeMax?: string;
    timeMin?: string;
    timeZone?: string;
    updatedMin?: string;
};

export type CalendarEventAttendee = {
    email?: string | null;
    /**
     * @TODO double check Microsoft, they may use different termonology, or this may be a .ics standard
     * Unfortunately must also include | string to avoid refactoring exising work
     * because of the way Google Calendar defines this type and the way we rely upon and use that type
     */
    responseStatus?: 'needsAction' | 'declined' | 'tentative' | 'accepted' | string | null;
};

export type CalendarEvent = {
    id?: string | null;
    iCalUID?: string | null;
    attendees?: CalendarEventAttendee[];
    summary?: string | null;
    organizer?: CalendarEventAttendee | null;
    created?: string | null;
    recurringEventId?: string | null;
    eventType?: CalendarEventType | null;
    showAs?: 'unknown' | 'free' | 'tentative' | 'busy' | 'oof' | 'workingElsewhere' | null;
    start?: {
        dateTime?: string | null;
        timeZone?: string | null;
    } | null;
    end?: {
        dateTime?: string | null;
        timeZone?: string | null;
    } | null;
};
