import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { fetchSlackChannels } from '../../../apis/fetchSlackChannels';
import { useExperienceTracking, useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyRegularOnboard, HeaderThreeOnboard } from '../../../styles';
import { withFullStoryMasking } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type SlackDefaultsProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
    selectedChannel: { label: string; code: string } | null;
    setSelectedChannel: (channel: { label: string; code: string } | null) => void;
};

export function SlackDefaults({
    onBack,
    direction,
    onSkip,
    onSubmit,
    selectedChannel,
    setSelectedChannel,
    loadingMessage,
}: SlackDefaultsProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedSlackDefaultsStep);
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const [selectionLoadingText, setSelectionLoadingText] = useState('');
    const [fetchedSlackChannels, setFetchedSlackChannels] = useState<{ code: string; label: string }[]>([]);

    useEffect(() => {
        async function fetchChannels() {
            if (!user.isAuthedForSlack) {
                return;
            }

            const teamId = user.slackSettings?.teamId;
            const teamType = user.slackSettings?.teamType;

            setSelectionLoadingText('Getting channels...');
            const channels = await fetchSlackChannels(teamId, teamType, user.spinachUserId);
            setFetchedSlackChannels(
                channels.map((c) => ({
                    code: c.id,
                    label: `#${c.name}`,
                }))
            );
            setSelectionLoadingText('');
        }
        fetchChannels();
    }, [user.isAuthedForSlack, user.slackSettings]);

    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Pick your default Slack channel</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch style={{ maxWidth: '500px' }}>
                <SlidingColumn centered direction={direction}>
                    <Spacing factor={1 / 2} />
                    <Row centered>
                        <BodyRegularOnboard style={{ textAlign: 'center' }}>
                            We'll send your meeting notes to this channel. Don't worry, you can choose specific Slack
                            channels for each meeting in your dashboard.
                        </BodyRegularOnboard>
                    </Row>

                    <Spacing factor={1 / 2} />

                    <Row centered>
                        <Autocomplete
                            id="slack-channel-id-selection"
                            className={withFullStoryMasking()}
                            options={fetchedSlackChannels}
                            getOptionLabel={(option: { code: string; label: string }) => option.label}
                            value={selectedChannel}
                            size="small"
                            style={{ width: '300px', marginBottom: '10px' }}
                            loading={!!selectionLoadingText}
                            loadingText={selectionLoadingText}
                            autoComplete
                            onChange={async (event, channel) => {
                                setSelectedChannel(channel);

                                if (!channel) {
                                    return;
                                }

                                track(ClientEventType.UserSelectedSlackDefaultChannel);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Pick a channel"
                                    placeholder="Pick a channel"
                                />
                            )}
                        />
                    </Row>
                </SlidingColumn>
            </ScrollView>
            <Row centered>
                <PrimaryButton
                    title={'Done'}
                    onClick={onSubmit}
                    disabled={!selectedChannel}
                    isLoading={!!loadingMessage}
                    loadingText={loadingMessage}
                />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton onClick={onSkip} title="Skip" />
            </Row>
        </Stationary>
    );
}
