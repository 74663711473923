import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { UserSeriesMetadata } from '@spinach-shared/types';
import { isDemoSeries, isProductionStage } from '@spinach-shared/utils';

import { atomDashboardSocket } from '../../atoms';
import { ElementId } from '../../constants';
import {
    useGlobalAuthedUser,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useNotionPages,
    useTagManagerDestruction,
    useUserIdentification,
    useWebsocket,
} from '../../hooks';
import { useGlobalStoredSeriesList } from '../../hooks/useGlobalStoredSeriesList';
import { HeaderThree } from '../../styles';
import { FYI, FYIState, IntercomCustom, SpinachStationary, UserAccountButton, ViewContainer } from '../common';
import { AIDashboardCard } from './AIDashboardCard';
import { CreateSeriesCard } from './CreateSeriesCard';
import { CreateStandupModal } from './CreateStandupModal';
import { RemoveSeriesModal } from './RemoveSeriesModal';
import { RenameSeriesModal } from './RenameSeriesModal';
import { SeriesDashboardItem } from './SeriesDashboardItem';
import { ShareSeriesModal } from './ShareSeriesModal';
import { ScrollView } from './common';

const Stationary = styled(SpinachStationary)`
    min-height: 65%;
`;

const showDemoSeriesToo = true;

export function SeriesDashboard(): JSX.Element {
    const { socket } = useWebsocket(atomDashboardSocket);
    const {
        storedSeriesListState: { storedSeriesList, lastFetched },
    } = useGlobalStoredSeriesList();
    const [series] = useGlobalNullableStoredSeries();
    const [user] = useGlobalAuthedUser();
    const [shouldShowInviteModal, setShouldShowInviteModal] = useState(false);
    useUserIdentification(user);
    useRedirectionWithSeriesLoaded();
    useClearSeriesOnMount();
    useTagManagerDestruction();

    const { routeToAIDashboard } = useGlobalRouting();

    useNotionPages(true);
    const [hoveredSeries, setHoveredSeries] = useState<UserSeriesMetadata | null>(null);
    const [isCreateSeriesModalOpen, setIsCreateSeriesModalOpen] = useState(false);
    const [isRemoveSeriesModalOpen, setIsRemoveSeriesModalOpen] = useState(false);
    const [isRenameSeriesModalOpen, setIsRenameSeriesModalOpen] = useState(false);
    const [selectedSeries, setSelectedSeries] = useState<UserSeriesMetadata | null>(null);
    const [areOptionsOpen, setOptionsOpen] = useState<boolean>(false);

    const hasAtLeastOneScribeSeries = storedSeriesList.some((series) => !!series.metadata?.scribeMetadata);

    if (!lastFetched) {
        return <FYI state={FYIState.Loading} />;
    }

    return (
        <ViewContainer>
            <UserAccountButton />
            <Stationary id={ElementId.SeriesDashboardContainer}>
                <HeaderThree>{user.isScribeUser ? 'Your Meetings' : 'Your Standups'}</HeaderThree>
                <ScrollView stretch={true} sidePadding={0}>
                    {user.seriesMetadataList
                        .filter((series) => (!isProductionStage() ? showDemoSeriesToo : !isDemoSeries(series.id)))
                        .filter((series) => !!storedSeriesList.find((s) => s.id === series.id))
                        .filter((series) => !storedSeriesList.find((s) => s.id === series.id)?.scribeMetadata)
                        .map((series) => {
                            const storedSeriesForCard = storedSeriesList.find((s) => s.id === series.id);
                            const noElementsOpened = !(
                                areOptionsOpen ||
                                isRenameSeriesModalOpen ||
                                isRemoveSeriesModalOpen
                            );

                            return (
                                <SeriesDashboardItem
                                    key={series.id}
                                    series={series}
                                    storedSeries={storedSeriesForCard?.toJSON()}
                                    setOptionsOpen={setOptionsOpen}
                                    noElementsOpened={noElementsOpened}
                                    areOptionsOpen={areOptionsOpen}
                                    setHoveredSeries={setHoveredSeries}
                                    hoveredSeries={hoveredSeries}
                                    onClickRemove={() => {
                                        setSelectedSeries(series);
                                        setIsRemoveSeriesModalOpen(true);
                                    }}
                                    onClickRename={() => {
                                        setSelectedSeries(series);
                                        setIsRenameSeriesModalOpen(true);
                                    }}
                                />
                            );
                        })}

                    {hasAtLeastOneScribeSeries || user.isScribeUser ? (
                        <AIDashboardCard
                            onClick={() => {
                                routeToAIDashboard();
                            }}
                        />
                    ) : null}

                    {user.isScribeUser ? (
                        <></>
                    ) : (
                        <CreateSeriesCard
                            onOpenCreateSeriesModal={() => {
                                setIsCreateSeriesModalOpen(true);
                            }}
                        />
                    )}
                </ScrollView>
            </Stationary>

            {selectedSeries ? (
                <RemoveSeriesModal
                    user={user}
                    series={selectedSeries}
                    isOpen={isRemoveSeriesModalOpen}
                    onClose={() => {
                        setIsRemoveSeriesModalOpen(false);
                    }}
                />
            ) : null}

            <CreateStandupModal
                user={user}
                setShouldShowInviteModal={setShouldShowInviteModal}
                isOpen={isCreateSeriesModalOpen}
                onClose={() => {
                    setIsCreateSeriesModalOpen(false);
                }}
            />

            {selectedSeries ? (
                <RenameSeriesModal
                    socket={socket}
                    series={selectedSeries}
                    isOpen={isRenameSeriesModalOpen}
                    onClose={() => {
                        setIsRenameSeriesModalOpen(false);
                    }}
                />
            ) : null}

            {shouldShowInviteModal && series ? (
                <ShareSeriesModal
                    userId={user.spinachUserId}
                    isOpen={!!shouldShowInviteModal}
                    onClose={() => {}}
                    series={series}
                    redirectOnClose={true}
                />
            ) : null}
            <IntercomCustom />
        </ViewContainer>
    );
}

/**
 * here we have advantage of having all series data at our disposal
 * there's demo, onboarding, and first series flow, legacy dash, new dashboard
 */
function useRedirectionWithSeriesLoaded() {
    const [user] = useGlobalAuthedUser();
    const {
        storedSeriesListState: { lastFetched, storedSeriesList },
    } = useGlobalStoredSeriesList();
    const { routeToFirstSeriesFlow, routeToOnboarding, routeToAIDashboard, routeToDemoExperience } = useGlobalRouting();

    const hasAtLeastOneScribeSeries = storedSeriesList.some((series) => !!series.metadata?.scribeMetadata);
    const hasAtLeastOneLegacySeries = storedSeriesList.some((series) => !series.metadata?.scribeMetadata);

    useEffect(() => {
        // only process once we have fetched
        if (!lastFetched) {
            return;
        }

        if (user.isDemoing) {
            routeToDemoExperience({ replace: true });
            return;
        }

        if (!user.metadata.isOnboarded && !user.isScribeUser) {
            routeToOnboarding();
            return;
        }

        if (user.realSeries.length === 0) {
            if (!user.isScribeUser) {
                routeToFirstSeriesFlow({ replace: true });
            } else {
                routeToAIDashboard({ replace: true });
            }
            return;
        }

        if (hasAtLeastOneLegacySeries) {
            // stay on legacy dashboard
            return;
        }

        if (hasAtLeastOneScribeSeries || user.isScribeUser) {
            routeToAIDashboard({ replace: true });
            return;
        }
    }, [storedSeriesList.length, lastFetched, user.seriesMetadataList]);
}

function useClearSeriesOnMount() {
    const [, setSeries] = useGlobalNullableStoredSeries();

    useEffect(() => {
        setSeries(undefined);
    }, []);
}
