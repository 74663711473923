import { ApolloMappedPerson, IntegrationSettings } from '@spinach-backend/types';

import { DemoVersion, ExperimentKey, ISOString } from '.';
import { SlackUserSettings } from '..';
import { SpinachIntegration } from './Integration';
import { OutputPlatform, UUID, UserSeriesMetadata } from './Series';
import { ZoomParticipantRole } from './Zoom';
import { validateEnumUniqueness } from './validateEnumUniqueness';

export enum IntegrationCode {
    Zoom = 'zoom',
    GoogleMeet = 'google-meet',
    MicrosoftTeams = 'microsoft-teams',
    Slack = 'slack',
    GoogleChat = 'google-chat',
    Discord = 'discord',
    WhatsApp = 'whats-app',
    Telegram = 'telegram',
    JiraSoftware = 'jira-software',
    Asana = 'asana',
    Trello = 'trello',
    Monday = 'monday-com',
    ClickUp = 'clickup',
    Github = 'github',
    Gitlab = 'gitlab',
    Linear = 'linear',
    Notion = 'notion',
    Other = 'other',
}

export const IntegrationLabelMap = {
    [IntegrationCode.GoogleMeet]: 'Google Meet',
    [IntegrationCode.MicrosoftTeams]: 'Microsoft Teams',
    [IntegrationCode.Zoom]: 'Zoom',
    [IntegrationCode.Slack]: 'Slack',
    [IntegrationCode.GoogleChat]: 'Google Chat',
    [IntegrationCode.Discord]: 'Discord',
    [IntegrationCode.WhatsApp]: 'Whatsapp',
    [IntegrationCode.Telegram]: 'Telegram',
    [IntegrationCode.JiraSoftware]: 'Jira Software',
    [IntegrationCode.Asana]: 'Asana',
    [IntegrationCode.Trello]: 'Trello',
    [IntegrationCode.Monday]: 'Monday.com',
    [IntegrationCode.ClickUp]: 'Clickup',
    [IntegrationCode.Github]: 'Github',
    [IntegrationCode.Gitlab]: 'Gitlab',
    [IntegrationCode.Linear]: 'Linear',
    [IntegrationCode.Notion]: 'Notion',
    [IntegrationCode.Other]: 'Other',
};

export enum HowDidYouHearLabel {
    LinkedIn = 'LinkedIn',
    GoogleSearch = 'Google Search',
    Advertisement = 'Advertisement',
    ProductHunt = 'Product Hunt',
    YCombinator = 'Y Combinator',
    Article = 'Article/Newsletter',
    Zoom = 'Zoom Marketplace',
    FriendCoworker = 'A friend or co-worker',
    Other = 'Other',
}

export enum HowDidYouHearCode {
    LinkedIn = 'linkedin',
    GoogleSearch = 'google-search',
    Advertisement = 'advertisement',
    ProductHunt = 'producthunt',
    YCombinator = 'y-combinator',
    Article = 'article-newsletter',
    Zoom = 'zoom-marketplace',
    FriendCoworker = 'friend-or-coworker',
    Other = 'other',
}

export enum EmailVerifyReason {
    BlockedEmail = 'BlockedEmail',
    CodeFailedToSend = 'CodeFailedToSend',
}

export enum EmailReferralResponseCode {
    Success = 'Success',
    Failed = 'Failed',
}

export const HowDidYouHearMap = {
    LinkedIn: {
        code: HowDidYouHearCode.LinkedIn,
        label: HowDidYouHearLabel.LinkedIn,
    },
    Advertisement: {
        code: HowDidYouHearCode.Advertisement,
        label: HowDidYouHearLabel.Advertisement,
    },
    GoogleSearch: {
        code: HowDidYouHearCode.GoogleSearch,
        label: HowDidYouHearLabel.GoogleSearch,
    },
    ProductHunt: {
        code: HowDidYouHearCode.ProductHunt,
        label: HowDidYouHearLabel.ProductHunt,
    },
    YCombinator: {
        code: HowDidYouHearCode.YCombinator,
        label: HowDidYouHearLabel.YCombinator,
    },
    Article: {
        code: HowDidYouHearCode.Article,
        label: HowDidYouHearLabel.Article,
    },
    Zoom: {
        code: HowDidYouHearCode.Zoom,
        label: HowDidYouHearLabel.Zoom,
    },
    FriendCoworker: {
        code: HowDidYouHearCode.FriendCoworker,
        label: HowDidYouHearLabel.FriendCoworker,
    },
    Other: {
        code: HowDidYouHearCode.Other,
        label: HowDidYouHearLabel.Other,
    },
};

export type VerifyEmailRequest = {
    email: string;
};

export type VerifyEmailCodeRequest = VerifyEmailRequest & {
    code: string;
    experimentCode: string | null;
    demoVersion?: DemoVersion;
    deepLinkedSeriesId: string | null;
};

export type EmailVerificationResponse = {
    success: boolean;
    reason?: EmailVerifyReason;
};

export type UserCreatable = {
    isNewUser?: boolean;
};

export type UserResponse = {
    user: IClientUser | null;
};

export type GoogleVerificationResponse = UserCreatable & UserResponse;
export type MicrosoftVerificationResponse = UserCreatable & UserResponse;

export type EmailVerificationCodeResponse = EmailVerificationResponse & UserCreatable & UserResponse;

export type SeriesDestinationSettings = {
    channelName?: string;
    channelId?: string;
};

export type UserDefaultDestinationSettings = SeriesDestinationSettings;

export type SummaryOutputSettings = {
    shouldReinstall?: boolean;
    platform: OutputPlatform.Slack;
    teamId?: string;
    teamType?: string;
    seriesId: string;
    destinationSettings: SeriesDestinationSettings[];
    defaultDestinationSettings?: SeriesDestinationSettings;
};

export type SeriesIntegrationSettings = IntegrationSettings & {
    /** @NOTE keeping for backward compatability. Can remove after ~1 week 8/14/23 */
    summaryOutputSettings?: SummaryOutputSettings[];
    slack?: SummaryOutputSettings;
};

export enum UserIntegrations {
    Jira = 'jira',
    GoogleCalendar = 'google-calendar',
    Slack = 'slack',
}

export type ClientUserSlackSettings = SlackUserSettings & {
    shouldReinstall: boolean;
};

export type ClientUserNotionSettings = {
    isAuthed: boolean;
    defaultParentPageId?: string;
    defaultParentPageName?: string;
    defaultDatabaseId?: string;
    defaultDatabaseName?: string;
};

export type UserIntegrationSettings = Record<
    `${Exclude<
        SpinachIntegration,
        SpinachIntegration.Slack | SpinachIntegration.GoogleDrive | SpinachIntegration.GoogleCalendar
    >}Settings`,
    { isAuthed: boolean } | undefined
> & {
    notionSettings?: ClientUserNotionSettings;
    slackSettings?: ClientUserSlackSettings;
    confluenceSettings?: {
        isAuthed: boolean;
        confluenceAccountUrl?: string;
    };
    jiraSettings?: {
        isAuthed: boolean;
        hasCreateScopes: boolean;
        jiraAccountUrl: string;
    };
    googleSettings?: {
        isAuthed: boolean;
        isCalendarEnabled: boolean;
        isDriveEnabled: boolean;
    };
    microsoftSettings?: {
        isAuthed: boolean;
        isCalendarEnabled: boolean;
    };
};

export enum PlatformSource {
    ChromeExtStore = 'chrome-ext-store',
}

/**
 * These keys are meant to be minimal in length, yet somewhat identifiable,
 * so that as users dismiss feature hints over time, they don't dominate the user metadata
 */
export enum FeatureDiscoveryKey {
    /** deprecated but kept for reference */
    // JiraIntegration = 'ji',

    AsanaIntegration = 'ai',
    SpinachReferral = 'sr',
    PayUp = 'pu',
    Generic = 'g-',
    SecondUserInvite = 'si',
}

export enum CheckInFeatureDiscoveryKey {
    JiraIntegration = 'cji',
}

export type GenericFeatureKey = `${FeatureDiscoveryKey.Generic}${string}`;

export enum TooltipFeatureDiscoveryKey {
    JiraMagicInput = 'jmi',
    RegularUpdateSentiment = 'rus',
    RegularUpdateSentimentReasons = 'rsr',
}

export type DismissableHints =
    | FeatureDiscoveryKey
    | TooltipFeatureDiscoveryKey
    | GenericFeatureKey
    | CheckInFeatureDiscoveryKey;

validateEnumUniqueness([FeatureDiscoveryKey, TooltipFeatureDiscoveryKey, CheckInFeatureDiscoveryKey]);

// html element ID to attach to
export enum TooltipFeatureDiscoveryId {
    JiraMagicInputId = 'JiraMagicInputId',
}

/**
 * - `potential` - the user has the potential to be the first inviter
 *      (no other users from domain marked as first inviter) - but hasn't invited spinach yet
 * - `confirmed-yes` - the user has invited spinach to a meeting
 * - `confirmed-no` - confirmed that another user with this domain is the first inviter
 * - `unsupported` - the user is has a domain that existed before the feature was released and therefore is inconclusive
 */
export enum FirstDomainInviterStatus {
    Potential = 'potential',
    ConfirmedYes = 'confirmed-yes',
    ConfirmedNo = 'confirmed-no',
    Unsupported = 'unsupported',
}

export enum UserCreationSource {
    ScribeEmailInvite = 'scribe-email-invite',
    LegacyEmailInvite = 'legacy-email-invite',
    GoogleCodeFlow = 'google-code-flow',
    GoogleSignIn = 'google-sign-in',
    MicrosoftSignIn = 'microsoft-sign-in',
    MicrosoftSignInFromCompanyWebsite = 'microsoft-sign-in-from-company-website',
    EmailVerificationFlow = 'email-verification-flow',
    ZoomInstall = 'zoom-install',
    AnonymousUserFlow = 'anonymous-user-flow',
    RecallMeetingEvent = 'recall-meeting-event',
    LegacyEmailSubscriptionInvite = 'legacy-email-subscription-invite',
    CognitoSSO = 'cognito-sso',
    SlackHomeViewing = 'slack-home-viewing',
    StripePayment = 'stripe-payment',
}

export type UserMetadata = Partial<UserIntegrationTracking> &
    UTMTracking &
    AiMeetingCounters & {
        isSelfServe?: boolean;
        isFirstUserInACompanyAssociation?: boolean;
        companyName?: string;
        hasCompletedFirstMeeting?: boolean;
        lastLoggedOn?: ISOString;
        lastEditedOn?: ISOString;
        createdOn?: ISOString;
        preferredName?: string;
        howDidYouHear?: string;
        howDidYouHearOther?: string;
        firstName?: string;
        lastName?: string;
        integrationsVideo?: IntegrationCode;
        integrationsVideoOther?: string;
        integrationsMessaging?: IntegrationCode;
        integrationsMessagingOther?: string;
        integrationsProjectMgmt?: IntegrationCode[];
        integrationsProjectMgmtOther?: string;
        intercomHash?: string;
        numberOfTimesViewingAllOutAsyncTour?: number;
        isJoiningSeriesUponOnboarding?: boolean;
        isOnboarded?: boolean;
        isBot?: boolean;
        practiceRoundsComplete?: number;
        /** @deprecated */
        hasDismissedDemoBanner?: boolean;
        totalSessions?: number;
        timezoneRegion?: string;
        isAnonymousUser?: boolean;
        platformSource?: PlatformSource;
        /**
         * this is meant to contain feature discovery keys that the user has dismissed
         * that were specifically relevant to them. These keys are meant to be short and minimal
         * to reduce size of user metadata.
         */
        dismissedHints?: DismissableHints[];
        experimentCodes?: ExperimentKey[];
        signupBeforeDemo?: boolean;

        demoVersion?: DemoVersion;
        teamKind?: string;
        teamKindOther?: string;
        roleOnTeam?: string;
        roleOnTeamOther?: string;

        hasLoggedIn?: boolean;
        rootDomain?: string;

        usedGoogleCodeFlow?: boolean;

        firstInviterForDomainStatus?: FirstDomainInviterStatus;

        hasInvitedSpinachToMeeting?: boolean;

        creationSource?: UserCreationSource;

        apolloId?: string;

        isEmailingIcpOnly?: boolean;

        lastInvoicePaidSuccessEventId?: string;
        isPaidAi?: boolean;

        isEditingAiEmailsByDefault?: boolean;

        accountReverseTrialStartDate?: Date;
    };

export type AiMeetingCounterKey = `totalAi${string}SessionCount`;

export type AiMeetingCounters = {
    [x in AiMeetingCounterKey]?: number;
};

export type UserIntegrationTracking = {
    isIntegratedWithSlack: boolean;
    isIntegratedWithJira: boolean;
    isIntegratedWithAsana: boolean;
    isIntegratedWithGoogleCalendar: boolean;
    isIntegratedWithGoogleDrive: boolean;
    isIntegratedWithTrello: boolean;
    isIntegratedWithClickUp: boolean;
    isIntegratedWithNotion: boolean;
    isIntegratedWithLinear: boolean;
    isIntegratedWithMicrosoftCalendar: boolean;
};

export interface User {
    _id: UUID;
    email?: string;
    googleId?: string;
    microsoftId?: string;
    preferredName?: string;
    zoomUserId?: string;
    createdOn: ISOString;
    lastLoggedOn?: ISOString;
    lastEditedOn?: ISOString;
    refreshToken?: string;
    schemaVersion?: number;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata: UserMetadata;
    createdAt?: Date;
    updatedAt?: Date;
}

export type UTMTracking = {
    utm_id?: string;
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
};

export interface IClientUser extends Omit<User, 'refreshToken'> {
    email: string;
    preferredName: string;
    integrationSettings?: UserIntegrationSettings;
    intercomHash?: string;
    featureToggles?: Record<FeatureToggle, FeatureFlagValue>;
    apolloData?: ApolloMappedPerson;
}

export enum SendCalendarSuggestionsCohort {
    Slack = 'slack',
    Email = 'email',
    All = 'all',
}

export type BasicZoomParticipant = BaseUser & {
    meetingParticipantId: string;
    // Role is not defined InMainClient
    role?: ZoomParticipantRole;
};

export type BaseUser = {
    preferredName: string;
};

export enum FeatureToggle {
    Jira1 = 'jira',
    JiraBoard = 'jira-board',
    TeamTopics2 = 'team-topics-2',
    SentimentCopy = 'sentiment-copy',
    MoodCheck = 'vibe-check',
    RecentCheckIns = 'recent-check-ins',
    AnonymousUserSupport = 'anonymous-user-support',
    LiveSummary = 'live-summary',
    NumberOfTimesUserCanViewAoATour = 'number-of-times-user-can-view-aoa-tour',
    AllowedInternalEmails = 'allowed-internal-emails',
    SSOBeta = 'sso-beta',
    SSODomains = 'sso-domains',
    ToggleRoundtable = 'disable-roundtable',
    EditUserName = 'edit-user-name',
    GoogleCalendar = 'google-calendar',
    DiscussionReactions = 'discussion-reactions',
    UserGoals = 'user-goals',
    Asana = 'asana-mvp',
    EmailSummaries = 'email-summaries',
    JiraIntegrationCallout = 'jira-int-callout',
    SentimentOpacity = 'sentiment-opacity',
    JiraMagicInputCallout = 'jira-magic-input-callout',
    Meditation = 'meditation-mvp',
    AsanaIntegrationCallout = 'asana-int-callout',
    ReferralCallout = 'referral-callout',
    SummaryFilters = 'summary-filters',
    PayupCalloutURL = 'pay-up-url',
    GeneratedSummaries = 'generated-summaries',
    GenericCalloutProps = 'generic-callout-props',
    StopStateMachineMonitoring = 'stop-state-machine-monitoring',
    SignupBeforeDemo = 'signup-before-demo',
    RegularItemSentiment = 'regular-item-sentiment',
    LiveItemSentimentDetails = 'live-item-sentiment-details',
    FreeTierLimited = 'free-tier',
    JiraSentimentInFooter = 'jira-sentiment-in-footer',
    IssueBased = 'continuity-1',
    CustomSlackReminders = 'custom-slack-reminders',
    ProgressNavigation = 'progress-navigation',
    JiraSearchAll = 'jira-search-all',
    IssueResolution = 'issue-resolution',
    DemoVersion = 'demo-version',
    InviteViaSlack = 'invite-via-slack',

    AISeriesAsanaIntegration = 'ai-series-asana-integration',
    AISeriesJiraIntegration = 'ai-series-jira-integration',
    AISummaryJiraIntegration = 'ai-summary-jira-integration',
    AISlackActionItems = 'ai-slack-action-items',
    AISlackDMPerPerson = 'ai-slack-dm-per-person',
    AISlackIssueActionItems = 'ai-slack-issue-action-items',
    AIShouldSendSlackActionsToAll = 'ai-should-send-slack-actions-to-all',

    ScribeMeetingType = 'scribe-meeting-type',
    ScribePerPersonStandupPromptTemplate = 'open-ai-transcript-summary-prompt',
    ScribeFlowBasedStandupPromptTemplate = 'open-ai-minimal-transcript-flow-prompt',
    ScribeRetroPromptTemplate = 'scribe-retro-prompt-template',
    ScribeWeeklyPromptTemplate = 'scribe-weekly-prompt-template',
    ScribeSprintPlanningPromptTemplate = 'scribe-sprint-planning-prompt-template',
    ScribeBacklogGroomingPromptTemplate = 'scribe-backlog-grooming-prompt-template',
    ScribeAllHandsPromptTemplate = 'scribe-all-hands-prompt-template',
    ScribeResearchSessionPromptTemplate = 'scribe-research-session-prompt-template',
    ScribeOneOnOnePromptTemplate = 'scribe-one-on-one-prompt-template',
    ScribeGenericPromptTemplate = 'scribe-generic-prompt-template',
    ScribeSprintPlanningWithTickets = 'scribe-sprint-planning-with-tickets',
    ScribeGenericWithTickets = 'scribe-generic-with-tickets',

    OpenAiModel = 'open-ai-model',
    ScribeMeetingDeterminationPromptTemplate = 'scribe-meeting-determination-prompt-template',
    ScribeUseGptMeetingDetermination = 'use-gpt-meeting-determination',
    EmailOrganizerOnly = 'email-organizer-only',
    InVideoMeetingSlackConnectMessageEnabled = 'in-video-meeting-slack-connect-message-enabled',
    InVideoMeetingSlackConnectMessageText = 'in-video-meeting-slack-connect-message-text',
    InVideoMeetingSlackConnectMessageNumberRetries = 'in-video-meeting-slack-connect-message-number-retries',
    InVideoMeetingSlackConnectMessageDelay = 'in-video-meeting-slack-connect-message-delay',
    SendSlackSummaryInDmAsDefault = 'slack-summary-in-dm-as-default',
    ScribeEmbeddingOptions = 'scribe-embedding-options',
    ScribeEmbeddingTopK = 'scribe-embedding-topk',
    AiGoogleCalendar = 'ai-google-calendar',
    FavorEmbeddingsForActionItemsOutput = 'favor-embeddings-for-action-items',
    LinearIntegration = 'linear-integration',
    ClickUpIntegration = 'clickup-integration',
    ScribeOpenAiTemperature = 'scribe-open-ai-temperature',
    ScribeOpenAiTemperatureFullTranscript = 'scribe-open-ai-temperature-full-transcript',
    ScribeApiRetryCount = 'scribe-api-retry-count',
    TrelloIntegration = 'trello-integration',
    ScribeSummaryActionItemsEmbedding = 'scribe-action-items-embedding',
    RootDomainManagement = 'root-domain-management',
    ConfluenceIntegration = 'confluence-integration',
    NotionIntegration = 'notion-integration',
    GoogleDriveIntegration = 'google-drive-integration',
    ScribeEmail = 'scribe-email',
    SlackSummaryRatingEnabled = 'slack-summary-rating-enabled',
    UseAssemblyAiLanguageDetection = 'use-assembly-ai-language-detection',
    RetryRecallTranscriptionOnError = 'retry-recall-transcription-on-error',
    TicketPaginationWaitTime = 'ticket-pagination-wait-time',
    ProAccount = 'pro-account',
    /** @NOTE - this is disabled only for testing purposes only  */
    UseExternalValidationForTranscripts = 'use-external-validation-for-transcripts',
    TranscriptsMVP = 'transcripts-mvp',
    UseCustomVocabulary = 'use-custom-vocabulary',
    RunIntentWithFullTranscript = 'run-intent-with-full-transcript',
    PromptWithFullTranscriptMaxCharLength = 'prompt-with-full-transcript-max-char-length',
    GenerateMeetingAtomWithFullTranscript = 'generate-meeting-atom-with-full-transcript',
    PostChainDeduplication = 'post-chain-deduplication',
    JwtAccess = 'jwt-access',
    UseAzureOpenAI = 'use-azure-open-ai',
    SlackPublicFollowups = 'slack-public-followups',
    AzureStrategyProbability = 'azure-strategy-probability',
    RetroSummaryEmbedding = 'retro-summary-embedding',
    Azure32KOverOpenAi8kStrategyProbability = 'azure-32k-over-open-ai-8k-strategy-probability',
    SlackPublicIssueActions = 'slack-public-issue-actions',
    PaymentUrl = 'pro-payment-url',
    WhatsSpinachEmailExperiment = 'whats-spinach-email-experiment',
    MicrosoftSignInEnabled = 'microsoft-sign-in-enabled',
    CalendarSuggestions = 'calendar-suggestion',
    NumberOfCalendarSuggestions = 'number-of-calendar-suggestions',
    RealtimePaidAccessEnabled = 'realtime-paid-access-enabled',
    SlackDMFollowUpsEnabled = 'ai-slack-action-items-with-followup',
    EditSummaryMVP = 'edit-summary-mvp',
    EnhancedDiarizationEnabled = 'enhanced-diarization-enabled',
    InMeetingHistoryEnabled = 'in-meeting-history',
    InMeetingHistorySendDelay = 'in-meeting-history-send-delay',
    CustomUUID = 'custom-uuid',
    NonOrganizerSpinachRemove = 'non-organizer-spinach-remove',
    SummariesInDashboard = 'summaries-in-dashboard',
    ChunkBasedPromptsOverlapInWords = 'chunk-based-prompts-overlap-in-words',
    ChunkBasedPromptsChunkSizeInWords = 'chunk-based-prompts-chunk-size-in-words',
    ChunkBasedPromptsTemperature = 'chunk-based-prompts-temperature',
    ChunkBasedPromptsOverlapInWordsRealTime = 'chunk-based-prompts-overlap-in-words-realtime',
    ChunkBasedPromptsChunkSizeInWordsRealTime = 'chunk-based-prompts-chunk-size-in-words-realtime',
    ChunkBasedPromptsTemperatureRealTime = 'chunk-based-prompts-temperature-realtime',
    ChunkBasedBlockers = 'chunk-based-blockers',
    SummaryGenerationAbTestFromUrl = 'summary-generation-ab-test-form-url',
    SyncCalendarEventsWithScribeResponseStatus = 'sync-calendar-events-with-scribe-response-status',
    SendIndividualEmails = 'send-individual-emails',
    AIMeetingDashboardCombo = 'ai-dashboard-redesign',
    PerPersonJiraTicketFetch = 'per-person-jira-ticket-fetch',
    PerPersonJiraTicketFetchMaxAttendees = 'per-person-jira-ticket-fetch-max-attendees',
    ReverseTrialAiEnabled = 'reverse-trial-ai-enabled',
    ReverseTrialLengthInDays = 'reverse-trial-length-in-days',
    ScribeTranscriptionSettings = 'scribe-transcription-settings',
    ChainedActionItemGrouping = 'chained-action-item-grouping',
    ChainedBlockerGrouping = 'chained-blocker-grouping',
    FullTranscriptPlansAndProgress = 'full-transcript-plans-and-progress',
    PostGenerationPerPersonTicketMatching = 'post-generation-per-person-ticket-matching',
    WeeklySummaryEnabled = 'weekly-summary-enabled',
    ActionItemCreationEnabledTicketSources = 'action-item-creation-enabled-ticket-sources',
    IsActionItemTicketCreationEnabled = 'action-item-ticket-creation',
    LimitAIQuality = 'limit-ai-quality',
    MinimumRequiredHistories = 'minimum-required-histories',
    PreMeetingFacilitationNotification = 'ai-facilitation-notification',
    HeySpinachSectionEnabled = 'hey-spinach-section-enabled',
    HeySpinachSectionNumberOfWordsAfter = 'hey-spinach-section-number-of-words-after',
    HeySpinachSectionNumberOfWordsBefore = 'hey-spinach-section-number-of-words-before',
    HeySpinachSectionTemperature = 'hey-spinach-section-temperature',
    RealtimeBlockersEnabled = 'realtime-blockers-enabled',
}

export type FeatureFlagSet<T extends string> = Record<T, FeatureFlagValue>;
export type FeatureFlagValue = number | boolean | string | Array<FeatureFlagValue> | Object;

export type UserIdentity = {
    userId: string;
    firstName?: string;
    lastName?: string;
    company?: string;
    rootDomain: string;
    howDidYouHearSource?: string;
    userName: string;
    userEmail: string;
    seriesMetadataList?: UserSeriesMetadata[];
    metadata?: UserMetadata;
};

export enum FeatureIntent {
    PerPersonUpdates = 'per-person-updates',
    ActionItems = 'action-items',
    IssueActions = 'issue-actions',
    MeetingTypeDetermination = 'meeting-type-determination',
    NameEmailMapping = 'name-email-mapping',
    MeetingSummary = 'meeting-summary',
    TranscriptEmbedding = 'transcript-embedding',
    MeetingActionItems = 'meeting-action-items',
    MeetingActionItemsFullTranscript = 'meeting-action-items-full-transcript',
    MeetingBlockers = 'meeting-blockers',
    MeetingKeyDecisions = 'meeting-key-decisions',
    MeetingCelebrations = 'meeting-celebrations',
    MeetingAgendaItems = 'meeting-agenda-items',
    ActionItemDeduplication = 'action-item-deduplication',
    BlockerDeduplication = 'blocker-deduplication',
    MeetingWhatWentWell = 'meeting-what-went-well',
    MeetingWhatDidNotGoWell = 'meeting-what-did-not-go-well',
    MeetingWhatCanBeImproved = 'meeting-what-can-be-improved',
    MeetingTicketMatching = 'meeting-ticket-matching',
    MeetingHeySpinachSection = 'meeting-hey-spinach-section',
    MapReducePipelineTest = 'map-reduce-pipeline-test',

    WorkstreamsFromMeeting = 'workstreams-from-meeting',
    WorkstreamWeeklyOverview = 'workstream-weekly-overview',
}
