import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import SpinachCalendarConnectionSrc from '../../../assets/spinach-calendar-connection.png';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useLandingAnalytic,
} from '../../../hooks';
import { BodyRegularOnboard, HeaderThreeOnboard } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Row, SlidingColumn, Spacing } from '../../common';
import { GoogleLoginComponent } from '../../common/GoogleLoginComponent';
import { ScrollView } from '../../series';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, Stationary, StepProps } from './common';

export type CalendarPermissionsStepProps = StepProps & {
    onBack: () => void;
    onOptForManual: () => void;
    onProgressToAddEventToMeeting: (user: ClientUser) => void;
};

export function CalendarPermissionsStep({
    onBack,
    direction,
    onOptForManual,
    onProgressToAddEventToMeeting,
}: CalendarPermissionsStepProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(onProgressToAddEventToMeeting);
    useLandingAnalytic(ClientEventType.UserViewedCalendarPermissionsStep);
    const track = useExperienceTracking();
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Connect to your calendar</HeaderThreeOnboard>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <Spacing factor={1 / 2} />

                    <BodyRegularOnboard style={{ textAlign: 'center' }}>
                        Connecting your calendar is the easy way to control which meetings Spinach joins and summarizes
                    </BodyRegularOnboard>
                    <Spacing factor={1 / 2} />

                    <Row centered>
                        <img src={SpinachCalendarConnectionSrc} style={{ height: '60px' }} />
                    </Row>

                    <Spacing factor={2} />

                    <Row centered>
                        <GoogleLoginComponent
                            text="Connect Google Calendar"
                            onClick={() => {
                                track(ClientEventType.UserClickedIntegrateWithGoogleCalendar);
                                startDetection();
                                URLUtil.openURL(
                                    `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                );
                            }}
                        />
                    </Row>
                </SlidingColumn>
            </ScrollView>
            <Spacing />
            <Row centered>
                <SecondaryButton title={"No thanks. I'll do it manually"} onClick={onOptForManual} />
            </Row>
        </Stationary>
    );
}
