import { SpinachIntegration } from './Integration';
import { DetailedTicket } from './Jira';

export type CreateTicketRequest = {
    title: string;
    description: string;
    projectId?: string;
    issueTypeId?: string;
};

export type TicketSource = TicketIntegration;

export const TICKET_SOURCE_MAP: {
    [key in Capitalize<TicketSource>]: TicketIntegration;
} = {
    Jira: SpinachIntegration.Jira,
    Asana: SpinachIntegration.Asana,
    Linear: SpinachIntegration.Linear,
    ClickUp: SpinachIntegration.Clickup,
    Trello: SpinachIntegration.Trello,
};

export const isTicketSource = (source: unknown): source is TicketSource =>
    Boolean(source && typeof source === 'string' && Object.values(TICKET_SOURCE_MAP).includes(source as TicketSource));

export type ClickUpDetailedTicket = DetailedTicket & {
    creatorEmail: string;
};

export type TicketIntegration = Extract<
    SpinachIntegration,
    | SpinachIntegration.Asana
    | SpinachIntegration.Clickup
    | SpinachIntegration.Jira
    | SpinachIntegration.Linear
    | SpinachIntegration.Trello
>;
