import { SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postAnonymousFeatureFlag(key: string, defaultValue: boolean = false): Promise<boolean> {
    try {
        const response = await postSpinachAPI<{ value: boolean }>(SpinachAPIPath.AnonymousFeatureToggle, { key });
        return !!response?.value;
    } catch {
        return defaultValue;
    }
}
