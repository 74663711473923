export enum WebUrlQuery {
    AI = 'ai',
    Secret = 'secret',
    Where = 'where',
    Dashboard = 'dashboard',
    Suid = 'suid',
    Email = 'email',
    Popout = 'popout',
    Meeting = 'meeting',
    Window = 'window',
    PlatformSource = 'platform-source',
    Experiment = 'experiment',
    DemoSandboxRecipient = 'for',
    DemoSandboxSender = 'f',
    SubView = 'subview',

    /**
     * @NOTE utm query params are stripped from redirect urls clicked in gmail
     * so we need to use a different query param to track the source of the click
     * */
    Source = 'source',
    Campaign = 'campaign',
    UTMSource = 'utm_source',
    UTMMedium = 'utm_medium',
    UTMCampaign = 'utm_campaign',
    DemoVersion = 'dv',
    Code = 'code',
    State = 'state',
    ICalUid = 'iCalUid',
    Redirect = 'redirect',
    RedirectUrl = 'redirect_url',
    Section = 'section',
    SeriesId = 'sid',
    BotId = 'bid',
    EditSuccess = 'editSuccess',
    Selection = 'selection',
    TicketSource = 'ticket-source',
    ActionItemId = 'action-item-id',
}

export enum MeetingSettingsSubview {
    TrelloSettings = 'trello-settings',
    SlackSettings = 'slack-settings',
    AsanaSettings = 'asana-settings',
    JiraSettings = 'jira-settings',
    LinearSettings = 'linear-settings',
    ClickUpSettings = 'clickup-settings',
    CalendarSettings = 'calendar-settings',
    GoogleSettings = 'calendar-settings',
    ConfluenceSettings = 'confluence-settings',
    NotionSettings = 'notion-settings',
    GoogleDriveSettings = 'google-drive-settings',
}

export enum UTMSource {
    ProductHunt = 'product-hunt',
    Slack = 'slack',
    AiEmail = 'ai-email',
    AiEmailSummary = 'ai-email-summary',
    AiVideoChat = 'ai-video-chat',
    AiOnboardingLandingPage = 'ai-onboarding',
    ExternalExport = 'external-export',
    ContinueWithEmail = 'continue-with-email',
    MicrosoftSignInfromCompanyWebsite = 'microsft-sign-in-from-company-website',
}

export enum UTMMedium {
    AsyncReminder = 'async-reminder',
    SlackIntroduction = 'slack-introduction',
    SummaryPerPersonUpdates = 'summary-per-person-updates',
    SummaryActionItems = 'summary-action-items',
}

export enum UTMCampaign {
    SlackConnect = 'slack-connect',
    GoogleCalendarConnect = 'google-calendar-connect',
    CreateTicketFromActionsRedirectLink = 'create-ticket-from-actions-redirect-link',
    CreateTicketFromActionsHeaderImage = 'create-ticket-from-actions-header-image',
}
export enum DemoVersion {
    Persona = '1',
    Legacy = '0',
}

export const DEMO_SANDBOX_SENDER_MAP: Record<string, string> = {
    fm: 'Matan',
    fy: 'Yoav',
    fd: 'Derek',
    fc: 'Coburn',
    fj: 'Josh',
};

export enum ExperimentKey {
    AISummary = 'ai-summary',
    StandupScribe = 'standup-scribe',
}

export enum ResponseCode {
    SummaryAlreadySent = 'summary-already-send',
}
