import { useEffect } from 'react';

import { FAREWELL_INSIGHT_ID } from '@spinach-shared/constants';
import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import FarewellArt from '../../../assets/finished.png';
import { useFacilitationTracking, useGlobalFacilitation, useGlobalRouting } from '../../../hooks';
import { BodyLarge, BodyRegular } from '../../../styles';
import { Row, Spacing } from '../../common';
import { PrimaryButton } from '../../stand-up';
import { DashboardSection } from '../dashboard/types';
import { ForwardBackwardButtons } from './TimedHeader';
import { Header, Padding, ScrollView } from './common';

export function FinishFacilitation(): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();
    const { routeToAIDashboard } = useGlobalRouting();
    const track = useFacilitationTracking();

    useEffect(() => {
        if (!facilitation.actualMeetingTime?.endTime && facilitation.actualMeetingTime?.startTime) {
            setFacilitation((facilitation) => ({
                ...facilitation,
                actualMeetingTime: {
                    ...facilitation.actualMeetingTime,
                    endTime: new Date().toISOString(),
                },
                inMeetingInsights: [
                    ...facilitation.inMeetingInsights,
                    {
                        id: FAREWELL_INSIGHT_ID,
                        title: `That's all, folks!`,
                        kind: 'Spinach',
                        actions: [],
                        context: 'Thanks for coming, and see you next time!',
                    },
                ],
            }));
        }
    }, []);

    return (
        <>
            <Row
                vCenter
                style={{
                    marginLeft: '20px',
                    marginTop: '20px',
                    marginRight: '20px',
                    justifyContent: 'space-between',
                    width: 'calc(100% - 40px)',
                }}
            >
                <Header>Meeting end</Header>
                <ForwardBackwardButtons hideForward />
            </Row>
            <ScrollView>
                <Padding>
                    <img
                        style={{ width: '100%', marginLeft: '-25px', borderRadius: '8px' }}
                        src={FarewellArt}
                        alt="finished"
                    />
                    <Spacing />
                    <Row centered>
                        <BodyLarge>The meeting has ended</BodyLarge>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row centered>
                        <BodyRegular>View the summary in your dashboard once it's ready.</BodyRegular>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row centered>
                        <PrimaryButton
                            title="Go to Dashboard"
                            onClick={() => {
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Go to Dashboard',
                                    Location: 'End Meeting',
                                });
                                routeToAIDashboard(
                                    undefined,
                                    new URLSearchParams({
                                        [WebUrlQuery.Section]: DashboardSection.Summaries,
                                    })
                                );
                            }}
                        />
                    </Row>
                </Padding>
            </ScrollView>
        </>
    );
}
