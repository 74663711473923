import {
    Facilitation,
    FacilitationComputedMetadata,
    ParticipantStatus,
    SpinachAPIPath,
    SpinachInsightKind,
} from '@spinach-shared/types';

import { getSpinachAPI } from './getSpinachAPI';
import { getFacilitationResponseMock } from './mockData';

type GetFacilitationResponse = {
    facilitation: Facilitation;
    facilitationComputedMetadata: FacilitationComputedMetadata;
    previousBotId: string;
    botId: string;
};

export async function getAiFacilitation(
    seriesId: string,
    botId?: string,
    previousBotId?: string
): Promise<GetFacilitationResponse | null> {
    const mock = getFacilitationResponseMock();
    if (mock) {
        return mock;
    }

    const params: Record<string, string> = {
        seriesId,
    };

    if (botId) {
        params.botId = botId;
    }

    if (previousBotId) {
        params.previousBotId = previousBotId;
    }

    return (
        (await getSpinachAPI<GetFacilitationResponse>(SpinachAPIPath.Facilitation, {
            params,
        })) ?? null
    );
}
