import { MeetingSettingsSubview, SpinachIntegration } from '@spinach-shared/types';

import ConfluenceLogo from '../../../assets/integrations/confluence-logo.png';
import { useGlobalAuthedUser, useGlobalNullableStoredSeries } from '../../../hooks';
import { useIntegrationRow } from '../../../hooks/useIntegrationRow';
import { AppIntegrationRow } from './AppIntegrationRow';
import { IntegrationRowProps } from './common';

export function ConfluenceIntegrationRow({ isConnectViewOnly }: IntegrationRowProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [series] = useGlobalNullableStoredSeries();
    const confluenceUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const integrationSettings = series?.getIntegrationSettings(SpinachIntegration.Confluence);
    const pageUrl =
        confluenceUrl && integrationSettings?.summaryParentPageId && integrationSettings.defaultSpaceKey
            ? `${confluenceUrl}/wiki/spaces/${integrationSettings.defaultSpaceKey}/pages/${integrationSettings.summaryParentPageId}`
            : '';
    const { onClick, subtext, isConnected, buttonText, isButtonDisabled } = useIntegrationRow(
        SpinachIntegration.Confluence,
        MeetingSettingsSubview.ConfluenceSettings,
        user.isAuthedForConfluence,
        user.isAuthedForConfluence ? (
            <span>
                Summaries will be saved to{' '}
                {pageUrl ? <a href={pageUrl}>this page</a> : 'a page prefixed with your meeting name'}.
            </span>
        ) : (
            `Save summaries to Confluence`
        )
    );

    return (
        <AppIntegrationRow
            onCTAClick={onClick}
            subtext={subtext}
            label={'Confluence'}
            icon={<img src={ConfluenceLogo} style={{ width: '24px', height: '24px' }} />}
            isConnected={isConnected}
            buttonText={buttonText}
            isButtonDisabled={isButtonDisabled}
            isConnectViewOnly={isConnectViewOnly}
        />
    );
}
