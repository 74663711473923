import { Modal } from '@material-ui/core';

import { SpinachModalContent } from '../../../common';
import { NotionIntegrationSettings } from '../../../common/settings/NotionIntegrationSettings';

export function NotionIntegrationSettingsModal({
    isNotionSettingsOpen,
    setIsNotionSettingsOpen,
}: {
    isNotionSettingsOpen: boolean;
    setIsNotionSettingsOpen: (val: boolean) => void;
}) {
    function onClose() {
        setIsNotionSettingsOpen(false);
    }

    if (!isNotionSettingsOpen) {
        return <></>;
    }

    return (
        <Modal open={isNotionSettingsOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    maxWidth: '80%',
                    padding: '20px',
                    minHeight: 'unset',
                    overflowY: 'hidden',
                }}
            >
                <NotionIntegrationSettings onClose={onClose} />
            </SpinachModalContent>
        </Modal>
    );
}
