import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import LeftDesign from '../../assets/abstract-bottom-left.svg';
import RightDesign from '../../assets/abstract-top-right-2.svg';
import MountainBackground from '../../assets/demo/mountain_bg.jpg';
import { useSeriesReality } from '../../hooks';
import { responsiveness } from '../../styles';

const BackgroundTerrain = styled.img`
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    min-width: 100vw;
    min-height: 100%;
`;

const BackgroundTerrainRight = styled.img`
    top: 0;
    right: 0;
    position: absolute;
    z-index: 0;
    height: 250px;

    @media ${responsiveness.thinnerThan1100} {
        height: 200px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanMD} {
        height: 150px;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 0px;
    }
`;

const BackgroundTerrainLeft = styled.img`
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    height: 250px;

    @media ${responsiveness.thinnerThan1100} {
        height: 200px;
        min-height: unset;
    }

    @media ${responsiveness.thinnerThanMD} {
        height: 150px;
    }

    @media ${responsiveness.thinnerThanSM} {
        height: 0px;
    }
`;

export function AbstractBackground(): JSX.Element {
    const { isDemoSeries } = useSeriesReality();
    const location = useLocation();

    if (isDemoSeries) {
        return <BackgroundTerrain src={MountainBackground} alt="" />;
    }

    if (location.pathname.match(/\/facilitation\/?/)) {
        return <></>;
    }

    return (
        <>
            <BackgroundTerrainLeft id="background-left" src={LeftDesign} alt="" />
            <BackgroundTerrainRight id="background-right" src={RightDesign} alt="" />
        </>
    );
}
