import { YTBHistoryProps, YTBHistoryPropsFactory } from '@spinach-shared/models';

import { ClientLogger, RunningContext } from '..';
import { getUsersYTBHistory } from '../apis/getUsersYTBHistory';

export async function fetchHistory(
    userId: string,
    seriesId: string,
    context: RunningContext
): Promise<YTBHistoryProps | undefined> {
    const historyJSON = await getUsersYTBHistory(seriesId);

    if (!historyJSON) {
        ClientLogger.info('No YTB History received for user', { userId, context });
        return undefined;
    }

    const props = YTBHistoryPropsFactory.fromJSON(historyJSON);
    ClientLogger.info('Received YTB History for user', {
        userId,
        context,
        archiveMetadata: props.archives.map((a) => ({
            ...a,
            deliveryDate: a.formattedDeliveryDate,
            updates: a.updates.length,
        })),
    });

    return props;
}
