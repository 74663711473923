import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, withStyles } from '@material-ui/core';
import { ErrorOutline, ExpandMore, Info, Warning } from '@material-ui/icons';
import moment from 'moment-timezone';
import { useState } from 'react';
import styled from 'styled-components';

import { CalendarEvent, ClientEventType, SpinachIntegration } from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    TimeUtils,
    getCalendarRecurringId,
    isMeetingTypeEnabledForEditing,
} from '@spinach-shared/utils';

import { patchSeries } from '../../../../apis';
import { useExperienceTracking, usePaymentUrl, useProFeatures, useWindowSize } from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useScribeEmail } from '../../../../hooks/useScribe';
import { BodyRegularOnboard, BodyRegularOnboardWithTextWrap, BodySmallOnboard, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { URLUtil, withFullStoryMasking } from '../../../../utils';
import { Anchor, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import './CombinedMeetingSection.css';
import { MeetingCommunicationDetails } from './MeetingCommunicationDetails';
import { KnowledgeBaseIntegrationDetails } from './MeetingDetails';

const StyledAccordionSummary = withStyles<'root' | 'content', {}, { isDesktopView: boolean }>({
    root: {
        padding: (props) => (!props.isDesktopView ? '0px 16px 0px 0px' : undefined),
    },
    content: {
        width: '100%',
    },
})(AccordionSummary);

export const MeetingCheck = styled.div<{ isChecked: boolean; isInteractive: boolean; isHovered?: boolean }>`
    width: 30px;
    height: 30px;
    background: ${(props) =>
        props.isChecked || props.isHovered ? props.theme.secondary.green : props.theme.neutrals.gray};
    justify-self: flex-end;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-left: 10px;
    opacity: ${(props) => (props.isHovered && !props.isChecked ? 0.2 : 1)};
    cursor: ${(props) => (!props.isInteractive ? 'not-allowed' : 'pointer')};
`;

function EditSummaryDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    const isProAccount = useProFeatures();
    const isMeetingTypeEnabledForEdit = isMeetingTypeEnabledForEditing(storedSeries.scribeMetadata?.meetingType);
    const [currentSeriesEditingFlag, setCurrentSeriesEditingFlag] = useState(
        !!storedSeries.metadata.scribeMetadata?.isHostEditing
    );
    const paymentUrl = usePaymentUrl();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();

    const track = useExperienceTracking();
    return (
        <>
            <Row>
                <BodyRegularOnboard>
                    <b style={{ marginRight: '10px', marginTop: '2px' }}>Edit Summary:</b>
                    <>
                        {isProAccount ? (
                            <BootstrapTooltip
                                title={
                                    !isMeetingTypeEnabledForEdit
                                        ? 'This meeting type is not yet supported for editing.'
                                        : ''
                                }
                                placement="top"
                            >
                                <span>
                                    <SpinachSwitch
                                        checked={isMeetingTypeEnabledForEdit ? currentSeriesEditingFlag : false}
                                        disabled={!isMeetingTypeEnabledForEdit}
                                        onChange={async () => {
                                            setCurrentSeriesEditingFlag(!currentSeriesEditingFlag);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Toggle Enable Edit Summary (Meeting Settings)',
                                                ToggleTo: currentSeriesEditingFlag ? 'Off' : 'On',
                                            });
                                            const updatedSeries = await patchSeries(storedSeries.id, {
                                                metadata: {
                                                    scribeMetadata: {
                                                        isHostEditing: !currentSeriesEditingFlag,
                                                    },
                                                },
                                            });

                                            if (updatedSeries) {
                                                const instance = new StoredSpinachSeries(updatedSeries);
                                                const newList = storedSeriesListState.storedSeriesList.map((series) => {
                                                    if (series.id === instance.id) {
                                                        return instance;
                                                    } else {
                                                        return series;
                                                    }
                                                });
                                                setStoredSeriesList(newList);
                                            }
                                        }}
                                    />
                                    <BodySmallOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                                        Let me edit summaries before they publish.
                                    </BodySmallOnboard>
                                </span>
                            </BootstrapTooltip>
                        ) : (
                            <Column style={{ marginLeft: '15px' }}>
                                <BodyRegularOnboard>
                                    Summaries are immediately sent out.{' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Click Upgrade Edit Summary (Settings Section)`,
                                            });
                                            URLUtil.openURL(paymentUrl, {
                                                redirectThroughBackendWhenOnZoom: true,
                                            });
                                        }}
                                    >
                                        Upgrade to Pro
                                    </Anchor>{' '}
                                    to edit beforehand.
                                </BodyRegularOnboard>
                            </Column>
                        )}
                    </>
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 3} />
        </>
    );
}

function MeetingConfigurationDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    return (
        <Column style={{ width: '100%' }}>
            <EditSummaryDetails storedSeries={storedSeries} />
            <MeetingCommunicationDetails storedSeries={storedSeries} />
            <KnowledgeBaseIntegrationDetails
                knowledgeBase={SpinachIntegration.Confluence}
                storedSeries={storedSeries}
            />
            <KnowledgeBaseIntegrationDetails knowledgeBase={SpinachIntegration.Notion} storedSeries={storedSeries} />
            <KnowledgeBaseIntegrationDetails
                knowledgeBase={SpinachIntegration.GoogleDrive}
                storedSeries={storedSeries}
            />
        </Column>
    );
}

const MeetingSwitchComponent = ({
    isUpdatingEvent,
    isChecked,
    onChange,
}: {
    isUpdatingEvent: boolean;
    onChange: () => void;
    isChecked: boolean;
}) => {
    return isUpdatingEvent ? (
        <CircularProgress size={'38px'} style={{ color: lightTheme.secondary.green }} />
    ) : (
        <SpinachSwitch checked={isChecked} onChange={onChange} onClick={(e) => e.stopPropagation()} />
    );
};

const InviteSpinachDetails = () => (
    <Row
        style={{
            backgroundColor: '#D1F8EF',
            borderRadius: '4px',
            justifyContent: 'center',
            padding: '10px 8px 10px 2px',
        }}
    >
        <Info style={{ color: lightTheme.status.positive, padding: '0px 8px 0px 3px' }} />
        <Column>
            <BodyRegularOnboard style={{ fontWeight: 400 }}>Toggle this meeting on to customize it.</BodyRegularOnboard>
        </Column>
    </Row>
);

const MeetingErrorDetails = ({ meetingTitle }: { meetingTitle?: string | null }) => {
    const scribeEmail = useScribeEmail();
    return (
        <Row
            style={{
                backgroundColor: '#FFA87729',
                borderRadius: '4px',
                padding: '10px 8px 10px 2px',
            }}
        >
            <ErrorOutline style={{ color: '#F26E24', padding: '0px 8px 3px 3px' }} />
            <Column>
                <BodyRegularOnboard style={{ fontWeight: 400 }}>
                    We were unable to add Spinach to this meeting. You can reach out to the meeting owner and ask them
                    to add <b>{scribeEmail}</b> to the calendar invite.
                </BodyRegularOnboard>
                <Spacing factor={1 / 2} />
                <BodyRegularOnboard style={{ fontWeight: 400 }}>
                    Are you the meeting owner?{' '}
                    <a
                        href={`mailto:support@spinach.io?subject=${encodeURI(
                            'Failed to add Spinach to meeting'
                        )}&body=${encodeURI(
                            `I tried to add Spinach to my meeting ${
                                meetingTitle ? `titled "${meetingTitle}"` : ''
                            } but was unable to.`
                        )}`}
                    >
                        Let us know
                    </a>{' '}
                    and we'll fix this!
                </BodyRegularOnboard>
            </Column>
        </Row>
    );
};

type CombinedMeetingDetailsProps = {
    isOpen: boolean;
    isOnboardingFlow: boolean;
    storedSeries?: StoredSpinachSeries;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    calendarEvent: CalendarEvent;
    setIsOpen: () => void;
    setFailedEvents: SetValue<string[]>;
    onChange: () => void;
    updatingEvents: string[];
    isError?: boolean;
};

export function CombinedMeetingDetails({
    isOpen,
    setIsOpen,
    isOnboardingFlow,
    onboardingEventsToAddScribeTo,
    storedSeries,
    calendarEvent,
    updatingEvents,
    isError,
    onChange,
}: CombinedMeetingDetailsProps): JSX.Element {
    const isUpdatingEvent = Boolean(updatingEvents.includes(getCalendarRecurringId(calendarEvent)));
    const { isDesktopView } = useWindowSize();

    const meetingSwitchComponent = isError ? (
        <ErrorOutline
            style={{ color: '#F26E24', height: '38px', width: '24px', paddingLeft: '24px', paddingRight: '13px' }}
        />
    ) : storedSeries?.isBotInvitedWithoutLink ? (
        <BootstrapTooltip title="Add a video link to this calendar event so Spinach can join.">
            <Warning htmlColor={lightTheme.primary.orangeLight} style={{ zIndex: 100 }} />
        </BootstrapTooltip>
    ) : (
        <MeetingSwitchComponent
            isChecked={
                isScribeOnEvent(calendarEvent) ||
                (isOnboardingFlow && onboardingEventsToAddScribeTo.includes(calendarEvent))
            }
            isUpdatingEvent={isUpdatingEvent}
            onChange={onChange}
        />
    );

    const isAccordionDisabled = Boolean(
        calendarEvent.end?.dateTime &&
            moment(calendarEvent.end.dateTime)
                .tz(calendarEvent.end.timeZone ?? TimeUtils.getTimezoneRegion())
                .isBefore(moment().tz(TimeUtils.getTimezoneRegion()))
    );
    const meetingTitle = calendarEvent.summary ?? storedSeries?.meetingTitle;
    return (
        <Row
            style={{
                width: isDesktopView ? '80%' : '100%',
                marginBottom: '20px',
                marginLeft: '10px',
            }}
        >
            <BootstrapTooltip placement="top" title={isAccordionDisabled ? 'This event has already passed' : ''}>
                <Accordion
                    expanded={isOpen}
                    onChange={setIsOpen}
                    disabled={Boolean(!storedSeries && isAccordionDisabled)}
                    elevation={4}
                    style={{ width: '100%' }}
                >
                    <StyledAccordionSummary isDesktopView={!!isDesktopView}>
                        <Row
                            style={{
                                alignItems: 'center',
                                padding: '5px',
                                height: '20px',
                            }}
                        >
                            <Row
                                style={{
                                    width: '15%',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                }}
                            >
                                <ExpandMore
                                    style={{
                                        transition: '100ms',
                                        transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)',
                                    }}
                                />
                                <BodyRegularOnboardWithTextWrap>
                                    {moment(calendarEvent.start?.dateTime).format('ddd MM/DD')}
                                </BodyRegularOnboardWithTextWrap>
                            </Row>
                            <span style={{ minWidth: '20px', maxWidth: '50px' }} />
                            <BodyRegularOnboardWithTextWrap style={{ width: '15%' }}>
                                {moment(calendarEvent.start?.dateTime).format('h:mmA')} -{' '}
                                {moment(calendarEvent.end?.dateTime).format('h:mmA')}
                            </BodyRegularOnboardWithTextWrap>
                            <span style={{ minWidth: '20px', maxWidth: '50px' }} />
                            <BodyRegularOnboardWithTextWrap
                                style={{
                                    flexGrow: 1,
                                }}
                                className={withFullStoryMasking()}
                            >
                                {meetingTitle}
                            </BodyRegularOnboardWithTextWrap>
                            <span style={{ minWidth: '20px', maxWidth: '50px' }} />
                            {meetingSwitchComponent}
                        </Row>
                    </StyledAccordionSummary>
                    <AccordionDetails style={{ padding: '20px' }}>
                        {isError ? (
                            <MeetingErrorDetails meetingTitle={meetingTitle} />
                        ) : storedSeries && isScribeOnEvent(calendarEvent) ? (
                            <MeetingConfigurationDetails storedSeries={storedSeries} />
                        ) : (
                            <InviteSpinachDetails />
                        )}
                    </AccordionDetails>
                </Accordion>
            </BootstrapTooltip>
        </Row>
    );
}
