import PropTypes from 'prop-types';

import { LoginButton } from '../LoginButton';
import Icon from './icon';
import useGoogleLogin from './useGoogleLogin';

export const GoogleLogin = (props: any) => {
    const {
        onSuccess,
        onAutoLoadFinished,
        onRequest,
        onFailure,
        onScriptLoadFailure,
        render,
        disabled: disabledProp,
        clientId,
        cookiePolicy,
        loginHint,
        hostedDomain,
        autoLoad,
        isSignedIn,
        fetchBasicProfile,
        redirectUri,
        discoveryDocs,
        uxMode,
        scope,
        accessType,
        responseType,
        jsSrc,
        prompt,
    } = props;

    const { signIn, loaded } = useGoogleLogin({
        onSuccess,
        onAutoLoadFinished,
        onRequest,
        onFailure,
        onScriptLoadFailure,
        clientId,
        cookiePolicy,
        loginHint,
        hostedDomain,
        autoLoad,
        isSignedIn,
        fetchBasicProfile,
        redirectUri,
        discoveryDocs,
        uxMode,
        scope,
        accessType,
        responseType,
        jsSrc,
        prompt,
    });
    const disabled = !!(disabledProp || !loaded);

    if (render) {
        return render({ onClick: signIn, disabled });
    }

    return (
        <LoginButton
            onClick={signIn}
            icon={<Icon disabled={disabled} />}
            text="Sign in with Google"
            disabled={disabled}
        />
    );
};

GoogleLogin.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    onScriptLoadFailure: PropTypes.func,
    clientId: PropTypes.string.isRequired,
    jsSrc: PropTypes.string,
    onRequest: PropTypes.func,
    buttonText: PropTypes.node,
    scope: PropTypes.string,
    className: PropTypes.string,
    redirectUri: PropTypes.string,
    cookiePolicy: PropTypes.string,
    loginHint: PropTypes.string,
    hostedDomain: PropTypes.string,
    children: PropTypes.node,
    disabledStyle: PropTypes.object,
    fetchBasicProfile: PropTypes.bool,
    prompt: PropTypes.string,
    tag: PropTypes.string,
    autoLoad: PropTypes.bool,
    disabled: PropTypes.bool,
    discoveryDocs: PropTypes.array,
    uxMode: PropTypes.string,
    isSignedIn: PropTypes.bool,
    responseType: PropTypes.string,
    type: PropTypes.string,
    accessType: PropTypes.string,
    render: PropTypes.func,
    theme: PropTypes.string,
    icon: PropTypes.bool,
};

GoogleLogin.defaultProps = {
    type: 'button',
    tag: 'button',
    buttonText: 'Connect with Google',
    scope: 'profile email',
    accessType: 'online',
    prompt: '',
    cookiePolicy: 'single_host_origin',
    fetchBasicProfile: true,
    isSignedIn: false,
    uxMode: 'popup',
    disabledStyle: {
        opacity: 0.6,
    },
    icon: true,
    theme: 'light',
    onRequest: () => {},
};
