import { Warning } from '@material-ui/icons';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { BodyRegularOnboard, lightTheme, responsiveness } from '../../../../styles';
import { IntegrationSettingsButton } from './IntegrationSettingsButton';
import { RemoveIntegrationButton } from './RemoveIntegrationButton';

const Body = styled(BodyRegularOnboard)`
    @media ${responsiveness.sm} {
        font-size: 14px;
    }
`;

export function UnfinishedRowContents({
    label,
    isRemoveVisible,
    onRemoveClick,
    onSettingsClick,
}: {
    label: ReactNode;
    isRemoveVisible: boolean;
    onRemoveClick: () => Promise<void>;
    onSettingsClick?: () => Promise<void>;
}): JSX.Element {
    return (
        <>
            <Warning fontSize="small" style={{ marginRight: '5px' }} htmlColor={lightTheme.primary.orangeLight} />

            <Body>{label}</Body>

            {onSettingsClick ? (
                <IntegrationSettingsButton
                    onClick={onSettingsClick}
                    isVisible={isRemoveVisible}
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                />
            ) : null}

            <RemoveIntegrationButton onClick={onRemoveClick} isVisible={isRemoveVisible} />
        </>
    );
}
