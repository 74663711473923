import { Stage } from '@spinach-shared/types';

export const SCRIBE_EMAILS_PROD = ['spinach@spinach.io', 'ai@spinach.io', 'scrum@spinach.io'] as const;
export const SCRIBE_EMAILS_TEST = ['scribe-test@spinach.io', 'scribe-test-2@spinach.io'] as const;

export function getScribeEmails(stage: Stage): readonly string[] {
    return stage === Stage.Production ? SCRIBE_EMAILS_PROD : SCRIBE_EMAILS_TEST;
}

export function getDefaultScribeEmail(stage: Stage): string {
    // default to ai@spinach.io
    return stage === Stage.Production ? getScribeEmails(stage)[1] : getScribeEmails(stage)[0];
}
