import { Box } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import moment from 'moment';

import { useGlobalFacilitation } from '../../../hooks';
import { BodyLarge, BodyRegular, lightTheme } from '../../../styles';
import { Column, Spacing } from '../../common';
import { GenericSpinachInsight } from './SpinachInsight';
import { TimedHeader } from './TimedHeader';
import { Header, Padding, ScrollView } from './common';

export function GapAnalysisContent(): JSX.Element {
    const [facilitation] = useGlobalFacilitation();

    return (
        <>
            <TimedHeader title={'AI Gap Analysis'} />

            <Spacing factor={1 / 5} />

            <ScrollView sidePadding={0} stretch style={{ height: 'calc(100% - 50px)' }}>
                <Padding>
                    <BodyLarge>Insights</BodyLarge>
                    <Spacing factor={1 / 2} />
                    {facilitation.roundtableWrapup.insights.map((insight) => (
                        <GenericSpinachInsight
                            key={insight.id}
                            title={insight.title}
                            kind={insight.kind}
                            icon={<AlternateEmailIcon htmlColor={lightTheme.tertiary.green} />}
                            // highlightColor={lightTheme.tertiary.green}
                            context={insight.context}
                        />
                    ))}

                    {!facilitation.roundtableWrapup.insights.length ? (
                        <BodyRegular>An analysis of each person's update will be provided here.</BodyRegular>
                    ) : null}

                    <Box style={{ fontWeight: 'bold', textAlign: 'center', opacity: 0.35 }}>
                        Insights may take up to a minute to appear
                    </Box>
                </Padding>
            </ScrollView>
        </>
    );
}
