import { IconButton, Modal } from '@material-ui/core';
import { WarningOutlined } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment-timezone';
import { useState } from 'react';

import { CalendarEvent, CalendarEventType, ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    TimeUtils,
    getCalUidPrefix,
    getCalendarRecurringId,
    getUniques,
} from '@spinach-shared/utils';

import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useIsNonOrganizerAllowedToRemoveSpinach,
    useWindowSize,
} from '../../../../hooks';
import { useCalendarEvents, useUpdateScribeOnEventWithOverrides } from '../../../../hooks/useCalendarEvents';
import { useGlobalAiMeetingSelection } from '../../../../hooks/useGlobalAiMeetingSelection';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { isScribeEmail, useScribeEmail } from '../../../../hooks/useScribe';
import {
    BodyBigOnboard,
    BodyRegularOnboard,
    BodyRegularOnboardWithTextWrap,
    HeaderThree,
    HeaderTwo,
    lightTheme,
} from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, Row, Spacing, SpinachModalContent } from '../../../common';
import { GoogleLoginComponent } from '../../../common/GoogleLoginComponent';
import { PrimaryButton, ScrollArea } from '../../../stand-up';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { isScribeOnEvent } from '../../ScribeCalendarPage';
import { DoItManually } from '../../onboarding/common';
import { AiModalKind } from '../types';
import { CombinedMeetingDetails } from './CombinedMeetingDetails';
import './MeetingSection.css';

const PAGE_SIZE = 20;

function RemoveConfirmationModal({
    isOpen,
    onClose,
    onClick,
}: {
    isOpen: boolean;
    onClose: () => void;
    onClick: () => void;
}) {
    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                style={{
                    overflowY: 'unset',
                    maxHeight: 'unset',
                    minHeight: 'unset',
                    height: '350px',
                }}
                onClose={onClose}
            >
                <Spacing />
                <Column
                    style={{
                        flexGrow: 1,
                        padding: '15px',
                        borderRadius: '4px',
                    }}
                >
                    <Column style={{ flexGrow: 1, paddingLeft: '24px' }}>
                        <Row style={{ alignItems: 'end' }}>
                            <WarningOutlined
                                htmlColor={lightTheme.primary.orangeLight}
                                style={{ paddingRight: '3px' }}
                            />
                            <HeaderThree style={{ display: 'contents', verticalAlign: 'bottom' }}>
                                Are you sure?
                            </HeaderThree>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                You’re not the organizer of this meeting.
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                If you remove Spinach, your team will no longer receive summaries for all future
                                meetings in this series.
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <span style={{ width: '24px', paddingRight: '3px' }} />
                            <BodyRegularOnboard style={{ width: '100%' }}>
                                Are you sure you want to remove Spinach?
                            </BodyRegularOnboard>
                        </Row>
                    </Column>
                    <Row centered>
                        <PrimaryButton title="Nevermind" onClick={onClose} />
                        <Spacing factor={1 / 2} horizontal={true} />
                        <SecondaryButton title="Remove Spinach" onClick={onClick} />
                    </Row>
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}

export function CombinedMeetingSection({
    storedSeriesList,
}: {
    storedSeriesList: StoredSpinachSeries[];
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    setModalKind: (modalKind: AiModalKind | null) => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection();

    const {
        openedMeetingsState: { openMeetingDetails },
        openMeetingByCalEventId,
        closeMeetingByCalEventId,
    } = useGlobalAiMeetingSelection();

    const { data, queryKey, refetch, isRefetching, isFetching } = useCalendarEvents({
        timeMin: moment
            .tz(TimeUtils.getTimezoneRegion())
            .subtract(1, 'hour')
            .minutes(0)
            .seconds(0)
            .milliseconds(0)
            .toISOString(),
        timeMax: moment.tz(TimeUtils.getTimezoneRegion()).add(10, 'weeks').endOf('day').toISOString(),
        maxResults: 500,
    });

    const [onboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [updatingEvents, setUpdatingEvents] = useState<string[]>([]);
    const [failedEvents, setFailedEvents] = useState<string[]>([]);

    const { fetch, isLoading } = useStoredSeriesListFetcher({ recurringOnly: false });
    const { mutate: updateScribeOnEvent } = useUpdateScribeOnEventWithOverrides(queryKey, {
        onSuccess: async (settledEvent, _data) => {
            if (!settledEvent) {
                setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getCalendarRecurringId(_data.event)]));
                return;
            }

            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getCalendarRecurringId(settledEvent)) {
                        return false;
                    }
                    return true;
                })
            );

            // wait just a tidbit for recall / google to sync after the update before refreshing
            await new Promise((resolve) => setTimeout(resolve, 800));
            await fetch();
            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getCalendarRecurringId(_data.event)) {
                        return false;
                    }
                    return true;
                })
            );
        },
        onError: (_, _data) => {
            track(ClientEventType.FailedToUpdateMeeting, {
                RecurringCalendarEventId: getCalendarRecurringId(_data.event),
                ICalUid: _data.event.iCalUID,
                AddToEvent: _data.addToEvent,
            });
            setFailedEvents?.((failedEvents) => getUniques([...failedEvents, getCalendarRecurringId(_data.event)]));
            setUpdatingEvents((updatingEvents) =>
                updatingEvents.filter((id) => {
                    if (id === getCalendarRecurringId(_data.event)) {
                        return false;
                    }
                    return true;
                })
            );
        },
    });

    const [removeModalState, setRemoveModalState] = useState<{
        isOpen: boolean;
        calendarEvent: (CalendarEvent & { iCalUID: string }) | null;
    }>({
        isOpen: false,
        calendarEvent: null,
    });

    const [page, setPage] = useState(0);
    const validCalendarEvents = data?.filter(
        (d) =>
            d.eventType === CalendarEventType.Default &&
            d.end?.dateTime &&
            moment(d.end.dateTime)
                .tz(d.end.timeZone ?? TimeUtils.getTimezoneRegion())
                .isAfter(moment().tz(TimeUtils.getTimezoneRegion()))
    );
    const eventsToUse = validCalendarEvents?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE);

    const hasAnotherPage = validCalendarEvents?.length && validCalendarEvents.length > page * PAGE_SIZE + PAGE_SIZE;
    const hasPreviousPage = page !== 0;

    const isNotGoogleUser = !user.googleId;
    const isNotMicrosoftUser = !user.microsoftId;
    const isDesktopView = width > 800;

    const scribeEmail = useScribeEmail();
    const isNonOrganizerAllowedToRemoveSpinach = useIsNonOrganizerAllowedToRemoveSpinach();

    const toggleScribeOnEvent = (event: CalendarEvent & { iCalUID: string }) => {
        const isScribeOn = isScribeOnEvent(event);

        const iCalUID = event.iCalUID;
        if (!iCalUID) {
            return;
        }
        setUpdatingEvents(getUniques([...updatingEvents, ...[getCalendarRecurringId(event)]]));

        updateScribeOnEvent({
            event: { ...event, iCalUID }, // Ensure Typescript correctly infers the type of the event
            // Should always be false since we are removing scribe from the event
            addToEvent: !isScribeOn,
        }); // toggle scribe on event
    };

    if (!user.isAuthedForGoogleCalendar && !!user.googleId) {
        return (
            <>
                <Spacing factor={2} />
                <Row>
                    <BodyBigOnboard>
                        Connect to your calendar to control which meetings Spinach joins and summarizes
                    </BodyBigOnboard>
                </Row>
                <Spacing />
                <Row>
                    <GoogleLoginComponent
                        text="Connect Google Calendar"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Connect to Google via Meeting Section`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                            );
                        }}
                    />
                </Row>
            </>
        );
    }

    function mapEventRows(eventsToRender: CalendarEvent[]) {
        return eventsToRender.map((event) => {
            const associatedSeries = storedSeriesList.find((series) =>
                series.iCalUid && event.iCalUID
                    ? series.iCalUid === getCalendarRecurringId(event) ||
                      getCalUidPrefix(series.iCalUid) === getCalUidPrefix(event.iCalUID)
                    : false
            );
            return (
                <CombinedMeetingDetails
                    calendarEvent={event}
                    isOpen={Boolean(event.id && openMeetingDetails.includes(event.id))}
                    setIsOpen={() => {
                        if (event.id && openMeetingDetails.includes(event.id)) {
                            closeMeetingByCalEventId(event.id);
                        } else if (event.id) {
                            openMeetingByCalEventId(event.id);
                        }
                    }}
                    isOnboardingFlow={false}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    storedSeries={associatedSeries}
                    onChange={() => {
                        const iCalUID = event?.iCalUID;
                        if (!iCalUID) {
                            return;
                        }

                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);

                        const analyticsData = {
                            ICalUid: event.iCalUID,
                            Action: isScribeOn ? 'remove' : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: false,
                            AttendeeCount: event.attendees?.length,
                            ChosenScribeEmail:
                                /**
                                 * @NOTE If scribe is already on the event we show this email as being the chosen email
                                 * since we will be accepting/decling events and not adding/removing */
                                event.attendees?.find((attendee) => isScribeEmail(attendee.email))?.email ??
                                scribeEmail,
                            CalendarProvider: user.calendarProvider,
                        };

                        track(ClientEventType.CalendarMeetingItemClick, analyticsData);

                        if (isScribeOn && !isOrganizer) {
                            if (!isNonOrganizerAllowedToRemoveSpinach) {
                                return;
                            }
                            track(ClientEventType.MeetingItemRemoveModalShown, analyticsData);
                            setRemoveModalState({ isOpen: true, calendarEvent: { ...event, iCalUID } });
                            return;
                        }

                        toggleScribeOnEvent({ ...event, iCalUID });
                    }}
                    updatingEvents={updatingEvents}
                    isError={Boolean(event.iCalUID && failedEvents.includes(getCalendarRecurringId(event)))}
                    setFailedEvents={setFailedEvents}
                />
            );
        });
    }

    return (
        <>
            <Spacing factor={2} />
            {isDesktopView ? (
                <Row vCenter>
                    <HeaderTwo>Upcoming Meetings</HeaderTwo>
                    <IconButton
                        onClick={async () => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Refresh Calendar Events',
                            });
                            await Promise.all([refetch(), fetch()]);
                        }}
                        disableRipple
                        disabled={isRefetching || isLoading || isFetching}
                        className={isRefetching || isFetching ? 'refresh-upcoming-meetings' : undefined}
                    >
                        <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                    </IconButton>
                </Row>
            ) : null}

            <Spacing />
            {isNotGoogleUser && isNotMicrosoftUser ? (
                <Row centered style={{ width: '90%' }}>
                    <Column style={{ maxWidth: '500px' }}>
                        <DoItManually />
                    </Column>
                </Row>
            ) : (
                <>
                    <Row
                        style={{
                            paddingTop: '10px',
                            paddingBottom: '10px',
                            marginLeft: '10px',
                            marginBottom: '20px',
                            backgroundColor: '#F4F6F6',
                            width: isDesktopView ? '80%' : '100%',
                            height: 'fit-content',
                        }}
                    >
                        <Row style={{ width: '15%', paddingLeft: '22px' }}>
                            <span style={{ width: '24px' }} />
                            <BodyRegularOnboardWithTextWrap style={{ paddingRight: 'unset' }}>
                                Date
                            </BodyRegularOnboardWithTextWrap>
                        </Row>
                        <span style={{ minWidth: '20px', maxWidth: '50px' }} />
                        <BodyRegularOnboardWithTextWrap style={{ width: '15%' }}>
                            Time ({moment.tz(TimeUtils.getTimezoneRegion()).zoneAbbr()})
                        </BodyRegularOnboardWithTextWrap>
                        <span style={{ minWidth: '20px', maxWidth: '50px' }} />
                        <BodyRegularOnboardWithTextWrap style={{ flexGrow: 1 }}>Meeting</BodyRegularOnboardWithTextWrap>
                        <BodyRegularOnboardWithTextWrap style={{ paddingRight: '15px', textAlign: 'end' }}>
                            Attend
                        </BodyRegularOnboardWithTextWrap>
                    </Row>
                    <ScrollArea sidePadding={0} style={{ background: 'transparent' }}>
                        {eventsToUse?.length ? (
                            <>
                                {mapEventRows(eventsToUse)}
                                {hasPreviousPage || hasAnotherPage ? (
                                    <>
                                        <Row centered style={{ marginTop: '8px', width: '80%' }}>
                                            <OutlinedButton
                                                disabled={!hasPreviousPage}
                                                style={{ marginRight: '10px' }}
                                                title="Previous"
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Previous Meeting Page',
                                                    });
                                                    setPage((page) => page - 1);
                                                }}
                                            />
                                            <OutlinedButton
                                                disabled={!hasAnotherPage}
                                                title="Next"
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: 'Next Meeting Page',
                                                    });
                                                    setPage((page) => page + 1);
                                                }}
                                            />
                                        </Row>
                                        <Spacing factor={1 / 2} />
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : !eventsToUse ? (
                            <></>
                        ) : (
                            <>
                                <Column>
                                    <Spacing factor={2} />
                                    <Row centered>
                                        {!!user.googleId && !user.isAuthedForGoogleCalendar ? (
                                            <GoogleLoginComponent
                                                text="Connect Google Calendar"
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: `Connect to Google via Meeting Section`,
                                                    });
                                                    startDetection();
                                                    URLUtil.openURL(
                                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <Column centered>
                                                <BodyRegularOnboard>
                                                    It doesn't look like you have any meetings scheduled in the next few
                                                    weeks.
                                                </BodyRegularOnboard>
                                                <Spacing />
                                                <BodyRegularOnboard>
                                                    Schedule a meeting with your team to get started.
                                                </BodyRegularOnboard>
                                            </Column>
                                        )}
                                    </Row>
                                </Column>
                            </>
                        )}
                    </ScrollArea>
                </>
            )}
            <RemoveConfirmationModal
                isOpen={removeModalState.isOpen}
                onClose={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Remove Spinach Modal Close',
                    });
                    setRemoveModalState({ isOpen: false, calendarEvent: null });
                }}
                onClick={() => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Remove Spinach Modal Confirmation',
                        ICalUid: removeModalState.calendarEvent?.iCalUID,
                    });
                    if (!removeModalState.calendarEvent) {
                        setRemoveModalState({ isOpen: false, calendarEvent: null });
                        return;
                    }
                    toggleScribeOnEvent(removeModalState.calendarEvent);
                    setRemoveModalState({ isOpen: false, calendarEvent: null });
                }}
            />
        </>
    );
}
