import { IconButton } from '@material-ui/core';
import { ReactNode } from 'react';

import { FacilitatedStartStops } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';
import { formatTime } from '@spinach-shared/utils';

import { ReactComponent as CheckIcon } from '../../../assets/circle-chevron.svg';
import { useFacilitationTracking, useGlobalBotIdPair, useGlobalFacilitation, useTimer } from '../../../hooks';
import { withFullStoryMasking } from '../../../utils';
import { Row } from '../../common';
import { Header, getCurrentTimedView, getNextAgendaFacilitation, getPreviousAgendaFacilitation } from './common';

export type TimedHeaderProps = {
    title: ReactNode;
    isPii?: boolean;
};

export type ForwardBackwardButtonsProps = {
    hideBackward?: boolean;
    hideForward?: boolean;
};

export function ForwardBackwardButtons({ hideBackward, hideForward }: ForwardBackwardButtonsProps): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();
    const [botIdPair] = useGlobalBotIdPair();
    const track = useFacilitationTracking();

    return (
        <>
            {!hideBackward ? (
                <IconButton
                    size="small"
                    onClick={() => {
                        track(ClientEventType.AIFacilitationClick, {
                            ClickedOn: 'Navigate Backwards',
                        });
                        const updatedFacilitationState = getPreviousAgendaFacilitation(facilitation, botIdPair);
                        setFacilitation((facilitation) => ({
                            ...facilitation,
                            ...updatedFacilitationState,
                        }));
                    }}
                >
                    {/* <ArrowLeftIcon fontSize="large" htmlColor={lightTheme.primary.midnight} /> */}
                    <CheckIcon style={{ transform: 'rotate(180deg)' }} />
                </IconButton>
            ) : null}
            {!hideForward ? (
                <IconButton
                    size="small"
                    onClick={() => {
                        track(ClientEventType.AIFacilitationClick, {
                            ClickedOn: 'Navigate Forwards',
                        });
                        const updatedFacilitationState = getNextAgendaFacilitation(facilitation, botIdPair);
                        setFacilitation((facilitation) => ({
                            ...facilitation,
                            ...updatedFacilitationState,
                        }));
                    }}
                >
                    {/* <ArrowRightIcon fontSize="large" htmlColor={lightTheme.primary.midnight} /> */}
                    <CheckIcon />
                </IconButton>
            ) : null}
        </>
    );
}

export function TimedHeader({
    title,
    hideBackward,
    hideForward,
    isPii = false,
}: TimedHeaderProps & ForwardBackwardButtonsProps): JSX.Element {
    const [facilitation] = useGlobalFacilitation();

    const currentView = getCurrentTimedView(facilitation);

    const startStops = new FacilitatedStartStops(currentView?.startStops ?? []);
    const time = useTimer(startStops.inProgress()?.startedAt);

    if (!currentView) {
        // TODO-insights - handle error scenario
        return <>no view matching currentViewId</>;
    }

    return (
        <Row
            style={{
                justifyContent: 'space-between',
                marginLeft: '20px',
                marginTop: '20px',
                marginRight: '20px',
                width: 'calc(100% - 40px)',
            }}
        >
            <Header className={isPii ? withFullStoryMasking() : undefined}>{title}</Header>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Header
                    key={currentView.viewId}
                    style={{
                        flexShrink: 0,
                        minWidth: '60px',
                        marginRight: '5px',
                    }}
                >
                    {formatTime(time + startStops.totalTime)}
                </Header>
                <ForwardBackwardButtons hideBackward={hideBackward} hideForward={hideForward} />
            </span>
        </Row>
    );
}
