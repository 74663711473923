import { ClientEventType } from '@spinach-shared/types';

import { useLandingAnalytic } from '../../../hooks';
import { HeaderThreeOnboard } from '../../../styles';
import { Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { BackButtonRow, DoItManually, Stationary, StepProps } from './common';

export type ManualInviteProps = StepProps & {
    onBack: () => void;
    onSkip: () => void;
};

export function ManualInvite({ onBack, direction, onSkip, onSubmit }: ManualInviteProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedManualSpinachSetupStep);
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Experience the magic 🪄✨</HeaderThreeOnboard>
            <ScrollView sidePadding={0}>
                <SlidingColumn centered direction={direction}>
                    <Spacing />

                    <DoItManually />
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={1 / 2} />
            <Row centered>
                <PrimaryButton title={'Confirm'} onClick={onSubmit} />
            </Row>
            <Spacing factor={1 / 2} />
            <Row centered>
                <SecondaryButton title={'Skip'} onClick={onSkip} />
            </Row>
        </Stationary>
    );
}
