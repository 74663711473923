import { SpinachAPIPath } from '@spinach-shared/types';

import { NotionPage } from '@spinach-backend/types';

import { getSpinachAPI } from './getSpinachAPI';

export const fetchNotionPages = async () => {
    try {
        const response = await getSpinachAPI<NotionPage[]>(SpinachAPIPath.NotionPages);
        return response ?? [];
    } catch (error) {
        return [];
    }
};
