import { IconButton } from '@material-ui/core';
import { AddCircleOutline, ChevronLeft, Warning } from '@material-ui/icons';
import CachedIcon from '@material-ui/icons/Cached';
import moment from 'moment';
import { useState } from 'react';

import { CalendarEvent, ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    TimeUtils,
    getCalendarRecurringId,
    getUniqueEvents,
    getUniques,
} from '@spinach-shared/utils';

import GoogleCalendarSrc from '../../../../assets/google-cal.png';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useIntegrationDetection,
    useIsNonOrganizerAllowedToRemoveSpinach,
    useWindowSize,
} from '../../../../hooks';
import { useCalendarEvents, useUpdateScribeOnEvent } from '../../../../hooks/useCalendarEvents';
import { useGlobalAiMeetingSelection } from '../../../../hooks/useGlobalAiMeetingSelection';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { isScribeEmail, useScribeEmail } from '../../../../hooks/useScribe';
import { BodyBigOnboard, BodyRegularOnboard, HeaderThree, HeaderTwo, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, Row, Spacing } from '../../../common';
import { GoogleLoginComponent } from '../../../common/GoogleLoginComponent';
import { Notification, PrimaryButton, ScrollArea } from '../../../stand-up';
import { SelectCalendarEventsForSpinach, isScribeOnEvent } from '../../ScribeCalendarPage';
import { DoItManually } from '../../onboarding/common';
import { AiModalKind } from '../types';
import { MeetingDetails } from './MeetingDetails';
import './MeetingSection.css';

export function MeetingSection({
    storedSeriesList,
    setStoredSeriesList,
    setModalKind,
}: {
    storedSeriesList: StoredSpinachSeries[];
    isAddingAdditionalMeetings: boolean;
    setIsAddingAdditionalMeetings: (value: boolean) => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    setModalKind: (modalKind: AiModalKind | null) => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection();
    const {
        openedMeetingsState: {
            openMeetingDetails,
            highlightedMeetingDetails,
            isAddingAdditionalMeetings,
            showInviteErrorToast,
        },
        clearHighlightedMeeting,
        setShowInviteErrorToast,
        openMeetingBySeriesId,
        setIsAddingAdditionalMeetings,
        closeMeetingBySeriesId,
    } = useGlobalAiMeetingSelection();
    const { data, queryKey, error, refetch, isRefetching, isFetching } = useCalendarEvents({
        timeMin: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
        timeMax: moment.tz(TimeUtils.getTimezoneRegion()).add(28, 'days').endOf('day').toISOString(),
    });
    const [onboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [updatingEvents, setUpdatingEvents] = useState<string[]>([]);

    const { mutate: updateScribeOnEvent } = useUpdateScribeOnEvent(queryKey, setUpdatingEvents);
    const { fetch, isLoading } = useStoredSeriesListFetcher({ recurringOnly: false });

    const scribeEmail = useScribeEmail();

    const eventsToUse = getUniqueEvents(data ?? []);

    const isNotMicrosoftUser = !user.microsoftId;
    const isDesktopView = width > 800;
    const [shouldShowNotification, setShouldShowNotification] = useState(!!highlightedMeetingDetails);
    const isNonOrganizerAllowedToRemoveSpinach = useIsNonOrganizerAllowedToRemoveSpinach();

    if (isAddingAdditionalMeetings) {
        return (
            <>
                <Spacing factor={2} />
                <Row style={{ alignItems: 'center' }}>
                    <span
                        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Go Back from Add Additional Meetings`,
                            });
                            setIsAddingAdditionalMeetings(false);
                        }}
                    >
                        <ChevronLeft
                            fontSize="large"
                            style={{ marginRight: '5px', marginLeft: '-10px' }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                        <HeaderThree>Go back</HeaderThree>
                    </span>
                </Row>
                <Spacing />
                <Row style={{ width: isDesktopView ? '80%' : '100%' }}>
                    <HeaderTwo>Add Spinach to your meetings</HeaderTwo>
                    <IconButton
                        onClick={async () => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Refresh Calendar Events',
                            });
                            await refetch();
                        }}
                        disableRipple
                        disabled={isRefetching || isFetching}
                        className={isRefetching || isFetching ? 'refresh-upcoming-meetings' : undefined}
                    >
                        <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                    </IconButton>
                </Row>
                <Spacing />
                {!user.isAuthedForGoogleCalendar && !!user.googleId ? (
                    <>
                        <Row>
                            <BodyBigOnboard>
                                Connect to your calendar to control which meetings Spinach joins and summarizes
                            </BodyBigOnboard>
                        </Row>
                        <Spacing />
                        <Row>
                            <GoogleLoginComponent
                                text="Connect Google Calendar"
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Connect to Google via Meeting Section`,
                                    });
                                    startDetection();
                                    URLUtil.openURL(
                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                    );
                                }}
                            />
                        </Row>
                    </>
                ) : null}
                {eventsToUse ? (
                    <ScrollArea
                        sidePadding={0}
                        style={{
                            height: '100%',
                            width: isDesktopView ? '80%' : '100%',
                            paddingBottom: '30px',
                            background: 'transparent',
                        }}
                    >
                        <SelectCalendarEventsForSpinach
                            isOnboardingFlow={false}
                            hasError={!!error}
                            updatingEvents={updatingEvents}
                            onEventClick={(event: CalendarEvent) => {
                                const isOrganizer = user.isUserTheOrganizer(event);
                                const isScribeOn = isScribeOnEvent(event);

                                if (!isNonOrganizerAllowedToRemoveSpinach && isScribeOn && !isOrganizer) {
                                    return;
                                }

                                track(ClientEventType.CalendarMeetingItemClick, {
                                    ICalUid: event.iCalUID,
                                    Action: isScribeOn ? 'remove' : 'add',
                                    MeetingTitle: event.summary,
                                    IsCurrentUserTheOrganizer: isOrganizer,
                                    IsOnboardingFlow: false,
                                    AttendeeCount: event.attendees?.length,
                                    ChosenScribeEmail:
                                        /**
                                         * @NOTE If scribe is already on the event we show this email as being the chosen email
                                         * since we will be accepting/decling events and not adding/removing */
                                        event.attendees?.find((attendee) => isScribeEmail(attendee.email))?.email ??
                                        scribeEmail,
                                    CalendarProvider: user.calendarProvider,
                                });

                                const iCalUID = event.iCalUID;
                                if (!iCalUID) {
                                    return;
                                }

                                if (highlightedMeetingDetails) {
                                    clearHighlightedMeeting();
                                }

                                setUpdatingEvents(getUniques([...updatingEvents, ...[getCalendarRecurringId(event)]]));

                                updateScribeOnEvent(
                                    {
                                        event: { ...event, iCalUID }, // ensures typescript correctly infers the type of the event
                                        addToEvent: !isScribeOn,
                                    },
                                    {
                                        onSuccess: async () => {
                                            // wait just a tidbit for recall / google to sync after the update before refreshing
                                            await new Promise((resolve) => setTimeout(resolve, 500));
                                            await fetch();
                                        },
                                    }
                                ); // toggle scribe on event
                            }}
                            relevantEvents={eventsToUse}
                            shouldShowNotification={shouldShowNotification}
                            setShouldShowNotification={setShouldShowNotification}
                            onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                        />
                    </ScrollArea>
                ) : null}

                <Notification
                    containerStyle={{ position: 'absolute', bottom: '10px' }}
                    isOpen={showInviteErrorToast}
                    duration={3000}
                    onClose={() => setShowInviteErrorToast(false)}
                    message={'The meeting selected was not found. Try adding Spinach to one of these meeting!'}
                    icon={
                        <Warning style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />
                    }
                />
            </>
        );
    }

    return (
        <>
            <Spacing factor={2} />
            {isDesktopView ? (
                <Row vCenter>
                    <HeaderTwo>Upcoming Meetings</HeaderTwo>
                    <IconButton
                        onClick={async () => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Refresh Upcoming Meetings',
                            });
                            await fetch();
                        }}
                        disableRipple
                        disabled={isLoading}
                        className={isLoading ? 'refresh-upcoming-meetings' : undefined}
                    >
                        <CachedIcon htmlColor={lightTheme.primary.greenLight} fontSize="large" />
                    </IconButton>
                </Row>
            ) : null}

            <Spacing />
            {!!storedSeriesList.length || user.isAuthedForMicrosoftCalendar ? (
                <>
                    {user.isAuthedForAnyCalendar ? (
                        <>
                            <Row style={{ alignItems: 'center' }}>
                                <span
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Add Spinach to Additional Meetings`,
                                        });
                                        setIsAddingAdditionalMeetings(true);
                                    }}
                                >
                                    <AddCircleOutline
                                        fontSize="large"
                                        style={{ marginRight: '5px' }}
                                        htmlColor={lightTheme.primary.greenLight}
                                    />
                                    <HeaderThree>Add or Remove Meetings</HeaderThree>
                                </span>
                            </Row>
                            <Spacing />
                        </>
                    ) : null}

                    <ScrollArea sidePadding={0} style={{ background: 'transparent' }}>
                        {storedSeriesList.map((storedSeries) => {
                            return (
                                <MeetingDetails
                                    key={`${storedSeries.id}-${storedSeries.metadata.scribeMetadata?.isEmailingIcpOnly}`}
                                    isOpen={openMeetingDetails.includes(storedSeries.id)}
                                    setIsOpen={() => {
                                        if (openMeetingDetails.includes(storedSeries.id)) {
                                            closeMeetingBySeriesId(storedSeries.id);
                                        } else {
                                            openMeetingBySeriesId(storedSeries.id);
                                        }
                                    }}
                                    storedSeries={storedSeries}
                                    setStoredSeriesList={setStoredSeriesList}
                                    storedSeriesList={storedSeriesList}
                                    setModalKind={setModalKind}
                                />
                            );
                        })}
                    </ScrollArea>
                </>
            ) : isNotMicrosoftUser || !!user.googleId ? (
                <>
                    <Column>
                        <Row>
                            <BodyRegularOnboard>Your Spinach meetings will show up here.</BodyRegularOnboard>
                        </Row>
                        <Spacing factor={2} />
                        <Row centered>
                            <img src={GoogleCalendarSrc} style={{ width: '40px', height: '40px' }} />
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row centered>
                            {user.isAuthedForGoogleCalendar ? (
                                <PrimaryButton
                                    title="Add a meeting to get started!"
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Add Spinach to Additional Meetings`,
                                        });
                                        setIsAddingAdditionalMeetings(true);
                                    }}
                                />
                            ) : (
                                <GoogleLoginComponent
                                    text="Connect Google Calendar"
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Connect to Google via Meeting Section`,
                                        });
                                        startDetection();
                                        URLUtil.openURL(
                                            `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                                        );
                                    }}
                                />
                            )}
                        </Row>
                    </Column>
                </>
            ) : (
                <Row centered style={{ width: '90%' }}>
                    <Column style={{ maxWidth: '500px' }}>
                        <DoItManually />
                    </Column>
                </Row>
            )}
        </>
    );
}
