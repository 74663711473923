import { SpinachAPIPath, TicketSource, UTMCampaign, UTMSource, WebUrlQuery } from '@spinach-shared/types';

import { isProductionStage, isStagingStage, isTestStage } from '.';

// TODO: update when chrome comes in between subdomains
export function getWebAppHost(): string {
    const url = isProductionStage()
        ? 'https://app.spinach.io'
        : isStagingStage()
        ? 'https://staging.app.spinach.io'
        : isTestStage()
        ? 'https://internal.app.spinach.io'
        : `http://localhost:${process.env.REACT_APP_PORT}`;

    return url;
}

export function getSharedLink(code: string): string {
    return `${getWebAppHost()}/?${WebUrlQuery.Meeting}=${code}`;
}

export type ExternalClickTrackPayload = {
    experiment: string;
    distinct_id: string;
    redirect_url: string;
    cohort?: string | null;
};

export function getCreateTicketFromActionItemsUrl(
    botId: string,
    seriesId: string,
    ticketSource: TicketSource,
    utmCampaign: Extract<
        UTMCampaign,
        UTMCampaign.CreateTicketFromActionsHeaderImage | UTMCampaign.CreateTicketFromActionsRedirectLink
    >,
    actionId?: string
): string {
    return encodeURI(
        `${getWebAppHost()}?${WebUrlQuery.Section}=actions&${WebUrlQuery.Redirect}=/ai/home&${WebUrlQuery.Source}=${
            UTMSource.AiEmailSummary
        }&${WebUrlQuery.UTMSource}=${UTMSource.AiEmailSummary}&${WebUrlQuery.TicketSource}=${ticketSource}&${
            WebUrlQuery.SeriesId
        }=${seriesId}&${WebUrlQuery.BotId}=${botId}&${WebUrlQuery.Campaign}=${utmCampaign}&${
            WebUrlQuery.UTMCampaign
        }=${utmCampaign}&${WebUrlQuery.ActionItemId}=${actionId}`
    );
}

export function getEmailCtaMetadataFromQuery(metadata: string): ExternalClickTrackPayload {
    const decoded = decodeURIComponent(metadata);
    const json: ExternalClickTrackPayload = JSON.parse(decoded);
    return json;
}

export const UTM_COOKIE_NAME = 'spinachSource';
