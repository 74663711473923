import moment from 'moment';
import { useEffect, useState } from 'react';

import { CalendarEvent, ClientEventType, UserCreationSource } from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { patchUser } from '../../../apis';
import { postSlackDefaultUserChannel } from '../../../apis/postSlackDefaultUserChannel';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../hooks';
import { useBulkUpdateScribeOnEvents, useCalendarEventSuggestions } from '../../../hooks/useCalendarEvents';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { useScribeEmail } from '../../../hooks/useScribe';
import { TagManager } from '../../../utils/TagManager';
import { Direction, ViewContainer } from '../../common';
import { isScribeOnEvent } from '../ScribeCalendarPage';
import { AboutStep } from './AboutStep';
import { AddToMeetingsStep } from './AddToMeetingsStep';
import { CalendarPermissionsStep } from './CalendarPermissionsStep';
import { FinishedFlowReverseTrial } from './FinishedFlowReverseTrialStep';
import { FinishedFlow } from './FinishedFlowStep';
import { KnowledgeBaseSetupStep } from './KnowledgeBaseSetupStep';
import { ManualInvite } from './ManualInviteStep';
import { SlackDefaults } from './SlackDefaultsStep';
import { SlackSetup } from './SlackSetupStep';
import { TicketSetup } from './TicketSetupStep';
import { WelcomeToReverseTrial } from './WelcomeToReverseTrialStep';
import { OnboardingStep } from './common';

export function OnboardingFlowContainer(): JSX.Element {
    return (
        <ViewContainer>
            <OnboardingFlow />
        </ViewContainer>
    );
}

/** @NOTE - we don't want to show reverse trial in onboarding flow if theyre eligble but only have a few days left */
const MIN_DAYS_LEFT_TO_USE_TRIAL_FLOW = 5;

export function OnboardingFlow(): JSX.Element {
    const { routeToAIDashboard } = useGlobalRouting();
    const [user, setUser] = useGlobalAuthedUser();

    const shouldUseReverseTrialFlow =
        user.isOnLiveReverseTrial && user.reverseTrialDaysLeft >= MIN_DAYS_LEFT_TO_USE_TRIAL_FLOW;
    const STARTING_STEP = shouldUseReverseTrialFlow ? OnboardingStep.WelcomeToReverseTrial : OnboardingStep.About;
    const FINAL_STEP = shouldUseReverseTrialFlow ? OnboardingStep.ReverseTrialFinishFlow : OnboardingStep.Finished;
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(STARTING_STEP);
    const [direction, setSlidingDirection] = useState<Direction>(Direction.Forward);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { setSubview, closeSettingsModal } = useGlobalMeetingSettings();
    const track = useExperienceTracking();
    const [previousSteps, setPreviousSteps] = useState<OnboardingStep[]>([]);

    const [teamKind, setTeamKind] = useState('');
    const [teamKindOther, setTeamKindOther] = useState('');
    const [role, setRole] = useState('');
    const [otherRole, setOtherRole] = useState('');
    const [howDidYouHear, setHowDidYouHear] = useState('');
    const [howDidYouHearOther, setHowDidYouHearOther] = useState('');

    const [onboardingEventsToAddScribeTo, setOnboardingEventsToAddScribeTo] = useState<CalendarEvent[]>([]);
    const [hasFetched, setHasFetched] = useState(false);
    const { data, error, queryKey, isFetching } = useCalendarEventSuggestions(
        {
            startISOString: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
            endISOString: moment.tz(TimeUtils.getTimezoneRegion()).add(28, 'days').endOf('day').toISOString(),
        },
        !hasFetched
    );
    const scribeEmail = useScribeEmail();
    useEffect(() => {
        if (data && !hasFetched) {
            setHasFetched(true);
            track(ClientEventType.MeetingSuggestionsReceived, {
                TimedOut: data.timedOut,
                SuggestionCount: data.suggestionIds.length,
                EventCount: data.events.length,
                ChosenScribeEmail: scribeEmail,
            });

            if (data.suggestionsWithoutSpinach.length) {
                setOnboardingEventsToAddScribeTo(data.suggestionsWithoutSpinach);
            }
        }
    }, [data, hasFetched, setHasFetched]);

    const { mutateAsync: bulkUpdateAddScribeToEvents, error: bulkError } = useBulkUpdateScribeOnEvents(queryKey);

    // if an error occurs from retrieving or updating events, kill the loading message
    useEffect(() => {
        if (!!error || !!bulkError) {
            setLoadingMessage('');
        }
    }, [error, bulkError]);

    const preSelectedChannel =
        user.slackSettings?.defaultChannelId && user.slackSettings?.defaultChannelName
            ? { code: user.slackSettings?.defaultChannelId, label: user.slackSettings?.defaultChannelName }
            : null;

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(preSelectedChannel);

    function progressTo(step: OnboardingStep, preventGoingBackTo = false) {
        setSlidingDirection(Direction.Forward);

        if (!preventGoingBackTo) {
            setPreviousSteps([onboardingStep, ...previousSteps]);
        }

        setOnboardingStep(step);
    }

    function goBack() {
        setSlidingDirection(Direction.Backward);

        const previousStep = previousSteps[0];

        if (!previousStep) {
            setOnboardingStep(STARTING_STEP);
            return;
        }

        const updatedPreviousSteps = previousSteps.filter((pStep) => previousStep !== pStep);

        setOnboardingStep(previousStep);
        setPreviousSteps(updatedPreviousSteps);
    }

    switch (onboardingStep) {
        case OnboardingStep.WelcomeToReverseTrial:
            return (
                <WelcomeToReverseTrial
                    loadingMessage={''}
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedWelcomeToReverseTrialStep);
                        progressTo(OnboardingStep.About);
                    }}
                />
            );
        case OnboardingStep.About:
            return (
                <AboutStep
                    teamKind={teamKind}
                    setTeamKind={setTeamKind}
                    role={role}
                    setRole={setRole}
                    otherRole={otherRole}
                    setOtherRole={setOtherRole}
                    howDidYouHear={howDidYouHear}
                    setHowDidYouHear={setHowDidYouHear}
                    direction={direction}
                    teamKindOther={teamKindOther}
                    setTeamKindOther={setTeamKindOther}
                    howDidYouHearOther={howDidYouHearOther}
                    setHowDidYouHearOther={setHowDidYouHearOther}
                    onSubmit={async () => {
                        setLoadingMessage('Loading...');

                        track(ClientEventType.UserSubmittedAboutStep, {
                            TeamKind: teamKind,
                            TeamKindOther: teamKindOther,
                            RoleOnTeam: role,
                            RoleOnTeamOther: otherRole,
                            HowDidYouHear: howDidYouHear,
                            HowDidYouHearOther: howDidYouHearOther,
                        });
                        const updatedUser = await patchUser({
                            metadata: {
                                teamKind,
                                teamKindOther,
                                roleOnTeam: role,
                                roleOnTeamOther: otherRole,
                                howDidYouHear,
                                howDidYouHearOther,
                            },
                        });
                        setLoadingMessage('');
                        if (updatedUser.user) {
                            setUser(updatedUser.user);
                        }
                        if (user.isAuthedForAnyCalendar) {
                            progressTo(OnboardingStep.AddSpinachToMeetings);
                        } else if (user.googleId) {
                            progressTo(OnboardingStep.CalendarPermissions);
                        } else {
                            progressTo(OnboardingStep.ManualInvite);
                        }
                    }}
                    loadingMessage={loadingMessage}
                />
            );
        case OnboardingStep.AddSpinachToMeetings:
            return (
                <AddToMeetingsStep
                    data={data}
                    // note - this isn't needed since its not click-to-load
                    updatingEvents={[]}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedAddSpinachToMeetingsStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    onOptForManual={() => {
                        track(ClientEventType.UserOptedForManualAddSpinachToMeetingsStep);
                        progressTo(OnboardingStep.ManualInvite);
                    }}
                    isFetchingEvents={isFetching}
                    onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                    onEventClick={(event: CalendarEvent) => {
                        const isOrganizer = user.isUserTheOrganizer(event);
                        const isScribeOn = isScribeOnEvent(event);
                        if (isScribeOn) {
                            return;
                        }
                        track(ClientEventType.CalendarMeetingItemClick, {
                            ICalUid: event.iCalUID,
                            Action: !!onboardingEventsToAddScribeTo.find((e) => e.iCalUID === event.iCalUID)
                                ? 'remove'
                                : 'add',
                            MeetingTitle: event.summary,
                            IsCurrentUserTheOrganizer: isOrganizer,
                            IsOnboardingFlow: true,
                            AttendeeCount: event.attendees?.length,
                            IsSuggestion: event.iCalUID && data ? data.isICalUidSuggested(event.iCalUID) : false,
                            ChosenScribeEmail: scribeEmail,
                            CalendarProvider: user.calendarProvider,
                        });
                        if (!event.iCalUID) {
                            return;
                        }
                        if (onboardingEventsToAddScribeTo.includes(event)) {
                            setOnboardingEventsToAddScribeTo(
                                onboardingEventsToAddScribeTo.filter(
                                    (eventToAdd) => eventToAdd.iCalUID !== event.iCalUID
                                )
                            );
                        } else {
                            setOnboardingEventsToAddScribeTo([...onboardingEventsToAddScribeTo, event]);
                        }
                    }}
                    hasError={!!error || !!bulkError}
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedAddSpinachToMeetingStep, {
                            NumberOfEventsScribeWasAddedTo: onboardingEventsToAddScribeTo.length,
                            TotalEventsAvailable: data?.events.length,
                            DidSuggestionsTimeOut: data?.timedOut,
                            TotalSuggestedMeetingsAdded: onboardingEventsToAddScribeTo.filter(
                                (event) => !!event.iCalUID && data?.suggestionIds.includes(event.iCalUID)
                            ).length,
                            ...(data?.getAcceptedSuggestionsByMeetingType(onboardingEventsToAddScribeTo) ?? {}),
                            ChosenScribeEmail: scribeEmail,
                        });

                        setLoadingMessage('Saving...');
                        await bulkUpdateAddScribeToEvents(
                            onboardingEventsToAddScribeTo.map((event) => ({
                                iCalUid: event.iCalUID!,
                                addToEvent: true,
                            }))
                        );

                        if (!!onboardingEventsToAddScribeTo.length) {
                            if (user.metadata.usedGoogleCodeFlow) {
                                TagManager.trackGoogleCodeScribeUserAddedSpinach(user);
                            } else if (
                                user.metadata.creationSource === UserCreationSource.MicrosoftSignInFromCompanyWebsite
                            ) {
                                TagManager.trackMicrosoftCompanyWebsiteScribeUserAddedSpinach(user);
                            }
                        }

                        setLoadingMessage('');
                        setOnboardingEventsToAddScribeTo([]);

                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromAddSpinachToMeetingsStep);
                        goBack();
                    }}
                />
            );
        case OnboardingStep.CalendarPermissions:
            return (
                <CalendarPermissionsStep
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedCalendarPermissionsStep);
                        if (user.isAuthedForGoogleCalendar) {
                            progressTo(OnboardingStep.AddSpinachToMeetings);
                        } else if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromCalendarPermissionsStep);
                        goBack();
                    }}
                    onOptForManual={() => {
                        track(ClientEventType.UserOptedForManualCalendarSetup);
                        progressTo(OnboardingStep.ManualInvite);
                    }}
                    onProgressToAddEventToMeeting={(updatedUser) => {
                        if (updatedUser.isAuthedForGoogleCalendar) {
                            track(ClientEventType.UserAutoProgressedCalendarPermissionsStep);
                            progressTo(OnboardingStep.AddSpinachToMeetings, true);
                        }
                    }}
                />
            );
        case OnboardingStep.ManualInvite:
            return (
                <ManualInvite
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedManualSpinachSetupStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromManualSpinachSetupStep);
                        goBack();
                    }}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedManualSpinachSetupStep);
                        if (user.isAuthedForSlack) {
                            progressTo(OnboardingStep.SlackDefaults);
                        } else {
                            progressTo(OnboardingStep.Slack);
                        }
                    }}
                />
            );
        case OnboardingStep.Slack:
            return (
                <SlackSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectSlackStep);
                        goBack();
                    }}
                    direction={direction}
                    onSkip={(reason: string) => {
                        track(ClientEventType.UserSkippedConnectSlackStep, {
                            Reason: reason,
                        });
                        progressTo(OnboardingStep.Tickets);
                    }}
                    progressToSlackDefaults={() => {
                        track(ClientEventType.UserAutoProgressedConnectSlackStep);
                        progressTo(OnboardingStep.SlackDefaults, true);
                    }}
                />
            );
        case OnboardingStep.SlackDefaults:
            return (
                <SlackDefaults
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromSlackDefaultsStep);
                        goBack();
                    }}
                    selectedChannel={selectedChannel}
                    setSelectedChannel={setSelectedChannel}
                    direction={direction}
                    onSkip={() => {
                        track(ClientEventType.UserSkippedSlackDefaultsStep);
                        progressTo(OnboardingStep.Tickets);
                    }}
                    onSubmit={async () => {
                        if (!selectedChannel) {
                            return;
                        }

                        track(ClientEventType.UserSubmittedSlackDefaultsStep);
                        setLoadingMessage('Saving...');
                        const teamId = user.slackSettings?.teamId;
                        const teamType = user.slackSettings?.teamType;

                        if (!teamId || !teamType) {
                            return;
                        }

                        const result = await postSlackDefaultUserChannel(
                            teamId,
                            teamType,
                            selectedChannel.code,
                            selectedChannel.label.replaceAll('#', '')
                        );

                        if (result?.user) {
                            setUser(result.user);
                        }
                        setLoadingMessage('');
                        progressTo(OnboardingStep.Tickets);
                    }}
                />
            );
        case OnboardingStep.Tickets:
            return (
                <TicketSetup
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromConnectTicketsStep);
                        goBack();
                    }}
                    direction={direction}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedConnectTicketsStep);
                        setLoadingMessage('Here we go...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(OnboardingStep.KnowledgeBase);
                    }}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedConnectTicketsStep);
                        setLoadingMessage('Preparing...');

                        await patchUser({ metadata: { isOnboarded: true } });
                        progressTo(OnboardingStep.KnowledgeBase);
                        setLoadingMessage('');
                    }}
                />
            );
        case OnboardingStep.KnowledgeBase:
            return (
                <KnowledgeBaseSetupStep
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromKnowledgeBaseStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        track(ClientEventType.UserSubmittedKnowledgeBaseStep);
                        setLoadingMessage('Saving...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(FINAL_STEP);
                    }}
                    onSkip={async () => {
                        track(ClientEventType.UserSkippedKnowledgeBaseStep);
                        setLoadingMessage('Moving on...');
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');
                        progressTo(FINAL_STEP);
                    }}
                />
            );
        case OnboardingStep.ReverseTrialFinishFlow:
            return (
                <FinishedFlowReverseTrial
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromFinishOnboardingStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        setLoadingMessage('Here we go...');
                        track(ClientEventType.UserClickedGoToDashboardFromFinished);
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');

                        // clears any subviews / settings modals that may have been popped by integration rows
                        setSubview(null);
                        closeSettingsModal();

                        routeToAIDashboard();
                    }}
                />
            );
        case OnboardingStep.Finished:
            return (
                <FinishedFlow
                    loadingMessage={loadingMessage}
                    onBack={() => {
                        track(ClientEventType.UserWentBackFromFinishOnboardingStep);
                        goBack();
                    }}
                    direction={direction}
                    onSubmit={async () => {
                        setLoadingMessage('Here we go...');
                        track(ClientEventType.UserClickedGoToDashboardFromFinished);
                        await patchUser({ metadata: { isOnboarded: true } });
                        setLoadingMessage('');

                        // clears any subviews / settings modals that may have been popped by integration rows
                        setSubview(null);
                        closeSettingsModal();

                        routeToAIDashboard();
                    }}
                />
            );
    }
}
