import { useEffect } from 'react';

import { TagManager } from '../utils/TagManager';
import { useGlobalUser } from './useGlobalUser';

// fun and accurate
export function useTagManagerDestruction(): void {
    const [user] = useGlobalUser();
    useEffect(() => {
        if (user?.metadata.isOnboarded) {
            setTimeout(() => {
                TagManager.destroy();
            }, 5000);
        }
    }, []);
}

export function useTagManager(): void {
    useEffect(() => {
        TagManager.init();
    }, []);
}
