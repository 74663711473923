import { CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';

import { ClientEventType } from '@spinach-shared/types';

import { getUser, patchAllSeriesSettings, patchUser } from '../../../../apis';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    usePaymentUrl,
    useProFeatures,
} from '../../../../hooks';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, BodySmallOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { Label } from './common';

export function ModifySummarySection(): JSX.Element {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const hasProFeatures = useProFeatures();
    const paymentUrl = usePaymentUrl();

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Row>
                <HeaderThree>Modify Summaries</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Make changes to the summary</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Column style={{}}>
                <Row vCenter>
                    <EditIcon
                        htmlColor={lightTheme.primary.greenLight}
                        style={{ fontSize: '25px', marginRight: '15px' }}
                    />
                    <Label style={{ width: 'unset' }}>Edit Summaries</Label>
                </Row>
                <BodySmallOnboard>
                    {hasProFeatures ? (
                        <Row>
                            {isLoading ? (
                                <span
                                    style={{
                                        display: 'flex',
                                        width: '40px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <CircularProgress
                                        size={'16px'}
                                        thickness={6}
                                        style={{
                                            color: lightTheme.primary.greenLight,
                                        }}
                                    />
                                </span>
                            ) : (
                                <span style={{ width: '40px' }} />
                            )}
                            <Column>
                                <Row vCenter>
                                    <SpinachSwitch
                                        checked={
                                            user.metadata.isEditingAiEmailsByDefault ||
                                            (isLoading && !user.metadata.isEditingAiEmailsByDefault)
                                        }
                                        disabled={isLoading}
                                        onChange={async () => {
                                            setIsLoading(true);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Toggle Enable Edit Summary (User Settings)',
                                                ToggleTo: !!user.metadata.isEditingAiEmailsByDefault ? 'Off' : 'On',
                                            });

                                            await Promise.all([
                                                patchAllSeriesSettings({
                                                    isHostEditing: !user.metadata.isEditingAiEmailsByDefault,
                                                }),
                                                patchUser({
                                                    metadata: {
                                                        isEditingAiEmailsByDefault:
                                                            !user.metadata.isEditingAiEmailsByDefault,
                                                    },
                                                }),
                                            ]);

                                            const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                            if (updatedUser.user) {
                                                setUser(updatedUser.user);
                                            }

                                            setToastText(
                                                'This has been applied to meetings that you have invited Spinach to.'
                                            );

                                            setIsLoading(false);
                                        }}
                                    />
                                    <BodyRegularOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                                        Let me edit summaries before they publish.
                                    </BodyRegularOnboard>
                                </Row>
                            </Column>
                        </Row>
                    ) : (
                        <Row>
                            <span style={{ width: '45px' }} />
                            <Column>
                                <BodyRegularOnboard>Summaries are immediately sent out.</BodyRegularOnboard>
                                <BodyRegularOnboard>
                                    {' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Click Upgrade Edit Summary (Settings Section)`,
                                            });
                                            URLUtil.openURL(paymentUrl, {
                                                redirectThroughBackendWhenOnZoom: true,
                                            });
                                        }}
                                    >
                                        Upgrade to Pro
                                    </Anchor>{' '}
                                    to edit beforehand.
                                </BodyRegularOnboard>
                            </Column>
                        </Row>
                    )}
                </BodySmallOnboard>
            </Column>
        </>
    );
}
