import { FacilitatedStartStop, ISOString, Participation, StartStop } from '@spinach-shared/types';

export class StartStopProps {
    segments: StartStop[];

    constructor(segments: StartStop[]) {
        this.segments = segments;
    }

    get inProgress(): StartStop | undefined {
        return this.segments.find((s) => s.endedAt === undefined);
    }

    get areAllFinished(): boolean {
        return this.segments.every((part) => part.endedAt !== undefined);
    }

    get latest(): Participation {
        return this.segments[this.segments.length - 1];
    }

    toJSON(): Participation[] {
        return this.segments.map((segment) => ({ startedAt: segment.startedAt, endedAt: segment.endedAt }));
    }
}

export class FacilitatedStartStops {
    segments: FacilitatedStartStop[];

    constructor(segments: FacilitatedStartStop[]) {
        this.segments = segments;
    }

    inProgress(): FacilitatedStartStop | undefined {
        return this.segments.find((s) => s.endedAt === undefined);
    }

    areAllFinished(): boolean {
        return this.segments.every((part) => part.endedAt !== undefined);
    }

    latest(): Participation {
        return this.segments[this.segments.length - 1];
    }

    startNewSegment(now: ISOString) {
        this.segments.push({ startedAt: now });
    }

    get totalTime(): number {
        return this.segments.reduce((acc, curr) => {
            if (curr.endedAt) {
                return acc + (new Date(curr.endedAt).getTime() - new Date(curr.startedAt).getTime()) / 1000;
            } else {
                return acc;
            }
        }, 0);
    }

    finishLatest(endedAt: ISOString) {
        const latest = this.inProgress();

        if (latest) {
            this.segments[this.segments.length - 1] = {
                ...this.segments[this.segments.length - 1],
                endedAt,
            };
        } else {
            console.warn('Attempted to finish a StartStop but none were in progress.');
        }
    }

    toJSON() {
        return this.segments;
    }
}
