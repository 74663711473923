import styled from 'styled-components';

export type LoginButtonProps = {
    onClick: (e: any) => void;
    icon: React.ReactNode;
    text: string;
    disabled?: boolean;
};

const Button = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 240px;
    height: 60px;
    gap: 15px;
    padding: 16px 22px;
    border: 1px solid #bcbcc7;
    background-color: #ffffff;
    &:hover {
        background-color: #f9f9f9;
    }
    &:disabled {
        cursor: default;
        background-color: #dfe5e5;
        color: #bcbcc7;
    }
    color: var(--variant-1-midnight, #3e3e49);
    font-size: 16px;
`;

export function LoginButton(props: LoginButtonProps) {
    return (
        <Button onClick={props.onClick} disabled={!!props.disabled}>
            {props.icon}
            {props.text}
        </Button>
    );
}
