import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { fetchSlackChannels } from '../apis/fetchSlackChannels';
import { GlobalSlackState, atomSlack } from '../atoms';
import { SetValue } from '../types';
import { ClientLogger } from '../utils';
import { useGlobalAuthedUser } from './useGlobalUser';
import { useGlobalNullableStoredSeries } from './useSeriesData';

export type GlobalSlackStateHook = {
    setInstallURL: (url: string) => void;
    slackState: GlobalSlackState;
    setSlackState: SetValue<GlobalSlackState>;
    setShouldSendSummaryOnChannelSelect: (val: boolean) => void;
    setSlackChannelOptions: (options: { code: string; label: string }[]) => void;
};

export function useGlobalSlack(): GlobalSlackStateHook {
    const [slackState, setSlackState] = useRecoilState(atomSlack);

    function setInstallURL(installUrl: string) {
        setSlackState({
            ...slackState,
            installUrl,
        });
    }

    function setShouldSendSummaryOnChannelSelect(val: boolean) {
        setSlackState({
            ...slackState,
            shouldSendSummaryOnChannelConnect: val,
        });
    }

    function setSlackChannelOptions(slackChannels: { code: string; label: string }[]) {
        setSlackState({
            ...slackState,
            slackChannels,
        });
    }

    return {
        setInstallURL,
        slackState,
        setSlackState,
        setShouldSendSummaryOnChannelSelect,
        setSlackChannelOptions,
    };
}

export function useSlackInstallLink(): string {
    const [user] = useGlobalAuthedUser();
    const { setInstallURL, slackState } = useGlobalSlack();

    useEffect(() => {
        fetch(
            `${process.env.REACT_APP_SLACK_SERVER_URL}/install?href=true&sid=${user.spinachUserId}&version=${process.env.REACT_APP_APP_VERSION}`
        )
            .then((response) =>
                response.text().then((url) => {
                    // if slack is down, do not set the error response as the url
                    if (url?.includes('http')) {
                        setInstallURL(url);
                    }
                })
            )
            .catch((error) => {
                ClientLogger.error('Failed to load slack install link', error);
            });
    }, [user.spinachUserId]);

    return slackState.installUrl;
}

export function useSlackChannels(): { isLoading: boolean; slackChannels: { code: string; label: string }[] } {
    const [user] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const { setSlackChannelOptions, slackState } = useGlobalSlack();

    useEffect(() => {
        async function fetchChannels() {
            if (!user.isAuthedForSlack) {
                return;
            }

            const teamId = user.slackSettings?.teamId;
            const teamType = user.slackSettings?.teamType;

            setIsLoading(true);

            const channels = await fetchSlackChannels(teamId, teamType, user.spinachUserId);
            setSlackChannelOptions(
                channels.map((c) => ({
                    code: c.id,
                    label: `#${c.name}`,
                }))
            );
            setIsLoading(false);
        }
        fetchChannels();
    }, [user.isAuthedForSlack]);

    return {
        isLoading,
        slackChannels: slackState.slackChannels,
    };
}
