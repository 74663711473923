import { useEffect, useState } from 'react';

import { MILLIS_IN_SECONDS, SECONDS_IN_MINUTES } from '@spinach-shared/constants';
import { ClientUser } from '@spinach-shared/models';

import { getUser } from '../apis';
import { useGlobalAuthedUser } from './useGlobalUser';

let intervalTimeout: NodeJS.Timeout;
let killswitchTimeout: NodeJS.Timeout;

export function useIntegrationDetection(
    onSuccess?: ((updatedUser: ClientUser) => void) | ((updatedUser: ClientUser) => Promise<void>)
) {
    const [user, setUser] = useGlobalAuthedUser();
    const [shouldStartFetching, setShouldStartFetching] = useState(false);

    function cleanup() {
        setShouldStartFetching(false);

        if (intervalTimeout) {
            clearTimeout(intervalTimeout);
        }
        if (killswitchTimeout) {
            clearTimeout(killswitchTimeout);
        }
    }

    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    useEffect(() => {
        if (shouldStartFetching) {
            /**
             * Begin loop that fetches user every 2 seconds for 3 minutes
             * if the integrations have been updated, we set them into state
             */
            const EVERY_2_SECONDS = 2 * MILLIS_IN_SECONDS;
            intervalTimeout = setInterval(async () => {
                const userResponse = await getUser();
                if (userResponse.user) {
                    const integrationsHaveUpdated = user.areIntegrationsUpdated(userResponse.user.integrationSettings);
                    if (integrationsHaveUpdated) {
                        setUser(userResponse.user);
                        cleanup();
                        if (onSuccess) {
                            onSuccess(new ClientUser(userResponse.user));
                        }
                    }
                }
            }, EVERY_2_SECONDS);

            /**
             * end interval after 3 minutes
             */
            const AFTER_3_MINUTES = 3 * MILLIS_IN_SECONDS * SECONDS_IN_MINUTES;
            killswitchTimeout = setTimeout(() => {
                cleanup();
            }, AFTER_3_MINUTES);
        }
    }, [shouldStartFetching]);

    return () => {
        setShouldStartFetching(true);
    };
}
