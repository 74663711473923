import { ClientUser } from '@spinach-shared/models';
import { getStage, isLocalStage, isProductionStage } from '@spinach-shared/utils';

import { isWebPlatform } from './platform';

export class TagManager {
    static isInitialized = false;

    static init() {
        if (isWebPlatform() && !isLocalStage()) {
            (function (w, d, s, l, i) {
                // @ts-ignore
                w[l] = w[l] || [];
                // @ts-ignore
                w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                // @ts-ignore
                j.async = true;
                // @ts-ignore
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                // @ts-ignore
                f.parentNode.insertBefore(j, f);

                TagManager.isInitialized = true;
            })(window, document, 'script', 'dataLayer', 'GTM-NCRJHZV');
        }
    }

    static trackSignUp(user: ClientUser) {
        /** @NOTE - maintaining `sign_up` without prefix as that's what it's been before stage-based suffices */
        const eventName = isProductionStage() ? 'sign_up' : `sign_up${this.getStageSuffix()}`;
        this.track(eventName, user.email, user.spinachUserId);
    }

    static trackGoogleCodeScribeSignup(user: ClientUser) {
        this.track(`sign_up_with_site_google${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackCompanySiteSignup(user);
    }

    static trackMicrosoftSiteSignup(user: ClientUser) {
        this.track(`sign_up_with_site_microsoft${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackCompanySiteSignup(user);
    }

    static trackCompanySiteSignup(user: ClientUser) {
        this.track(`sign_up_with_company_site${this.getStageSuffix()}`, user.email, user.spinachUserId);
    }

    static trackMicrosoftWebAppSignup(user: ClientUser) {
        this.track(`sign_up_with_webapp_microsoft${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackWebAppScribeSignup(user);
    }

    static trackGoogleWebAppScribeSignup(user: ClientUser) {
        this.track(`sign_up_ai_with_webapp_google${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackWebAppScribeSignup(user);
    }

    static trackWebAppScribeSignup(user: ClientUser) {
        this.track(`sign_up_ai_with_webapp${this.getStageSuffix()}`, user.email, user.spinachUserId);
    }

    static trackGoogleCodeScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_google_user_added_meeting${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackCompanyWebsiteScribeUserAddedSpinach(user);
    }

    static trackMicrosoftCompanyWebsiteScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_microsoft_user_added_meeting${this.getStageSuffix()}`, user.email, user.spinachUserId);
        this.trackCompanyWebsiteScribeUserAddedSpinach(user);
    }

    static trackCompanyWebsiteScribeUserAddedSpinach(user: ClientUser) {
        this.track(`site_user_added_meeting${this.getStageSuffix()}`, user.email, user.spinachUserId);
    }

    static destroy() {
        if (!isWebPlatform()) {
            return;
        }

        try {
            this.isInitialized = false;

            const scripts = document.getElementsByTagName('script');

            Array.from(scripts).forEach((script) => {
                if (
                    script.src.includes('googletag') ||
                    script.src.includes('snap.licdn') ||
                    script.textContent?.includes('facebook') ||
                    script.textContent?.includes('clearbitjs')
                ) {
                    document.body.removeChild(script);
                }
            });

            const noscripts = document.getElementsByTagName('noscript');

            Array.from(noscripts).forEach((noscript) => {
                if (noscript.textContent?.includes('facebook')) {
                    document.body.removeChild(noscript);
                }
            });
        } catch (error) {}
    }

    /** @NOTE - using the STAGE in the event name itself as it's easier for the users of GA to use, rather than metadata */
    private static getStageSuffix(): string {
        /** @NOTE - event names can only be so long, ensuring production is prod */
        const suffix = getStage().substring(0, 4);
        return `_${suffix}`;
    }

    private static track(eventName: string, email: string, userId: string) {
        const shouldTrack = this.shouldTrack();

        if (!shouldTrack) {
            return;
        }

        try {
            // @ts-ignore
            window.dataLayer.push({
                event: eventName,
                // all tracking with tagmanager thus far has been related to onboarding on UI
                step: 'welcome',
                // this is the only metadata requested for GA thus far
                email,
                user_id: userId,
            });
        } catch (err) {}
    }

    private static shouldTrack(): boolean {
        return this.isInitialized && isWebPlatform();
    }
}
