import { useEffect } from 'react';

import { SpinachSeriesProps } from '@spinach-shared/models';
import { ClientSocketEvent, UserSeriesMetadata } from '@spinach-shared/types';

import { getUser } from '../../apis';
import { DemoModal, GlobalModal, atomMeetingSocket } from '../../atoms';
import {
    SocketStatus,
    useAsanaIssues,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalModal,
    useGlobalNullableLiveSeries,
    useJiraIssues,
    useLiveHistory,
    useLiveSeries,
    useLoadingState,
    useNotionPages,
    usePersonaDemo,
    useSeriesHistories,
    useSeriesReality,
    useWebsocket,
} from '../../hooks';
import { SetValue } from '../../types';
import { getParticipantJoiningRequest } from '../../utils';
import { DemoVideo } from '../demo';
import { BottomCornerGuideContainer } from '../demo/DemoGuides';
import { DemoModalContainer } from '../demo/DemoModals';
import { DemoPrefaceViewsContainer } from '../demo/DemoPrefaceViews';
import { SpinachStandUp } from '../stand-up';
import { SimpleBackdrop } from './Backdrop';
import { FYI, FYIState } from './FYI';
import { InMeetingContainer } from './InMeetingContainer';

export function SpinachMeeting({ seriesMetadata }: { seriesMetadata: UserSeriesMetadata }): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const request = getParticipantJoiningRequest(user, seriesMetadata);
    const eventMeta = {
        event: ClientSocketEvent.ParticipantJoining,
        payload: request,
        meetingId: liveSeries?.currentMeeting?.id,
    };
    const { socket, socketStatus, resetSocketConnection } = useWebsocket(atomMeetingSocket, eventMeta);

    // fetch user on SpinachMeeting mount, does not block loading, just ensures latest feature toggles and data
    useEffect(() => {
        async function fetch() {
            const userResponse = await getUser();
            if (userResponse.user) {
                setUser(userResponse.user);
            }
        }
        fetch();
    }, []);

    const [series, setLiveSeries] = useLiveSeries(socket, user);
    const [history] = useLiveHistory(socket);
    const [seriesHistories] = useSeriesHistories(series);
    const isLoading = useLoadingState(Boolean(series && socket && seriesHistories !== null && history !== null));
    useJiraIssues(isLoading);
    useAsanaIssues(isLoading);
    useNotionPages(isLoading);

    const { isDemoSeries } = useSeriesReality();
    const isPersonaDemo = usePersonaDemo();
    const { demoState } = useGlobalDemoState();
    useClearGlobalSeriesDataOnUnmount(setLiveSeries);
    useInitialDemoStateBasedOnUserData();

    const shouldBlockDemoWithSignup = useDemoBlocking();
    if (shouldBlockDemoWithSignup) {
        return <></>;
    }

    if (isLoading) {
        const isReconnectingOrIdling = socketStatus === SocketStatus.Reconnecting || socketStatus === SocketStatus.Idle;
        const header = isDemoSeries ? 'Ready for faster, more-focused standups?' : 'Creating some space & kindness';
        return (
            <>
                {isReconnectingOrIdling ? null : <FYI header={header} state={FYIState.Loading} />}
                <SimpleBackdrop status={socketStatus} resetSocketConnection={resetSocketConnection} />
            </>
        );
    }

    if (series?.spinachParticipant.shouldRefreshApp) {
        return <FYI state={FYIState.Refresh} />;
    }

    if (isPersonaDemo && demoState.demoPrefaceView) {
        return <DemoPrefaceViewsContainer />;
    }

    return (
        <div className="App">
            <InMeetingContainer>
                <DemoVideo />

                <SpinachStandUp
                    series={series!}
                    seriesHistories={seriesHistories!}
                    socket={socket!}
                    history={history!}
                />

                <DemoModalContainer />
                <BottomCornerGuideContainer />
            </InMeetingContainer>
            <SimpleBackdrop status={socketStatus} resetSocketConnection={resetSocketConnection} />
        </div>
    );
}

function useClearGlobalSeriesDataOnUnmount(setLiveSeries: SetValue<SpinachSeriesProps | null>) {
    useEffect(() => {
        return () => {
            setLiveSeries(null);
        };
    }, []);
}

function useInitialDemoStateBasedOnUserData() {
    const isPersonaDemo = usePersonaDemo();
    const { setDemoState, demoState, demoModal } = useGlobalDemoState();
    useEffect(() => {
        if (isPersonaDemo && demoModal !== DemoModal.EnteringPersonaDemoFromOnboarding) {
            setDemoState({
                ...demoState,
                demoModal: null,
            });
        } else if (!isPersonaDemo) {
            setDemoState({
                ...demoState,
                demoPrefaceView: null,
                spinachWindowOverlay: null,
            });
        }
    }, []);
}

function useDemoBlocking(): boolean {
    const { setDemoModal } = useGlobalDemoState();
    const [, setGlobalModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();
    const { isDemoSeries } = useSeriesReality();

    const shouldBlockDemoWithSignup = user.shouldAuthBeforeDemo && !user.email && isDemoSeries;
    useEffect(() => {
        if (shouldBlockDemoWithSignup) {
            setDemoModal(null);
            setGlobalModal(GlobalModal.VerifyEmail);
        }
    }, []);

    return shouldBlockDemoWithSignup;
}
