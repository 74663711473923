import { CSSProperties, ReactNode } from 'react';

import { StoredSpinachSeries } from '@spinach-shared/utils';

export enum AiModalKind {
    RemoveSlack = 'remove-slack',
    RemoveGoogleCalendar = 'remove-google-calendar',
    RemoveJira = 'remove-jira',
    RemoveAsana = 'remove-asana',
    RemoveLinear = 'remove-linear',
    RemoveClickUp = 'remove-clickup',
    RemoveTrello = 'remove-trello',
    RemoveConfluence = 'remove-confluence',
    RemoveNotion = 'remove-notion',
    RemoveGoogleDrive = 'remove-google-drive',

    // RemoveSpinachFromSeries = 'remove-spinach-from-series',
}

export type ConfirmAiModalProps = {
    modalKind: AiModalKind | null;
    setModalKind: (modalKind: AiModalKind | null) => void;
};

export type UseAiModalProps = {
    header: ReactNode;
    body: ReactNode;
    isLoading: boolean;
    onConfirm: () => Promise<void>;
    onClose: (() => Promise<void>) | (() => void);
};

export enum DashboardSection {
    Meetings = 'meetings',
    Integrations = 'integrations',
    Transcripts = 'transcripts',
    Summaries = 'summaries',
    Actions = 'actions',
}
export type DashboardComponent = {
    activeSection: DashboardSection;
    setActiveSection: (section: DashboardSection) => void;
};

export type ActiveSectionComponent = DashboardComponent & {
    storedSeriesList: StoredSpinachSeries[];
    setModalKind: (modalKind: AiModalKind | null) => void;
};

export type UseHoverRowProps = {
    hoveredRow: null | string;
    setHoveredRow: (value: null | string) => void;
    getHoverableRowProps: (rowId: string) => {
        onMouseEnter: () => void;
        onMouseLeave: () => void;
        style: CSSProperties;
    };
    getVisibleRemoveProps: (rowId: string) => {
        isVisible: boolean;
    };
};
