import { CalendarEvent } from '@spinach-shared/types';

export function getCalUidPrefix(iCalUid: string) {
    let iCalUidPrefix = iCalUid;
    if (iCalUidPrefix.includes('@google.com')) {
        if (iCalUidPrefix.includes('_R20')) {
            iCalUidPrefix = iCalUidPrefix.split('_R20')[0];
        } else {
            iCalUidPrefix = iCalUidPrefix.split('@google.com')[0];
        }
    }
    return iCalUidPrefix;
}

export function getUniqueEvents(events: CalendarEvent[]): CalendarEvent[] {
    const uniqueEvents: CalendarEvent[] = [];
    const recurringEventIdSet = new Set();

    // google events have the same iCalUID for recurring events
    events.forEach((event) => {
        if (event.recurringEventId && recurringEventIdSet.has(event.recurringEventId)) {
            return;
        }

        uniqueEvents.push(event);
        if (event.recurringEventId) {
            recurringEventIdSet.add(event.recurringEventId);
        }
    });

    return uniqueEvents;
}

export function getCalendarRecurringId(event: CalendarEvent): string {
    if (event.iCalUID?.includes('@google')) {
        return event.iCalUID;
    } else if (event.recurringEventId) {
        return event.recurringEventId;
    } else {
        return event.id!;
    }
}
