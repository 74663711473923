import { useState } from 'react';

import { useGlobalAuthedUser, useWindowSize } from '../../../../hooks';
import { HeaderTwo } from '../../../../styles';
import { Hairline, Row, Spacing } from '../../../common';
import { ScrollArea } from '../../../stand-up';
import { AiModalKind, UseHoverRowProps } from '../types';
import { CalendarIntegrationSection } from './CalendarIntegrationSection';
import { CommunicationsIntegrationSection } from './CommunicationsIntegrationSection';
import { KnowledgeBaseIntegrationSection } from './KnowledgeBaseIntegrationSection';
import { ModifySummarySection } from './ModifySummarySection';
import { TicketingIntegrationSection } from './TicketingIntegrationSection';

function useHoverRow(): UseHoverRowProps {
    const [hoveredRow, setHoveredRow] = useState<null | string>(null);

    function getHoverableRowProps(rowId: string) {
        return {
            onMouseEnter: () => {
                setHoveredRow(rowId);
            },
            onMouseLeave: () => {
                setHoveredRow(null);
            },
            style: {
                minHeight: '30px',
            },
        };
    }

    function getVisibleRemoveProps(rowId: string) {
        return {
            isVisible: hoveredRow === rowId,
        };
    }

    return {
        hoveredRow,
        setHoveredRow,
        getHoverableRowProps,
        getVisibleRemoveProps,
    };
}

export function IntegrationsSection({ setModalKind }: { setModalKind: (modalKind: AiModalKind) => void }): JSX.Element {
    const hoverRowProps = useHoverRow();
    const { width } = useWindowSize();
    const [user] = useGlobalAuthedUser();

    // currently, we only show the calendar section to users who have signed in with google
    // this is so we don't allow users to sign in with one email and connect a calendar with another
    // for microsoft, we don't need to show this since the user can either grant all permissions or none
    const showCalendarSection = !!user.googleId;
    const isDesktopView = width > 800;

    return (
        <>
            <Spacing factor={2} />

            {isDesktopView ? (
                <Row>
                    <HeaderTwo>Settings</HeaderTwo>
                </Row>
            ) : null}

            <Spacing />

            <ScrollArea sidePadding={0} style={{ width: '100%' }}>
                <ModifySummarySection />
                <Hairline />
                <Spacing factor={1 / 2} />

                <CommunicationsIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                <Hairline />
                <Spacing factor={1 / 2} />

                {showCalendarSection ? (
                    <>
                        <CalendarIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                        <Hairline />
                        <Spacing factor={1 / 2} />
                    </>
                ) : null}

                <TicketingIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                <Hairline />
                <Spacing factor={1 / 2} />

                <KnowledgeBaseIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                <Spacing />
            </ScrollArea>
            <Spacing />
        </>
    );
}
