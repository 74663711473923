// TODO - move zod schemas elsewhere
import { z } from 'zod';

import { MeetingHeySpinachResult } from '@spinach-backend/types';

import { DetailedTicket, MappedIssueChangeLogHistoryItem, TrimmedTicket } from './Jira';

export type UserNameWithEmailConfidenceMap = Record<string, { email: string; emailConfidence: number }>;

export type MeetingBlocker = {
    user: string;
    content: string;
    context: string;
    kind?: string;
};

export type MeetingAgendaItem = {
    content: string;
    context: string;
    amount?: string;
};

export type MeetingCelebration = {
    user: string;
    content: string;
};

export type MeetingKeyDecision = {
    content: string;
    context: string;
};

export type MeetingActionItem = {
    title: string;
    context: string;
    id?: string;
    ticketData?: Pick<DetailedTicket, 'id' | 'ticketUrl' | 'ticketSource' | 'title' | 'displayName'>;
    users: { email?: string; username: string }[];
};

export type UserPlansAndProgress = {
    id: string;
    userName: string;
    email: string;
    plans: string;
    progress: string;
};

export type SummaryJson = {
    attendees: string[];
    blockers?: MeetingBlocker[];
    celebrations?: MeetingCelebration[];
    keyDecisions?: MeetingKeyDecision[];
    actionItems?: MeetingActionItem[];
    agendaItems?: MeetingAgendaItem[];
    plansAndProgress?: UserPlansAndProgress[];
    whatWentWellItems?: MeetingWhatWentWellItem[];
    whatDidNotGoWellItems?: MeetingWhatDidNotGoWellItem[];
    potentialImprovementItems?: MeetingPotentialImprovementItem[];
    tickets?: DetailedTicket[];
    heySpinach?: MeetingHeySpinachResult[];
};

export type BotIdPair = {
    botId: string;
    previousBotId?: string;
};

export type SummaryAnalyticsMetadata = Record<`NumberOf${Capitalize<keyof SummaryJson>}`, number | undefined>;

export type MeetingWhatWentWellItem = {
    content: string;
    context: string;
    user: string;
};

export type MeetingWhatDidNotGoWellItem = {
    content: string;
    context: string;
    user: string;
};

export type MeetingPotentialImprovementItem = {
    content: string;
    context: string;
    user: string;
};

export type WorkstreamOverviewEntry = {
    title: string;
    subtopics: string;
    decisions: string;
    summary: string;
    id?: string;
};

export type MeetingWorkstream = {
    title: string;
    progress: string;
    decisions: string;
    description: string;
    id?: string;
};

export type WorkstreamsFromMeeting = {
    workstreams: MeetingWorkstream[];
};

export type WeeklyWorkstreamOverview = {
    workstreams: WorkstreamOverviewEntry[];
};

const SpinachInsightAction = z.object({
    // Define this schema
});

export enum SpinachInsightKind {
    Gap = 'gap',
    Blocker = 'blocker',
    HeySpinach = 'hey-spinach',
    TimeCheck = 'time-check',
    MeetingTip = 'meeting-tip',
}

export const SpinachInsightSchema = z.object({
    id: z.string(),
    title: z.string(),
    context: z.string(),
    kind: z.string(),
    actions: z.array(SpinachInsightAction),
});

const StartStopSchema = z.object({
    startedAt: z.string(),
    endedAt: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
});

const DiscussionTopicSchema = z.object({
    viewId: z.string(),
    title: z.string(),
    notes: z.array(z.string()),
    startStops: z.array(StartStopSchema),
});

const RoundtableWrapUpSchema = z.object({
    viewId: z.string(),
    startStops: z.array(StartStopSchema),
    insights: z.array(SpinachInsightSchema),
});

export enum ParticipantStatus {
    Online = 'online',
    Offline = 'offline',
}

export const FacilitatedParticipantSchema = z.object({
    viewId: z.string(),
    userId: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    email: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    displayName: z.string(),
    preferedName: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    startStops: z.array(StartStopSchema),
    addedUpdates: z.array(
        z.object({
            title: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
            context: z.string(),
            userId: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
        })
    ),
    status: z.nativeEnum(ParticipantStatus), // Replace with the correct type
});

const FacilitatedIcebreakerSchema = z.object({
    viewId: z.string(),
    enabled: z.boolean(),
    currentIndex: z
        .number()
        .nullish()
        .transform((x) => x ?? undefined),
    usedIndexes: z.array(z.number()),
    // ... additional fields for FacilitatedIcebreaker when they become available
});

export const FacilitationEventPersonUpdateCompleteSchema = z.object({
    botId: z.string(),
    previousBotId: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    kind: z.literal('person-update-complete'),
    userDisplayName: z.string(),
    userEmail: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
});

export const FacilitationEventPayloadSchema = z.discriminatedUnion('kind', [
    FacilitationEventPersonUpdateCompleteSchema,
]);

export const FacilitationComputedMetadataSchema = z.object({
    isBotInMeeting: z.boolean(),
});

export const FacilitationSchema = z.object({
    briefingInsights: z.array(SpinachInsightSchema),
    inMeetingInsights: z.array(SpinachInsightSchema),
    discussionTopics: z.array(DiscussionTopicSchema),
    roundtableWrapup: RoundtableWrapUpSchema,
    icebreaker: FacilitatedIcebreakerSchema,
    participants: z.array(FacilitatedParticipantSchema),
    projectedMeetingTime: z.object({
        startTime: z.string(),
        endTime: z.string(),
    }),
    actualMeetingTime: z
        .object({
            startTime: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
            endTime: z
                .string()
                .nullish()
                .transform((x) => x ?? undefined),
        })
        .nullish()
        .transform((x) => x ?? undefined),
    currentViewId: z.string(),
    meetingTitle: z.string(),
});

export const GenerateGapInsightsRequestBodySchema = z.object({
    userDisplayName: z.string(),
    userEmail: z
        .string()
        .nullish()
        .transform((x) => x ?? undefined),
    currentTranscriptForUser: z.string(), // TODO figure out the format of this
    currentBotId: z.string(),
    seriesId: z.string(),
    previousBotId: z.string(),
});

export type TeamTicketingMap = Record<string, TrimmedTicket[]>;

export type UserTicketingMap = {
    changelogHistory: MappedIssueChangeLogHistoryItem[];
    issues: TrimmedTicket[];
};

export type GenerateGapInsightsRequestBody = z.infer<typeof GenerateGapInsightsRequestBodySchema>;
export type Facilitation = z.infer<typeof FacilitationSchema>;
export type SpinachInsight = z.infer<typeof SpinachInsightSchema>;
export type FacilitationComputedMetadata = z.infer<typeof FacilitationComputedMetadataSchema>;
export type FacilitatedParticipant = z.infer<typeof FacilitatedParticipantSchema>;
export type DiscussionTopic = z.infer<typeof DiscussionTopicSchema>;
export type FacilitatedStartStop = z.infer<typeof StartStopSchema>;
export type FacilitationEventPayload = z.infer<typeof FacilitationEventPayloadSchema>;
export type FacilitationEventPersonUpdateComplete = z.infer<typeof FacilitationEventPersonUpdateCompleteSchema>;
export type RoundtableWrapup = z.infer<typeof RoundtableWrapUpSchema>;
export type FacilitatedIcebreaker = z.infer<typeof FacilitatedIcebreakerSchema>;
