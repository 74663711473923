import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ScribeType, SeriesCreationSource } from '@spinach-shared/types';
import { isDemoSeries } from '@spinach-shared/utils';

import { getUser, postSeries } from '../../apis';
import { getStoredSeries } from '../../apis/getStoredSeries';
import { GlobalModal } from '../../atoms';
import {
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useLocationalSeriesId,
    useSeriesReality,
    useTagManagerDestruction,
} from '../../hooks';
import { DEMO_PATH } from '../../utils';
import { FYI, FYIState } from './FYI';
import { SpinachMeeting } from './SpinachMeeting';

function useLoadSeriesOnMount() {
    const [user, setUser] = useGlobalAuthedUser();
    const locationalSeriesId = useLocationalSeriesId();
    const location = useLocation();
    const [, setSeries] = useGlobalNullableStoredSeries();
    const { routeToDashboard } = useGlobalRouting();

    async function loadDemoSeries() {
        const demoSeriesOnAccount = user.seriesMetadataList.find((sm) => isDemoSeries(sm.id));

        if (demoSeriesOnAccount) {
            const storedSeries = await getStoredSeries(demoSeriesOnAccount.id);

            setSeries(storedSeries);
        } else {
            const newDemoSeries = await postSeries({
                createdBy: user.spinachUserId,
                name: 'Practice Round',
                isDemo: true,
                metadata: {
                    creationSource: SeriesCreationSource.LegacyOnboarding,
                },
            });

            const userResponse = await getUser();

            if (userResponse.user) {
                setUser(userResponse.user);
                setSeries(newDemoSeries);
            }
        }
    }

    useEffect(() => {
        async function loadSeries(): Promise<void> {
            if (location.pathname.includes(DEMO_PATH)) {
                await loadDemoSeries();
            } else if (locationalSeriesId) {
                const fetchedSeries = await getStoredSeries(locationalSeriesId);

                if (fetchedSeries) {
                    setSeries(fetchedSeries);
                } else {
                    routeToDashboard({ replace: true });
                }
            }
        }

        loadSeries();
    }, [location.pathname]);
}

function useClearGlobalModal() {
    const location = useLocation();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const { isRealSeries, isDemoSeries } = useSeriesReality();

    useEffect(() => {
        if (isRealSeries) {
            if (globalModal === GlobalModal.ConfirmLeaveDemo) {
                setGlobalModal(null);
            }
        } else if (isDemoSeries) {
            if (globalModal === GlobalModal.ShareSeries) {
                setGlobalModal(null);
            }
        }
    }, [location.pathname]);
}

export function SpinachMeetingValidator(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const { routeToDashboard, routeToAIDashboard } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    const location = useLocation();

    useLoadSeriesOnMount();
    useClearGlobalModal();
    useTagManagerDestruction();

    if (!series) {
        return <FYI state={FYIState.Loading} />;
    }

    const seriesMetadata = user.getSeriesById(series.id);

    if (!seriesMetadata) {
        routeToDashboard({ replace: true });
        return <></>;
    }

    if (series.isScribeSeries) {
        routeToAIDashboard();
        return <></>;
    }

    return (
        <SpinachMeeting
            seriesMetadata={seriesMetadata}
            // key is used to remount spinachMeeting if changes from demo to real series
            // TODO: do we still want this?
            key={location.pathname}
        />
    );
}
