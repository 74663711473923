import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import styled from 'styled-components';

import { FacilitatedViewId } from '@spinach-shared/constants';
import { FacilitatedStartStops } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';
import { formatTime } from '@spinach-shared/utils';

import {
    useFacilitationTracking,
    useGlobalBotIdPair,
    useGlobalFacilitation,
    useGlobalNullableFacilitationComputedMetadata,
    useTimer,
} from '../../../hooks';
import { BodyLarge, BodyRegular } from '../../../styles';
import { withFullStoryMasking } from '../../../utils/withFullStoryMasking';
import { Row, Spacing } from '../../common';
import { SpinachSwitch } from '../../common/SpinachSwitch';
import { PrimaryButton } from '../../stand-up';
import { DiscussionTopics } from './DiscussionTopics';
import {
    Header,
    Padding,
    ScrollView,
    getAgendaIds,
    getPreviousAgendaFacilitation,
    getUpdatedTimesAndCurrentView,
} from './common';

const Chapter = styled(BodyLarge)`
    font-weight: 400;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #f9f9f9;
`;

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        background: '#000000AA',
        fontSize: '15px',
        padding: '10px',
    },
}));

const ErrorIcon = styled(Error)`
    align-self: center;
    margin: 5px;
    color: ${(props) => props.theme.primary.orangeLight};
`;

export function AgendaSidebar(): JSX.Element {
    const track = useFacilitationTracking();
    const [facilitation, setFacilitation] = useGlobalFacilitation();
    const [facilitationComputedMetadata] = useGlobalNullableFacilitationComputedMetadata();
    const [botIdPair] = useGlobalBotIdPair();

    const isOnBriefing = facilitation.currentViewId === FacilitatedViewId.MeetingPrep;
    const isOnIcebreaker = facilitation.currentViewId === FacilitatedViewId.Icebreaker;
    const isOnRoundtableWrapup = facilitation.currentViewId === FacilitatedViewId.GapAnalysis;

    const timerUntilMeetingStarts = useTimer(facilitation.projectedMeetingTime.startTime);
    const timerUntilMeetingEnds = useTimer(facilitation.projectedMeetingTime.endTime);

    const onClickStart = () => {
        if (!facilitationComputedMetadata?.isBotInMeeting || !facilitation.participants.length) {
            return;
        }
        track(ClientEventType.AIFacilitationClick, {
            ClickedOn: 'Start Agenda',
        });

        // TODO-insights - tracking
        const firstAgendaId = getAgendaIds(facilitation).filter((id) => id !== FacilitatedViewId.MeetingPrep)[0];
        const updatedFacilitation = getUpdatedTimesAndCurrentView(facilitation, firstAgendaId, botIdPair, true);

        setFacilitation((facilitation) => ({
            ...facilitation,
            ...updatedFacilitation,
            actualMeetingTime: {
                startTime: new Date().toISOString(),
            },
        }));
    };

    const canStart = facilitationComputedMetadata?.isBotInMeeting && facilitation.participants.length > 0;

    return (
        <Container>
            <Padding>
                <Header>Agenda</Header>
                <ScrollView sidePadding={0} stretch>
                    <Row style={{ justifyContent: 'space-between' }}>
                        {timerUntilMeetingStarts <= 0 && !facilitation.actualMeetingTime?.startTime ? (
                            <>
                                <BodyRegular>Meeting starts in</BodyRegular>
                                <BodyRegular style={{ minWidth: '40px' }}>
                                    {formatTime(Math.abs(timerUntilMeetingStarts))}
                                </BodyRegular>
                            </>
                        ) : (
                            <>
                                <BodyRegular>{timerUntilMeetingEnds > 0 ? 'Overtime' : 'Time remaining'}</BodyRegular>
                                <BodyRegular style={{ minWidth: '40px' }}>
                                    {formatTime(Math.abs(timerUntilMeetingEnds))}
                                </BodyRegular>
                            </>
                        )}
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Tooltip
                        classes={useTooltipStyles()}
                        title={
                            canStart ? (
                                ''
                            ) : (
                                <Box display="flex" flexDirection="row">
                                    <ErrorIcon />
                                    <span>You must be in the meeting (ex. Zoom) with Spinach.io to Start agenda</span>
                                </Box>
                            )
                        }
                        arrow={true}
                        placement="top"
                        style={{ flexDirection: 'column' }}
                    >
                        <Row>
                            <PrimaryButton title="Start agenda" disabled={!canStart} onClick={onClickStart} />
                        </Row>
                    </Tooltip>
                    <Spacing factor={1 / 2} />
                    <Chapter
                        style={{
                            fontWeight: isOnBriefing ? 'bold' : 400,
                            cursor: isOnBriefing ? 'initial' : 'pointer',
                        }}
                        onClick={() => {
                            if (isOnBriefing) {
                                return;
                            }
                            track(ClientEventType.AIFacilitationClick, {
                                ClickedOn: 'Navigate to Meeting Prep',
                                Location: 'Sidebar',
                            });
                            setFacilitation((facilitation) => ({
                                ...facilitation,
                                currentViewId: FacilitatedViewId.MeetingPrep,
                            }));
                        }}
                    >
                        Meeting Prep
                    </Chapter>

                    <Spacing />

                    <Row style={{ justifyContent: 'space-between' }} vCenter={true}>
                        <Chapter
                            style={{
                                fontWeight: isOnIcebreaker ? 'bold' : 400,
                                cursor: isOnIcebreaker ? 'initial' : 'pointer',
                            }}
                            onClick={() => {
                                if (isOnIcebreaker) {
                                    return;
                                }
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Navigate to Icebreaker',
                                    Location: 'Sidebar',
                                });
                                const updatedFacilitation = getUpdatedTimesAndCurrentView(
                                    facilitation,
                                    FacilitatedViewId.Icebreaker,
                                    botIdPair
                                );
                                setFacilitation((facilitation) => ({
                                    ...facilitation,
                                    ...updatedFacilitation,
                                }));
                            }}
                        >
                            Icebreaker
                        </Chapter>
                        <SpinachSwitch
                            scale={0.8}
                            checked={facilitation.icebreaker.enabled}
                            onClick={() => {
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Toggle Icebreaker',
                                    Location: 'Sidebar',
                                    ToggledTo: facilitation.icebreaker.enabled ? 'Off' : 'On',
                                });
                                if (facilitation.currentViewId === FacilitatedViewId.Icebreaker) {
                                    const updatedFacilitation = getPreviousAgendaFacilitation(facilitation, botIdPair);
                                    setFacilitation((facilitation) => ({
                                        ...facilitation,
                                        ...updatedFacilitation,
                                        icebreaker: {
                                            ...facilitation.icebreaker,
                                            enabled: !facilitation.icebreaker.enabled,
                                        },
                                    }));
                                } else {
                                    setFacilitation((facilitation) => ({
                                        ...facilitation,
                                        icebreaker: {
                                            ...facilitation.icebreaker,
                                            enabled: !facilitation.icebreaker.enabled,
                                        },
                                    }));
                                }
                            }}
                        />
                    </Row>

                    <Spacing />

                    <Chapter>Individual Updates</Chapter>
                    {facilitation?.participants.map((participant) => (
                        <Row
                            style={{
                                marginTop: '10px',
                                marginLeft: '10px',
                                fontWeight: facilitation.currentViewId === participant.viewId ? 'bold' : 400,
                                cursor: facilitation.currentViewId === participant.viewId ? 'initial' : 'pointer',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                if (facilitation.currentViewId === participant.viewId) {
                                    return;
                                }
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Navigate to Participant',
                                    Location: 'Sidebar',
                                    ParticipantId: participant.userId,
                                });
                                const updatedFacilitation = getUpdatedTimesAndCurrentView(
                                    facilitation,
                                    participant.viewId,
                                    botIdPair
                                );
                                setFacilitation((facilitation) => ({
                                    ...facilitation,
                                    ...updatedFacilitation,
                                }));
                            }}
                        >
                            <span className={withFullStoryMasking()}>
                                {participant.preferedName ?? participant.displayName}
                            </span>
                            <span style={{ marginRight: '10px', fontSize: '12px' }}>
                                {new FacilitatedStartStops(participant.startStops).totalTime
                                    ? formatTime(new FacilitatedStartStops(participant.startStops).totalTime)
                                    : '-'}
                            </span>
                        </Row>
                    ))}

                    <Spacing />

                    <Row
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            display: 'flex',
                        }}
                        onClick={() => {
                            if (isOnRoundtableWrapup) {
                                return;
                            }
                            track(ClientEventType.AIFacilitationClick, {
                                ClickedOn: 'Navigate to AI Gaps',
                                Location: 'Sidebar',
                            });
                            const updatedFacilitation = getUpdatedTimesAndCurrentView(
                                facilitation,
                                FacilitatedViewId.GapAnalysis,
                                botIdPair
                            );
                            setFacilitation((facilitation) => ({
                                ...facilitation,
                                ...updatedFacilitation,
                            }));
                        }}
                    >
                        <Chapter
                            style={{
                                fontWeight: isOnRoundtableWrapup ? 'bold' : 400,
                                cursor: isOnRoundtableWrapup ? 'initial' : 'pointer',
                            }}
                        >
                            AI Gap Analysis
                        </Chapter>
                        <span style={{ fontSize: '12px' }}>
                            {new FacilitatedStartStops(facilitation.roundtableWrapup.startStops).totalTime
                                ? formatTime(
                                      new FacilitatedStartStops(facilitation.roundtableWrapup.startStops).totalTime
                                  )
                                : '-'}
                        </span>
                    </Row>

                    <Spacing />

                    <Chapter>Discussion Topics</Chapter>
                    <DiscussionTopics />

                    <Spacing />
                </ScrollView>
            </Padding>
        </Container>
    );
}
