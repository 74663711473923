import { atom } from 'recoil';

import { TicketSource } from '@spinach-shared/types';

/**
 * The thought here is that we can control modals from anywhere with global state
 * we can house them in a single place so as to not bloat up other components
 * There should only be a single modal up at a time, so global shouldn't produce
 * too many conflicts. Open to alternatives though.
 *
 * Initial usecase - need to finish demo mode -> route to real lobby -> pop modal there
 * Could use a query param, but ultimately i'd want to remove it. Global state makes this much easier.
 */
export enum GlobalModal {
    ShareSeries = 'share-series',
    SubscribeSeriesOutput = 'subscribe-series-output',
    Schedule = 'schedule',
    ConfirmLeaveDemo = 'confirm-leave-demo',
    VerifyEmail = 'verify-email',
    UserAccount = 'user-account',
    SeriesSettings = 'series-settings',
    ArchivedUserGoals = 'archived-user-goals',
    MeditationPrompt = 'meditation-prompt',
    SpinachReferral = 'spinach-referral',
    IntegrationsSettings = 'integrations-settings',
    InviteViaSlack = 'invite-via-slack',
    ConnectTicketSource = 'connect-ticket-source',
}
export enum GlobalModalFrom {
    StartStandup = 'start-standup',
    InviteSecondUserCallout = 'invite-second-user-callout',
    ActionItemSummaryComponent = 'action-item-summary-component',
}

export type GlobalModalState = {
    modal: GlobalModal | null;
    location?: string;
    from?: GlobalModalFrom;
    metadata?: {
        teamTicketIntegration: TicketSource;
        botId: string | null;
        seriesId: string | null;
    };
};

export const atomModalState = atom<GlobalModalState>({
    key: 'modalState',
    default: {
        modal: null,
    },
});
