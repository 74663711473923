import { useState } from 'react';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import { deleteIntegration, getUser, postNotionUserParentPage } from '../../../apis';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalNotionPages } from '../../../hooks';
import { useGlobalMeetingSettings } from '../../../hooks/useGlobalMeetingSettings';
import { BodyLarge, BodyRegular, ResponsiveModalTitle, lightTheme } from '../../../styles';
import { ListItemValue } from '../../../types';
import { PrimaryButton, ScrollArea } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { DropDown } from '../DropDown';
import { Direction, SlidingColumn } from '../SlidingColumn';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row, Spacing } from '../framing';

export function NotionIntegrationSettingsModal() {
    const { setSubview } = useGlobalMeetingSettings();
    const [direction, setDirection] = useState(Direction.Forward);

    function onBack() {
        setDirection(Direction.Forward);
        setSubview(null);
    }

    return (
        <SpinachModalContent
            onBack={onBack}
            style={{
                maxWidth: '80%',
                padding: '20px',
                minHeight: 'unset',
            }}
        >
            <SlidingColumn centered direction={direction}>
                <NotionIntegrationSettings />
            </SlidingColumn>
        </SpinachModalContent>
    );
}

export function NotionIntegrationSettings({ onClose }: { onClose?: () => void }): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const { setSubview } = useGlobalMeetingSettings();

    const track = useExperienceTracking();
    const [notionPages] = useGlobalNotionPages();

    const [isConfirmedToDelete, setIsConfirmedToDelete] = useState(false);

    const storedPageName = user.integrationSettings?.notionSettings?.defaultDatabaseName;
    const defaultDatabaseId = user.integrationSettings?.notionSettings?.defaultDatabaseId;

    const mappedPages = notionPages
        ?.filter((p) => p.id && p.title.length && p.title.some((t) => t.text.content))
        .map((p) => {
            const validTitle = p.title.find((t) => t.text.content);
            if (!validTitle) {
                return null;
            }
            return {
                code: p.id,
                label: validTitle.text.content ?? '',
            };
        });
    const notionListItemPages = mappedPages?.filter((p): p is ListItemValue<string> => !!p);
    const [isLoading, setIsLoading] = useState(!notionListItemPages && !storedPageName);
    const [selectionLoadingText, setSelectionLoadingText] = useState(isLoading ? 'Loading Notion Pages...' : '');

    return (
        <Column>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>Notion Settings</b>
                </ResponsiveModalTitle>
            </Row>

            <Hairline />

            <Spacing factor={1 / 2} />

            <ScrollArea style={{ height: '40vh' }} sidePadding={10}>
                <Row>
                    <Column>
                        {notionPages && !notionListItemPages?.length && !storedPageName ? (
                            <>
                                <BodyRegular>
                                    No Notion Pages Found. Please reauthenticate with Notion to provide Spinach.io with
                                    access to a page in which to save summaries
                                </BodyRegular>
                                <Spacing factor={1 / 3} />
                            </>
                        ) : (
                            <>
                                <BodyLarge>
                                    Select a Page where meeting summaries will be saved to finish Notion setup
                                </BodyLarge>
                                <Spacing factor={1 / 3} />

                                {!defaultDatabaseId ? (
                                    <>
                                        <BodyRegular style={{ fontWeight: 'bold' }}>
                                            ℹ️ Summaries will not be exported to Notion until a page is selected
                                        </BodyRegular>
                                        <Spacing factor={1 / 3} />
                                    </>
                                ) : (
                                    <></>
                                )}

                                <BodyRegular style={{ fontWeight: 'bold' }}>
                                    ⚠️ The data exported to Notion will share the permissions of the{' '}
                                    {storedPageName ? `${storedPageName} page` : 'page you select'}.
                                </BodyRegular>
                                <Spacing factor={1 / 3} />
                                <DropDown
                                    buttonProps={{
                                        style: {
                                            maxWidth: '280px',
                                        },
                                        labelStyles: {
                                            fontWeight: 600,
                                        },
                                        isLoading: isLoading,
                                        loadingText: selectionLoadingText,
                                    }}
                                    title={'Choose a parent page'}
                                    selected={storedPageName ?? undefined}
                                    values={notionListItemPages ?? []}
                                    handleSelection={async (code, value) => {
                                        setIsLoading(true);
                                        setSelectionLoadingText('Saving...');

                                        track(ClientEventType.SelectNotionPageInSettingsClick);

                                        const pageName = value.label;
                                        const pageId = code;

                                        const result = await postNotionUserParentPage(pageId, pageName);
                                        if (result?.user) {
                                            setUser(result.user);
                                        }

                                        setSelectionLoadingText('');
                                        setIsLoading(false);
                                    }}
                                />

                                <Spacing factor={1 / 3} />
                            </>
                        )}
                        <Hairline />
                        <BodyLarge>Disconnect Notion</BodyLarge>
                        <BodyRegular>After disconnecting, you will no longer be integrated with Notion</BodyRegular>
                        <Spacing factor={1 / 3} />
                        <OutlinedButton
                            title="Remove Notion Connection"
                            style={{
                                minWidth: '250px',
                                width: 'fit-content',
                            }}
                            labelStyles={{
                                width: 'unset',
                            }}
                            disabled={isConfirmedToDelete}
                            onClick={() => {
                                track(ClientEventType.DeleteIntegrationClick, {
                                    OauthProvider: SpinachIntegration.Notion,
                                });
                                setIsConfirmedToDelete(true);
                            }}
                        />
                        {isConfirmedToDelete ? (
                            <Row style={{ opacity: 1, transition: '500ms', marginTop: '10px' }}>
                                <PrimaryButton
                                    title={'Yes, Remove it'}
                                    color={lightTheme.status.negative}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    isLoading={isLoading}
                                    onClick={async () => {
                                        setIsLoading(true);

                                        track(ClientEventType.DeleteIntegrationConfirmClick, {
                                            OauthProvider: SpinachIntegration.Notion,
                                        });

                                        // First we delete the jira integration
                                        await deleteIntegration(SpinachIntegration.Notion);

                                        // then we refetch and hydrate user in state, now without the integration
                                        const userResponse = await getUser();
                                        if (userResponse.user) {
                                            setUser(userResponse.user);
                                        }

                                        setIsLoading(false);
                                        setSubview(null);
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                />

                                <span style={{ display: 'flex', width: '10px' }} />

                                <OutlinedButton
                                    title={'Cancel'}
                                    disabled={isLoading}
                                    style={{ opacity: 1, transition: '500ms' }}
                                    onClick={() => {
                                        track(ClientEventType.CancelIntegrationDeleteClick, {
                                            OauthProvider: SpinachIntegration.Notion,
                                        });
                                        setIsConfirmedToDelete(false);
                                    }}
                                />
                            </Row>
                        ) : null}
                    </Column>
                </Row>
                <Spacing />
            </ScrollArea>
        </Column>
    );
}
