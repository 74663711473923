import { TextField } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { useEffect } from 'react';

import { DiscussionTopic } from '@spinach-shared/types';

import { useGlobalFacilitation } from '../../../hooks';
import { BodyLarge, BodyRegular, lightTheme } from '../../../styles';
import { withFullStoryMasking } from '../../../utils';
import { Column, Row, Spacing } from '../../common';
import { useSpinachNewInputStyles } from '../../input';
import { GenericSpinachInsight } from './SpinachInsight';
import { TimedHeader } from './TimedHeader';
import { Header, Padding, ScrollView } from './common';

function useEmptyAppendedTopics(currentTopic?: DiscussionTopic) {
    const [facilitation, setFacilitation] = useGlobalFacilitation();

    useEffect(() => {
        if (currentTopic?.notes.every((note) => !!note)) {
            let copyOfDiscussionTopics = [...facilitation.discussionTopics];

            copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                if (topic.viewId === currentTopic.viewId) {
                    return {
                        ...topic,
                        notes: currentTopic.notes.concat(''),
                    };
                } else {
                    return topic;
                }
            });
            setFacilitation((facilitation) => ({
                ...facilitation,
                discussionTopics: copyOfDiscussionTopics,
            }));
        }
    }, [currentTopic]);
}

export function DiscussionTopicContent(): JSX.Element {
    const [facilitation, setFacilitation] = useGlobalFacilitation();

    const currentTopic = facilitation.discussionTopics.find((p) => p.viewId === facilitation.currentViewId);

    useEmptyAppendedTopics(currentTopic);

    const classes = useSpinachNewInputStyles({
        value: '',
    });

    if (!currentTopic) {
        // TODO-insights - handle error scenario
        return <>no participant matching list</>;
    }

    return (
        <>
            <TimedHeader title={currentTopic.title} isPii />

            <Spacing factor={1 / 5} />

            <ScrollView sidePadding={0} stretch style={{ height: 'calc(100% - 50px)' }}>
                <Padding>
                    {currentTopic.notes.map((note, i) => {
                        return (
                            <Row key={i} style={{ marginLeft: '10px', marginTop: '10px', width: 'calc(100% - 20px)' }}>
                                <TextField
                                    aria-label={`discussion-topic-notes-input-field`}
                                    InputProps={{ classes: { root: classes.base } }}
                                    multiline={true}
                                    className={withFullStoryMasking(classes.root)}
                                    fullWidth
                                    value={note}
                                    autoFocus={false}
                                    onChange={(e) => {
                                        if (e.target.value.includes('\n')) {
                                            // TODO - tab from here?
                                            return;
                                        }

                                        let copyOfNotes = [...currentTopic.notes];
                                        let copyOfDiscussionTopics = [...facilitation.discussionTopics];

                                        copyOfNotes = copyOfNotes.map((note, index) => {
                                            if (index === i) {
                                                return e.target.value;
                                            } else {
                                                return note;
                                            }
                                        });

                                        copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                                            if (topic.viewId === currentTopic.viewId) {
                                                return {
                                                    ...topic,
                                                    notes: copyOfNotes,
                                                };
                                            } else {
                                                return topic;
                                            }
                                        });

                                        setFacilitation((facilitation) => ({
                                            ...facilitation,
                                            discussionTopics: copyOfDiscussionTopics,
                                        }));
                                    }}
                                    onBlur={(e) => {
                                        /** remove empty inputs that aren't at the bottom of the topics list */
                                        if (!e.target.value) {
                                            if (i !== currentTopic.notes.length - 1) {
                                                let copyOfDiscussionTopics = [...facilitation.discussionTopics];

                                                copyOfDiscussionTopics = copyOfDiscussionTopics.map((topic) => {
                                                    if (topic.viewId === currentTopic.viewId) {
                                                        return {
                                                            ...topic,
                                                            notes: currentTopic.notes.filter((n, ni) => ni !== i),
                                                        };
                                                    } else {
                                                        return topic;
                                                    }
                                                });
                                                setFacilitation((facilitation) => ({
                                                    ...facilitation,
                                                    discussionTopics: copyOfDiscussionTopics,
                                                }));
                                            }
                                        }
                                    }}
                                    placeholder={'+ Add a note'}
                                />
                            </Row>
                        );
                    })}
                </Padding>
            </ScrollView>
        </>
    );
}
