export function base64URLEncode(str: Buffer) {
    return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/\=$/g, '');
}

export function base64URLDecode(encoded: string) {
    // Replace URL-safe characters with Base64 characters
    const base64 = encoded.replace(/-/g, '+').replace(/_/g, '/');

    // Calculate padding based on the length of the string
    const padding = base64.length % 4 === 0 ? 0 : 4 - (base64.length % 4);

    // Add padding characters if necessary
    const paddedBase64 = base64 + '==='.slice(0, padding);

    // Decode Base64
    const decoded = Buffer.from(paddedBase64, 'base64').toString('utf8');

    return decoded;
}
