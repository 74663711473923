import { Modal, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ClientEventType, SeriesUserMetadata } from '@spinach-shared/types';
import { StoredSpinachSeries, isDemoSeries, isEmailValid } from '@spinach-shared/utils';

import { deleteUserFromSeries, postExperienceEvent } from '../../apis';
import { postLegacySeriesUsersAssociation } from '../../apis/postLegacySeriesUsersAssociation';
import { ElementId } from '../../constants';
import { useGlobalAuthedUser, useGlobalRouting, useGlobalStoredSeries } from '../../hooks';
import { BodyLarge, BodyRegular, HeaderThree } from '../../styles';
import { ListItemValue, SetValue } from '../../types';
import { getClientPlatform, withAccessibleSubmitProps, withFullStoryMasking } from '../../utils';
import { ItemList } from '../common/ItemList';
import { Column, Row, Spacing } from '../common/framing';
import { useSpinachInputStyles } from '../input';
import { PrimaryButton, ScrollArea } from '../stand-up';
import { ErrorBodySubtitle, ModalContent } from './common';

const Content = styled(ModalContent)`
    max-width: 450px;
`;

export function InviteUsersByEmail({
    series,
    numberOfUsersAdded,
    setNumberOfUsersAdded,
    userId,
    autoFocusDelay = 0,
}: {
    series: StoredSpinachSeries;
    userId: string;
    autoFocusDelay?: number;
    numberOfUsersAdded: number;
    setNumberOfUsersAdded: SetValue<number>;
}): JSX.Element {
    const [, setSeries] = useGlobalStoredSeries();
    const usersToList = series.userMetadataList as SeriesUserMetadata[];

    const [userMetadataList, setUserMetadatList] = useState(usersToList);
    const [newEmailInvite, setNewEmailInvite] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useSpinachInputStyles({ value: newEmailInvite });

    const newEmailInvites = newEmailInvite
        .split(',')
        .map((email) => email.trim())
        .filter((email) => !!email);

    const seriesHasUserWithSameEmail = !!userMetadataList.find((s) => newEmailInvites.includes(s.email));

    const lengthError =
        newEmailInvites.length + userMetadataList.length > 50 ? 'You can only add 50 users to a series' : '';

    const error = seriesHasUserWithSameEmail
        ? 'User is already added'
        : newEmailInvites.some((newEmail) => !isEmailValid(newEmail)) && !!newEmailInvite
        ? 'Invalid email'
        : '';

    const onRemoveUserClick = async (user: SeriesUserMetadata) => {
        const updatedSeries = await deleteUserFromSeries(series.id, user._id);

        if (updatedSeries) {
            setSeries(updatedSeries);
            setUserMetadatList(updatedSeries.userMetadataList as SeriesUserMetadata[]);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            const inputElement = document.getElementById(ElementId.InviteEmailInput);
            if (inputElement) {
                inputElement.focus();
            }
        }, autoFocusDelay);
    }, []);

    const listItemValues: ListItemValue<string>[] = userMetadataList.map((user) => {
        const isThisUser = user._id === userId;
        return {
            code: user._id,
            label: `${user.email} ${isThisUser ? '(You)' : ''}`,
            postContent: !isThisUser ? (
                <span
                    key={user._id}
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    {...withAccessibleSubmitProps(() => {
                        onRemoveUserClick(user);
                    })}
                >
                    <HighlightOffIcon htmlColor="gray" />
                </span>
            ) : null,
        };
    });

    const onSubmitInvite = async () => {
        if (isDemoSeries(series.slug)) {
            return;
        }

        setIsLoading(true);
        const updatedSeries = await postLegacySeriesUsersAssociation(series.id, {
            emails: newEmailInvites,
            platform: getClientPlatform(),
        });
        setNumberOfUsersAdded(numberOfUsersAdded + newEmailInvites.length);

        if (updatedSeries) {
            setUserMetadatList(updatedSeries.userMetadataList as SeriesUserMetadata[]);
            setSeries(updatedSeries);
        }

        setNewEmailInvite('');
        setIsLoading(false);
    };

    return (
        <>
            <BodyRegular>
                Add teammates to this meeting by entering their email below, you can add multiple emails with a comma
                (,)
            </BodyRegular>

            <Spacing factor={1 / 2} />

            <Row style={{ flex: 'unset' }}>
                <TextField
                    id={ElementId.InviteEmailInput}
                    InputProps={{ classes: { root: classes.base } }}
                    className={withFullStoryMasking(classes.root)}
                    fullWidth
                    placeholder="Add by email"
                    value={newEmailInvite}
                    onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                            await onSubmitInvite();
                        }
                    }}
                    onChange={(e) => {
                        setNewEmailInvite(e.target.value.toLowerCase());
                    }}
                />

                <PrimaryButton
                    title="Add"
                    isLoading={isLoading}
                    loadingText={''}
                    onClick={onSubmitInvite}
                    disabled={!!error || !!lengthError || !newEmailInvite.trim()}
                />
            </Row>

            <ErrorBodySubtitle>{lengthError || error}</ErrorBodySubtitle>

            <Spacing factor={1 / 2} />

            <BodyLarge>Added Users</BodyLarge>

            <Spacing factor={1 / 2.5} />

            <ScrollArea style={{ height: '30vh' }} sidePadding={0}>
                <ItemList style={{ borderBottom: '1px solid lightgray' }} values={listItemValues} />
            </ScrollArea>
        </>
    );
}

export function ShareSeriesModal({
    isOpen,
    onClose,
    series,
    userId,
    redirectOnClose,
}: {
    isOpen: boolean;
    onClose: () => void;
    series: StoredSpinachSeries;
    userId: string;
    redirectOnClose?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { routeToSeriesExperience } = useGlobalRouting();

    const [numberOfUsersAdded, setNumberOfUsersAdded] = useState(0);
    const onModalClose = async () => {
        if (user.spinachUserId) {
            postExperienceEvent({
                eventType: ClientEventType.ShareSeriesModalClosed,
                payload: {
                    ...user.toUserIdentityPayload(),
                    NumberOfUsersAdded: numberOfUsersAdded,
                },
            });
        }
        onClose();
    };

    if (isDemoSeries(series.slug)) {
        return <></>;
    }

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                if (redirectOnClose) {
                    return;
                }
                onModalClose();
            }}
        >
            <Content id={ElementId.ShareSeriesModalContent}>
                <Column>
                    <HeaderThree>Add a Teammate</HeaderThree>
                    <InviteUsersByEmail
                        numberOfUsersAdded={numberOfUsersAdded}
                        setNumberOfUsersAdded={setNumberOfUsersAdded}
                        series={series}
                        userId={userId}
                    />

                    <Spacing factor={1 / 3} />

                    <PrimaryButton
                        title="Done"
                        onClick={async () => {
                            onModalClose();
                            if (redirectOnClose) {
                                routeToSeriesExperience(series.slug);
                            }
                        }}
                    />
                </Column>
            </Content>
        </Modal>
    );
}
