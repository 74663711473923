import moment from 'moment';
import React from 'react';

import { MILLIS_IN_DAY } from '@spinach-shared/constants';
import { ClientEventType, TrimmedTicket } from '@spinach-shared/types';

import {
    useFacilitationTracking,
    useGlobalFacilitation,
    useGlobalLastAiSummary,
    useGlobalNullableJiraChangelog,
} from '../../../hooks';
import { BodyLarge, BodyRegular } from '../../../styles';
import { URLUtil, withFullStoryMasking } from '../../../utils';
import { Anchor, Column, Spacing } from '../../common';
import { TimedHeader } from './TimedHeader';
import { Padding, ScrollView } from './common';

export function TicketingTables({ ticketingData }: { ticketingData: TrimmedTicket[] }): JSX.Element {
    const track = useFacilitationTracking();

    return (
        <>
            <BodyLarge>Updated Tickets since last meeting</BodyLarge>
            <Spacing factor={1 / 5} />
            {ticketingData.length ? (
                <>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr>
                                <th
                                    style={{
                                        padding: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Ticket
                                </th>
                                <th
                                    style={{
                                        padding: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Title
                                </th>
                                <th
                                    style={{
                                        padding: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Current Status
                                </th>
                                <th
                                    style={{
                                        padding: '10px',
                                        textAlign: 'left',
                                    }}
                                >
                                    Day in Status
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ticketingData.map((d, i) => (
                                <tr
                                    key={d.id}
                                    style={{
                                        backgroundColor: i % 2 === 0 ? '#F2F8F0' : 'initial',
                                    }}
                                >
                                    <td style={{ padding: '10px' }}>
                                        <Anchor
                                            className={withFullStoryMasking()}
                                            onClick={() => {
                                                track(ClientEventType.AIFacilitationClick, {
                                                    ClickedOn: 'Jira Ticket URL',
                                                    Location: 'Individual Update Table',
                                                    Status: d.status,
                                                });
                                                URLUtil.openURL(d.ticketUrl, {
                                                    redirectThroughBackendWhenOnZoom: true,
                                                });
                                            }}
                                        >
                                            {d.displayName}
                                        </Anchor>
                                    </td>
                                    <td style={{ padding: '10px' }} className={withFullStoryMasking()}>
                                        {d.title}
                                    </td>
                                    <td style={{ padding: '10px' }}>{d.status}</td>
                                    <td style={{ padding: '10px' }}>
                                        {Math.floor(moment().diff(moment(d.statusLastUpdated)) / MILLIS_IN_DAY)} days
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <>
                    <BodyRegular>No tickets have changed status since last update</BodyRegular>
                </>
            )}
        </>
    );
}

export function UserUpdateContent(): JSX.Element {
    const [facilitation] = useGlobalFacilitation();
    const [summaryJson] = useGlobalLastAiSummary();
    const [ticketing] = useGlobalNullableJiraChangelog();

    const currentParticipant = facilitation.participants.find((p) => p.viewId === facilitation.currentViewId);

    if (!currentParticipant) {
        // TODO-insights - handle error scenario
        return <>no participant matching list</>;
    }

    const currentParticipantsPreviousPlansProgress = summaryJson?.plansAndProgress?.filter((p) => {
        if (p.email === currentParticipant.email) {
            return true;
        }

        if (p.userName === currentParticipant.displayName) {
            return true;
        }

        return false;
    });

    const currentParticipantsPreviousActionItems = summaryJson?.actionItems?.filter((a) => {
        const { email, displayName } = currentParticipant;

        const usersOnActionItem = a.users;

        const userNamesOnActionItems = usersOnActionItem.map((u) => u.username).filter((s): s is string => !!s);
        const emailsOnActionItems = usersOnActionItem.map((u) => u.email).filter((s): s is string => !!s);

        const emailOnActionItem = email ? emailsOnActionItems.includes(email) : false;
        const displayNameOnActionItem = userNamesOnActionItems.includes(displayName);

        return emailOnActionItem || displayNameOnActionItem;
    });

    const currentParticipantsPreviousBlockers = summaryJson?.blockers?.filter(
        (a) =>
            a.user === currentParticipant.displayName ||
            (a.user === currentParticipant.email && !!currentParticipant.email)
    );

    const ticketingKeys = Object.keys(ticketing || {});
    const matchingKey = ticketingKeys.find((key) => {
        const [displayName, email] = key.split('::::');
        if (displayName === currentParticipant.displayName) {
            return key;
        }

        if (email === currentParticipant.email && !!email) {
            return key;
        }
    });

    const ticketingData = matchingKey ? ticketing?.[matchingKey] : undefined;

    return (
        <>
            <TimedHeader title={currentParticipant.displayName} />

            <ScrollView sidePadding={0} stretch style={{ height: 'calc(100% - 50px)' }}>
                <Padding>
                    <Spacing factor={1 / 2} />

                    <BodyLarge>Previous Plans</BodyLarge>
                    <Spacing factor={1 / 5} />
                    <Column style={{ marginBottom: '20px' }}>
                        {currentParticipantsPreviousPlansProgress?.length ? (
                            <ul style={{ margin: 'unset' }}>
                                {currentParticipantsPreviousPlansProgress?.map((pp, i) => (
                                    <li key={i} style={{ marginBottom: '5px' }} className={withFullStoryMasking()}>
                                        {pp.plans}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <BodyRegular>No previous updates</BodyRegular>
                        )}
                    </Column>

                    <BodyLarge>Previous Action Items</BodyLarge>
                    <Spacing factor={1 / 5} />
                    <Column style={{ marginBottom: '20px' }}>
                        {currentParticipantsPreviousActionItems?.length ? (
                            <>
                                {currentParticipantsPreviousActionItems?.map((pp, i) => (
                                    <Column
                                        key={i}
                                        style={{ marginBottom: '5px', width: 'calc(100% - 20px)', marginLeft: '20px' }}
                                    >
                                        <b className={withFullStoryMasking()}>{pp.title}</b>
                                        <ul style={{ margin: 'unset' }}>
                                            <li style={{ marginBottom: '5px' }} className={withFullStoryMasking()}>
                                                {pp.context}
                                            </li>
                                        </ul>
                                    </Column>
                                ))}
                            </>
                        ) : (
                            <BodyRegular>No previous action items</BodyRegular>
                        )}
                    </Column>

                    <BodyLarge>Previous Blockers</BodyLarge>
                    <Spacing factor={1 / 5} />
                    <Column style={{ marginBottom: '20px' }}>
                        {currentParticipantsPreviousBlockers?.length ? (
                            <>
                                {currentParticipantsPreviousBlockers?.map((pp, i) => (
                                    <React.Fragment key={i}>
                                        <b className={withFullStoryMasking()}>{pp.content}</b>
                                        <ul style={{ margin: 'unset' }}>
                                            <li className={withFullStoryMasking()} style={{ marginBottom: '5px' }}>
                                                {pp.context}
                                            </li>
                                        </ul>
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <BodyRegular>No previous blockers</BodyRegular>
                        )}
                    </Column>

                    {ticketing && ticketingData ? <TicketingTables ticketingData={ticketingData} /> : null}

                    <Spacing />
                </Padding>
            </ScrollView>
        </>
    );
}
