import { atom } from 'recoil';

import { DashboardSection } from '../components/spinach-ai/dashboard/types';

export type GlobalAiDashboardState = {
    activeSection: DashboardSection;
    hasEditedSummary: boolean;
    toastText: string;
};

export const atomAiDashboardState = atom<GlobalAiDashboardState>({
    key: 'aiDashboard',
    default: {
        activeSection: DashboardSection.Meetings,
        hasEditedSummary: false,
        toastText: '',
    },
});
