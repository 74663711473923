import { Route } from 'react-router-dom';

import { ClientPath } from '../common/ClientPaths';
import { AIEditSummary } from './AIEditSummary';
import { LoadLastSummary } from './LoadLastSummary';
import { AIDashboard } from './dashboard';
import { FacilitationContainer } from './facilitation';
import { OnboardingFlowContainer } from './onboarding/OnboardingFlow';

export const SpinachAIRoutes = [
    <Route path={ClientPath.OnboardingAI} element={<OnboardingFlowContainer />} />,
    <Route path={ClientPath.AIHome} element={<AIDashboard />} />,
    <Route path={ClientPath.AIEditSummary} element={<AIEditSummary />} />,
    <Route path={ClientPath.AILastSummary} element={<LoadLastSummary />} />,
    <Route path={ClientPath.AIFacilitation} element={<FacilitationContainer />} />,
];
