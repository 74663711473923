import { CallMade } from '@material-ui/icons';

import { ClientEventType, SpinachAPIPath, SpinachIntegration, WebUrlQuery } from '@spinach-shared/types';

import GoogleCalendarSrc from '../../../../assets/google-cal.png';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { AiModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';

export function CalendarIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: AiModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    return (
        <>
            <Row>
                <HeaderThree>Calendar</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    {user.isAuthedForGoogleCalendar
                        ? 'This is the account you have linked to your calendar'
                        : 'Connect your Google Calendar to Spinach'}
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.GoogleCalendar)}>
                <img src={GoogleCalendarSrc} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Google</Label>
                {!user.isAuthedForGoogleCalendar ? (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Google Calendar Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleCalendarAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                ) : (
                    <ConnectedRowContents
                        label={'Connected'}
                        isRemoveVisible={hoveredRow === SpinachIntegration.GoogleCalendar}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveGoogleCalendar);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Google Calendar Modal`,
                            });
                        }}
                    />
                )}
            </Row>
        </>
    );
}
