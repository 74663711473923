import { Tooltip } from '@material-ui/core';
import { Modal } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { sectionTypeList } from '@spinach-shared/constants';
import { BaseMeetingProps, SpinachMeetingProps, YTBAgendaItemProps } from '@spinach-shared/models';
import {
    AgendaRestartingRequest,
    AuthoredUpdate,
    ClientEventType,
    ClientExperienceEvent,
    ClientSocketEvent,
    MeetingInactiveRequest,
    MeetingStatus,
    NotificationCode,
    SentimentPickerLocation,
    TICKET_SOURCE_MAP,
    Ticket,
    TicketSource,
    TypedUpdate,
    UserMood,
} from '@spinach-shared/types';
import {
    doesUpdateSectionHaveTypedUpdates,
    formatSummaryHTML,
    formatTime,
    getFormattedDate,
    getTicketSource,
    isLocalStage,
} from '@spinach-shared/utils';

import { patchUser } from '../../apis';
import { postExperienceEvent } from '../../apis/postExperienceEvent';
import { ReactComponent as CopyContentIcon } from '../../assets/copy-btn.svg';
import SlackNotificationSrc from '../../assets/demo/slack-notification.png';
import { ElementId } from '../../constants';
import {
    useAsanaEnablement,
    useFreeTierLimitations,
    useGlobalAuthedUser,
    useGlobalDemoState,
    useGlobalLiveSeries,
    useGlobalModal,
    useGlobalRouting,
    useInactivityModalEmitter,
    useInviteViaSlackEnablement,
    useIssueBasedEnablement,
    useJiraEnablement,
    useLandingAnalytic,
    useLiveNotifications,
    usePersonaDemo,
    useSeriesReality,
    useTypedUpdateWrapper,
} from '../../hooks';
import { useCountdownTimer } from '../../hooks/useCountdownTimer';
import { BodyLarge, BodyRegular, BodySubtitle, lightTheme, withSelectionPrevention } from '../../styles';
import { AttachmentProps, SharedSpinachStandUpProps } from '../../types/StandUp';
import { usePracticeRoundCompletion } from '../../utils';
import { createWebsocketPayload } from '../../utils/analytics';
import { copyTextToClipboard } from '../../utils/copyTextToClipboard';
import { getClientPlatform, isWebPlatform } from '../../utils/platform';
import { withFullStoryMasking } from '../../utils/withFullStoryMasking';
import {
    BootstrapTooltip,
    Bullet,
    BulletMark,
    CloseButton,
    Column,
    FreeTierLimitationMessage,
    LimitationIntent,
    OverlayHint,
    PulsingHint,
    Row,
    Spacer,
    Spacing,
} from '../common';
import { SendOptions } from '../common/SendOptions';
import { AsanaPreviewContainer } from '../input';
import { SentimentBullet } from '../input/LiveItemBullet';
import { Reactions } from '../input/Reaction';
import { SentimentComponent } from '../input/SentimentPicker';
import { CenteredRow, ModalContent } from '../series/common';
import { BlurredText } from './BlurredText';
import { DiscussionReaction, DiscussionReactionButtons, useDiscussionReactions } from './DiscussionReactions';
import { LiveItemSentimentDetails } from './LiveItemSentimentDetails';
import { Notification } from './Notification';
import { ParkingLotItemsByParticipant } from './ParkingLotItemsByParticipant';
import { PrimaryButton } from './PrimaryButton';
import { ScrollArea } from './ScrollArea';
import SecondaryButton from './SecondaryButton';
import StandUpFooter from './StandUpFooter';
import { StandUpHeader } from './StandUpHeader';
import { fadeIn } from './animation';

const RESTART_TIMER_IN_SECONDS = isLocalStage() ? 30 : 1200;

const animateSlackNotification = () => css`
    animation: ${fadeIn} 500ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
`;

const SlackNotification = styled.img`
    position: absolute;
    height: 70px;
    top: -40px;
    margin-right: -10px;
    opacity: 0;

    ${animateSlackNotification()}
`;

const PersonaDemoSlackNotificationContainer = styled(Row)`
    height: 0px;
    justify-content: flex-end;
`;

const SummarySubtitle = styled(BodySubtitle)`
    ${withSelectionPrevention()}
`;

const TeamSentimentContainer = styled.div`
    flex-direction: column;
    background-color: #cde7fc1a;
    text-align: start;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const SentimentContainer = styled.div`
    padding: 3px;
    margin-bottom: 8px;
    border: 1px solid ${(props) => props.theme.neutrals.grayDark};
`;

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    background-color: ${(props) => props.theme.neutrals.offWhite};
    width: 100%;
    flex-shrink: 0;
`;

const TeammateNameRow = styled.div`
    ${withSelectionPrevention()};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const TeammatesTotalTalkTime = styled.div`
    ${withSelectionPrevention()};
    font-size: 14px;
    font-weight: normal;
    padding-top: 3px;
`;

const UPDATE_OFFSET = 0;

const UpdateRowContainer = styled.div`
    background-color: #fafafa;
    padding-top: 2px;
    margin-top: 5px;
    margin-bottom: 8px;
    padding-bottom: 8px;
`;
const UpdateRow = styled.div<{ isTabbedLine?: boolean }>`
    display: flex;
    flex-direction: row;
    margin-left: ${UPDATE_OFFSET}px;
    font-size: 14px;
    margin-bottom: 2px;
    margin-top: 5px;
    align-items: center;
    width: ${(props) => (props.isTabbedLine ? `calc(100% - ${UPDATE_OFFSET}px)` : '100%')};
    padding-left: 0px;
`;

const CONTENT_OFFSET_FROM_BULLET = 4;
const UpdateRowContent = styled.div`
    margin-left: ${CONTENT_OFFSET_FROM_BULLET}px;
    width: calc(100% - ${CONTENT_OFFSET_FROM_BULLET}px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
`;

const Content = styled(ModalContent)`
    min-width: 280px;
    justify-content: space-between;
`;

const CenteredContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    flex-shrink: 0;
    justify-content: space-between;
    align-content: center;
    text-align: center;
`;

const Author = styled(BodyRegular)`
    font-weight: 700;
    font-size: 12px;
    line-height: 115%;
    flex-direction: row;
    align-items: center;
    color: ${(props) => props.theme.secondary.midnight};
    border: 1px solid #ebf4ff;
    border-radius: 2px;
    margin-top: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 2px;
    padding-right: 8px;
    background-color: #e0eeff;
`;

export function ReadOnlyUpdateRow({
    text,
    isTabbedLine,
    update,
    ticket,
    ticketSource,
    isSentimentDetails,
}: {
    text: string | JSX.Element;
    isTabbedLine?: boolean;
    update?: TypedUpdate;
    ticket?: Ticket;
    ticketSource?: TicketSource;
    isSentimentDetails?: boolean;
}): JSX.Element {
    const typedUpdateWrapper = update ? useTypedUpdateWrapper(update) : null;
    const isAsanaEnabled = useAsanaEnablement();

    const isJiraEnabled = useJiraEnablement();
    const isAsanaTask = ticketSource === TICKET_SOURCE_MAP.Asana;

    const shouldShowAsanaPreviewContainer = isAsanaEnabled && ticket && typedUpdateWrapper && isAsanaTask;

    const isTicketItem = Boolean(isJiraEnabled && ticket && typedUpdateWrapper);
    // Ticket should never be undefined if `isTicketItem` is true
    const transformedText =
        isTicketItem && ticket ? `${isAsanaTask ? ticket?.projectName || '' : ticket.id} ${ticket.title}` : text;

    return (
        <>
            <UpdateRow isTabbedLine={isTabbedLine}>
                {(!isTabbedLine && !!typedUpdateWrapper?.sentiment) || isSentimentDetails ? (
                    <></>
                ) : (
                    <BulletMark
                        style={{ marginTop: 'unset', paddingLeft: !isTabbedLine ? '6px' : '0px', alignItems: 'center' }}
                    >
                        <Bullet />
                    </BulletMark>
                )}
                {typedUpdateWrapper?.sentiment && !isTabbedLine && !isSentimentDetails ? (
                    <SentimentBullet sentiment={typedUpdateWrapper.sentiment} />
                ) : null}
                <Row
                    style={{
                        alignContent: 'center',
                    }}
                >
                    {isSentimentDetails && update ? (
                        <LiveItemSentimentDetails typedUpdate={update} labelStyle={{ padding: '0px 3px' }} />
                    ) : (
                        <UpdateRowContent className={withFullStoryMasking()}>{transformedText}</UpdateRowContent>
                    )}
                </Row>
            </UpdateRow>
            {shouldShowAsanaPreviewContainer ? (
                <AsanaPreviewContainer
                    update={typedUpdateWrapper?.typedUpdate}
                    issueData={ticket}
                    interactive={false}
                />
            ) : null}{' '}
        </>
    );
}

function SummaryUpdate({ update, showAuthor }: { update: TypedUpdate; showAuthor?: boolean }) {
    const attachmentProps: AttachmentProps = {
        typedUpdate: update,
        saveFullTypedUpdate: () => {},
        isDisabled: true,
    };

    const ticketSource = getTicketSource(update);

    const isAuthoredUpdate = (typedUpdate: TypedUpdate): typedUpdate is AuthoredUpdate => 'author' in typedUpdate;

    return (
        <UpdateRowContainer>
            <ReadOnlyUpdateRow
                key={update.id}
                update={update}
                text={update.text}
                ticket={update.jiraData || update.asanaData || update.ticketData?.ticket}
                ticketSource={ticketSource}
            />
            <Column style={{ paddingLeft: '5px' }}>
                {update.subItems
                    ?.filter((su) => !!su.text)
                    .map((subItem) => (
                        <ReadOnlyUpdateRow update={update} key={subItem.id} text={subItem.text} isTabbedLine={true} />
                    ))}
                {update.sentimentDetails ? (
                    <ReadOnlyUpdateRow
                        update={update}
                        key={`${update.id}-sentiment-details`}
                        text={update.sentimentDetails}
                        isSentimentDetails={true}
                    />
                ) : (
                    <></>
                )}
                {update.resolvers?.length ? (
                    update.resolvers?.map((resolver) => (
                        <ReadOnlyUpdateRow
                            key={`${update.id}-resolver-details`}
                            isTabbedLine={true}
                            update={{ ...update, sentiment: undefined }}
                            text={
                                <p style={{ margin: 'unset' }}>
                                    <b>{resolver.preferredName}</b>: <i>{resolver.details}</i>
                                </p>
                            }
                        />
                    ))
                ) : (
                    <></>
                )}
                <Row>
                    {showAuthor && isAuthoredUpdate(update) ? <Author>{update.author}</Author> : <></>}
                    <Reactions {...attachmentProps} />
                </Row>
            </Column>
        </UpdateRowContainer>
    );
}

function UpdatesByType({ item }: { item: YTBAgendaItemProps }): JSX.Element {
    const [liveSeries] = useGlobalLiveSeries();
    const isIssueBasedEnabled = useIssueBasedEnablement();

    const customLists = liveSeries.enabledCustomRoundtableLists;

    const isUpdateSectionPopulated = doesUpdateSectionHaveTypedUpdates(item.standUpUpdate);
    const areReservedListsEmpty = !sectionTypeList.some(isUpdateSectionPopulated);
    const isIssueBasedCheckinEmpty = isIssueBasedEnabled && item.standUpUpdate.hasNoIssueBasedUpdates;

    return areReservedListsEmpty || isIssueBasedCheckinEmpty ? (
        item.isParticipantAgendaItem ? (
            <ReadOnlyUpdateRow text={`No updates today`} />
        ) : (
            <></>
        )
    ) : (
        <>
            {sectionTypeList
                .filter(isUpdateSectionPopulated)
                .filter((typeProps) => liveSeries.isComponentEnabled(typeProps.spinachUpdateType))
                .map((typeProps) => {
                    const updatesOfSection = item.standUpUpdate.getUpdatesForType(typeProps.spinachUpdateType);
                    return (
                        <div style={{ width: '100%' }} key={typeProps.spinachUpdateType}>
                            <Spacing factor={1 / 3} />
                            <SummarySubtitle>{typeProps.title ?? ''}</SummarySubtitle>

                            {updatesOfSection.map((update) => (
                                <SummaryUpdate update={update} />
                            ))}
                        </div>
                    );
                })}

            {customLists
                .filter((l) => item.standUpUpdate.getCustomUpdatesForList(l.id).length)
                .map((list) => {
                    return (
                        <div style={{ width: '100%' }} key={list.id}>
                            <Spacing factor={1 / 3} />
                            <SummarySubtitle>{list.title}</SummarySubtitle>

                            {item.standUpUpdate.getCustomUpdatesForList(list.id).map((update) => (
                                <SummaryUpdate update={update} />
                            ))}
                        </div>
                    );
                })}
        </>
    );
}

function MeetingCompleteMessage({
    notificationMessage,
    notificationCode,
}: {
    notificationMessage: string;
    notificationCode: NotificationCode | null;
}): JSX.Element {
    if (
        !notificationMessage ||
        !notificationCode ||
        ![
            NotificationCode.SlackOutputFailure,
            NotificationCode.SlackOutputSuccessful,
            NotificationCode.SlackOutputLimited,
        ].includes(notificationCode)
    ) {
        return <></>;
    }
    let message = <>{notificationMessage}</>;
    if (notificationCode === NotificationCode.SlackOutputLimited) {
        message = <FreeTierLimitationMessage intent={LimitationIntent.Slack} icon="" />;
    }

    return (
        <Notification
            isOpen={true}
            containerStyle={{ position: 'relative', bottom: 'unset', marginBottom: '20px' }}
            contentStyle={{ width: '100%' }}
            message={message}
            icon={
                notificationCode === NotificationCode.SlackOutputSuccessful ? (
                    <CheckCircleIcon
                        style={{ color: lightTheme.neutrals.white }}
                        htmlColor={lightTheme.neutrals.white}
                    />
                ) : (
                    <Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />
                )
            }
        />
    );
}

const Moods = ({ userMoods, meetingStatus }: { userMoods: UserMood[]; meetingStatus: MeetingStatus }) => {
    const [user] = useGlobalAuthedUser();

    const thisUserMood = userMoods.find((userMood) => userMood.spinachUserId === user.spinachUserId);
    const userMoodsWithSentiment = userMoods.filter((userMood) => !!userMood.sentiment);
    return (
        <SentimentContainer>
            <BodyRegular style={{ paddingBottom: '3px' }}>Team Mood</BodyRegular>
            <TeamSentimentContainer>
                <Column>
                    {!thisUserMood?.sentiment && meetingStatus !== MeetingStatus.AgendaComplete ? (
                        <BodyRegular>Share your mood to see results</BodyRegular>
                    ) : null}
                    {userMoodsWithSentiment.length ? (
                        userMoodsWithSentiment.map((userMood) =>
                            (thisUserMood?.sentiment ||
                                meetingStatus === MeetingStatus.AgendaComplete ||
                                meetingStatus === MeetingStatus.MeetingComplete) &&
                            userMood.sentiment ? (
                                <Row key={`user-mood-${userMood.spinachUserId}`} style={{ alignItems: 'center' }}>
                                    <BodyRegular
                                        style={{ wordBreak: 'break-word', display: 'flex', flexDirection: 'row' }}
                                    >
                                        <SentimentComponent
                                            location={SentimentPickerLocation.TeamMood}
                                            sentiment={userMood.sentiment}
                                            selectedSentiment={userMood.sentiment}
                                        />
                                        {`${userMood.displayName} ${userMood.details ? `- ${userMood.details}` : ''}`}
                                    </BodyRegular>
                                </Row>
                            ) : userMood.sentiment ? (
                                <BlurredText key={`user-mood-${userMood.spinachUserId}`} title={userMood.displayName} />
                            ) : null
                        )
                    ) : (
                        <BodyRegular>No one has shared their mood yet</BodyRegular>
                    )}
                </Column>
            </TeamSentimentContainer>
        </SentimentContainer>
    );
};

const StandupSummaryContainer = styled.div`
    ${withSelectionPrevention()};
`;

const TopItems = ({ updates }: { updates: AuthoredUpdate[] }): JSX.Element => {
    return updates.length ? (
        <div style={{ width: '100%' }}>
            <TeammateNameRow>
                <BodyLarge>Top Items</BodyLarge>
            </TeammateNameRow>
            <Spacing factor={1 / 3} />

            {updates.map((update) => (
                <SummaryUpdate key={`top-item-${update.id}`} showAuthor update={update} />
            ))}
        </div>
    ) : (
        <></>
    );
};

export function StandupSummary({
    currentMeeting,
    isTeamTopics2Enabled,
    isRecentCheckIn,
    withMood = true,
}: {
    currentMeeting: BaseMeetingProps;
    isTeamTopics2Enabled?: boolean;
    isRecentCheckIn?: boolean;
    withMood?: boolean;
}): JSX.Element {
    const agenda = currentMeeting.agenda;
    const isAsyncMeeting = currentMeeting.isAsyncMeeting;
    const { isIcebreakerLockedIn, icebreakerQuestionPrompt } = currentMeeting;
    const teamTopicsItems = isTeamTopics2Enabled ? agenda.topicUpdates : agenda.parkingLotUpdates;
    const isFreeTierLimited = useFreeTierLimitations();

    return (
        <StandupSummaryContainer id={ElementId.SummaryContainer} preventSelection={isFreeTierLimited}>
            <TopItems updates={agenda.blockerSentimentUpdates} />

            {!isTeamTopics2Enabled && teamTopicsItems?.length ? (
                <SummaryContainer>
                    <TeammateNameRow>
                        <BodyLarge>Parking Lot</BodyLarge>

                        {!isAsyncMeeting && (
                            <TeammatesTotalTalkTime>
                                {formatTime(agenda.parkingLotItem?.totalTime ?? 0)}
                            </TeammatesTotalTalkTime>
                        )}
                    </TeammateNameRow>

                    {!isTeamTopics2Enabled && (
                        <ParkingLotItemsByParticipant
                            updates={
                                isTeamTopics2Enabled
                                    ? agenda.YTBItemsWithTeamTopicUpdates
                                    : agenda.YTBItemsWithParkingLotUpdates
                            }
                            isTeamTopics2Enabled={isTeamTopics2Enabled}
                        />
                    )}

                    <Spacing />
                </SummaryContainer>
            ) : null}

            {agenda.YTBItems.length ? (
                agenda.YTBItems.map((item) => {
                    let author: string | undefined;
                    if (item.isParticipantAgendaItem === false && item.standUpUpdate.spinachUserId) {
                        const agendaItem = agenda.findParticipantsYTBAgendaItemProps(item.standUpUpdate.spinachUserId);
                        author = agendaItem?.title;
                    }
                    const timePxWidth = 80;
                    return (
                        <SummaryContainer key={item.id}>
                            <TeammateNameRow style={{ position: 'relative' }}>
                                {isRecentCheckIn ? (
                                    <BodyLarge style={{ fontSize: '12px' }}>
                                        {getFormattedDate(agenda.startedAt!)}
                                    </BodyLarge>
                                ) : (
                                    <>
                                        <BootstrapTooltip
                                            title={author ? `Author: ${author}` : ''}
                                            placement={'top'}
                                            arrow
                                        >
                                            <Row
                                                style={{
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    alignSelf: 'start',
                                                    wordBreak: 'break-word',
                                                    width: `calc(100% - ${timePxWidth}px)`,
                                                }}
                                            >
                                                <BodyLarge className={withFullStoryMasking()}>{item.title}</BodyLarge>
                                            </Row>
                                        </BootstrapTooltip>
                                        <Column
                                            style={{ width: `${timePxWidth}px`, flexShrink: 0, alignItems: 'flex-end' }}
                                        >
                                            <TeammatesTotalTalkTime>
                                                {formatTime(item.totalTime)}
                                            </TeammatesTotalTalkTime>
                                        </Column>
                                    </>
                                )}
                            </TeammateNameRow>

                            <Column style={{ paddingLeft: '7px' }}>
                                <UpdatesByType item={item} />
                            </Column>

                            <Spacing />
                        </SummaryContainer>
                    );
                })
            ) : (
                <SummaryContainer key={'empty-summary-view'}>
                    <TeammateNameRow style={{ position: 'relative' }}>
                        <BodyRegular>{'There was a scheduled meeting on this day, but no one was there.'}</BodyRegular>
                    </TeammateNameRow>
                </SummaryContainer>
            )}

            {isIcebreakerLockedIn ? (
                <SummaryContainer>
                    <BodyLarge>{icebreakerQuestionPrompt}</BodyLarge>
                    {agenda.YTBItems.map((item) => {
                        if (item.icebreakerUpdates.length) {
                            return (
                                <React.Fragment key={item.id}>
                                    <SummarySubtitle>{item.title}</SummarySubtitle>
                                    {item.icebreakerUpdates.map((update) => (
                                        <ReadOnlyUpdateRow key={update.id} text={update.text} update={update} />
                                    ))}
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </SummaryContainer>
            ) : null}
            {withMood ? <Moods userMoods={currentMeeting.userMoods} meetingStatus={currentMeeting.status} /> : null}
        </StandupSummaryContainer>
    );
}

type SummaryViewProps = Omit<SharedSpinachStandUpProps, 'history'>;

function useRestartAgenda(countdown: number, onRestartAgenda: () => void) {
    useEffect(() => {
        if (countdown < 1) {
            onRestartAgenda();
        }
    }, [countdown]);
}

function SummaryView({
    currentMeeting,
    socket,
    series,
    cooldownProps,
}: SummaryViewProps & {
    currentMeeting: SpinachMeetingProps;
}): JSX.Element {
    const [, setOpenModal] = useGlobalModal();
    const [user, setUser] = useGlobalAuthedUser();
    const { focusedFeature, setDemoModal } = useGlobalDemoState();
    const { routeToDirectExperience } = useGlobalRouting();
    const { isDemoSeries } = useSeriesReality();
    const isPersonaDemo = usePersonaDemo();
    const isInviteViaSlackEnabled = useInviteViaSlackEnablement();

    const spinachParticipant = series.spinachParticipant;
    const [isToastOpen, setIsToastOpen] = useState(false);
    const { bypassCooldown, cooldown } = cooldownProps;

    const { isDiscussionReactionVisible, reaction, triggeringSpinachUserId } = useDiscussionReactions(socket);

    useLandingAnalytic(ClientEventType.SummaryViewPageShown, { SeriesId: series.slug });

    const { notificationCode, notificationMessage } = useLiveNotifications(socket, 'SLACK');

    const { isNotificationToastOpen: shouldShowModal, setIsNotificationToastOpen: setInactivityModalOpen } =
        useLiveNotifications(socket, 'INACTIVITY');

    const { outputCode } = series.currentMeeting;
    const [copyButtonBackgroundColor, setCopyButtonBackgroundColor] = useState<string>('unset');

    const containerStyleBottom = !isWebPlatform() ? '130px' : '80px';

    const agendaNavigationRequest = createWebsocketPayload<AgendaRestartingRequest>({
        spinachUserId: spinachParticipant.spinachUserId,
        meetingId: series.currentMeeting.id,
        seriesSlug: series.slug,
        itemId: 'SummaryView',
    });

    useEffect(() => {
        window.Intercom?.('trackEvent', ClientEventType.SummaryViewPageShown, {
            ...user.toUserIdentityPayload(),
            Platform: getClientPlatform(),
            user_id: user.spinachUserId,
        });
    }, []);

    useEffect(() => {
        async function exec() {
            if (series.isDemo) {
                const userRes = await patchUser({
                    metadata: {
                        practiceRoundsComplete: (user.metadata.practiceRoundsComplete ?? 0) + 1,
                    },
                });

                if (userRes.user) {
                    setUser(userRes.user);
                }
            }
        }
        exec();
    }, []);

    useInactivityModalEmitter(series, socket, shouldShowModal);

    const [secondsLeftUntilRestart] = useCountdownTimer(shouldShowModal, RESTART_TIMER_IN_SECONDS);

    const secondsLeftInMinute = secondsLeftUntilRestart % 60;
    const minutesLeftUntilRestart = Math.floor(secondsLeftUntilRestart / 60);
    const minutesLeftUntilRestartDisplay =
        minutesLeftUntilRestart < 10 ? `0${minutesLeftUntilRestart}` : minutesLeftUntilRestart;
    const secondsLeftInMinuteDisplay = secondsLeftInMinute < 10 ? `0${secondsLeftInMinute}` : secondsLeftInMinute;
    const countdownDisplay = `${minutesLeftUntilRestartDisplay}:${secondsLeftInMinuteDisplay}`;

    const { completePracticeRound } = usePracticeRoundCompletion();

    const onCopySummary = async (outputCode?: NotificationCode) => {
        const richSummary = formatSummaryHTML(currentMeeting, series);
        copyTextToClipboard(richSummary);
        setIsToastOpen(true);

        const summaryEvent: ClientExperienceEvent = {
            eventType: ClientEventType.CopySummaryClick,
            payload: {
                ...user.toUserIdentityPayload(),
                UserRole: spinachParticipant.role,
                SlackOutputStatus: outputCode,
                MeetingID: currentMeeting.id,
            },
        };

        await postExperienceEvent(summaryEvent);
    };

    const restartMeeting = async () => {
        bypassCooldown();

        if (series.isDemo) {
            await completePracticeRound();
        } else {
            // this is triggered automatically when user leaves practice round, so not needed above
            socket.emit(ClientSocketEvent.AgendaRestarting, agendaNavigationRequest);
        }
    };

    useRestartAgenda(secondsLeftUntilRestart, restartMeeting);

    const onViewSummaryClick = () => {
        const payload = createWebsocketPayload<MeetingInactiveRequest>({
            seriesSlug: series.slug,
            spinachUserId: spinachParticipant.spinachUserId,
            meetingId: series.currentMeeting.id,
        });
        socket.emit(ClientSocketEvent.TriggerRemoveMeetingInactiveModal, payload);
    };

    const isNextButtonHighlightedForDemo = focusedFeature === ElementId.BackToLobby && isDemoSeries;

    return (
        <>
            <StandUpHeader
                style={{ position: 'relative' }}
                title={
                    <Row style={{ alignItems: 'center' }}>
                        Summary
                        <PulsingHint
                            pulseStyles={{
                                marginLeft: '65px',
                            }}
                            isVisible={series.isDemo && !isPersonaDemo}
                        />
                        {isNextButtonHighlightedForDemo ? (
                            <OverlayHint
                                style={{
                                    marginLeft: '87px',
                                    borderRadius: '50px',
                                    boxShadow: 'box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.01)',
                                }}
                                idOfElement={ElementId.CopySummaryOnSummaryView}
                            />
                        ) : (
                            <></>
                        )}
                        {series.isDemo ? (
                            <Tooltip title="Copy Summary" placement="top">
                                <CopyContentIcon
                                    id={ElementId.CopySummaryOnSummaryView}
                                    aria-label="copy summary button"
                                    onMouseEnter={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                                    onMouseLeave={() => setCopyButtonBackgroundColor('unset')}
                                    onMouseDown={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayDark)}
                                    onMouseUp={() => setCopyButtonBackgroundColor(lightTheme.neutrals.grayLight)}
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: '5px',
                                        backgroundColor: copyButtonBackgroundColor,
                                        borderRadius: '8px',
                                    }}
                                    onClick={() => onCopySummary(outputCode)}
                                />
                            </Tooltip>
                        ) : (
                            <SendOptions
                                parentId={ElementId.SummaryView}
                                onCopyClick={() => onCopySummary(outputCode)}
                                style={{ scale: '0.8' }}
                            />
                        )}
                    </Row>
                }
                id="summary-title"
            />

            {isPersonaDemo ? (
                <PersonaDemoSlackNotificationContainer>
                    <SlackNotification src={SlackNotificationSrc} />
                </PersonaDemoSlackNotificationContainer>
            ) : null}

            <DiscussionReaction
                isDiscussionReactionVisible={isDiscussionReactionVisible}
                triggeringSpinachUserId={triggeringSpinachUserId}
                reaction={reaction}
            />

            {shouldShowModal ? (
                <Modal
                    open={shouldShowModal}
                    onClose={() => {
                        setInactivityModalOpen(false);
                        onViewSummaryClick();
                    }}
                >
                    <Content>
                        <Spacer style={{ height: '25px' }} />
                        <CloseButton
                            onClick={() => {
                                setInactivityModalOpen(false);
                                onViewSummaryClick();
                            }}
                        />
                        <CenteredContent>
                            <BodyLarge>Looks like this meeting has ended.</BodyLarge>
                            <BodyRegular>Would you like to go back to the lobby?</BodyRegular>
                            <BodySubtitle>{`Going back to the lobby in ${countdownDisplay}`}</BodySubtitle>
                            <PrimaryButton title={'Back To Lobby'} onClick={restartMeeting} />
                            <CenteredRow>
                                <SecondaryButton
                                    style={{ justifyContent: 'center' }}
                                    title={'View summary'}
                                    onClick={() => {
                                        setInactivityModalOpen(false);
                                        onViewSummaryClick();
                                    }}
                                />
                            </CenteredRow>
                        </CenteredContent>
                    </Content>
                </Modal>
            ) : null}

            <MeetingCompleteMessage notificationMessage={notificationMessage} notificationCode={notificationCode} />

            <ScrollArea useGrayShading={true} stretch={true}>
                <StandupSummary currentMeeting={series.currentMeeting} />
            </ScrollArea>

            <Spacing factor={2 / 3} />

            <StandUpFooter>
                <PulsingHint
                    size={25}
                    pulseStyles={{
                        marginTop: '35px',
                    }}
                    isVisible={series.isDemo}
                />
                {isNextButtonHighlightedForDemo ? (
                    <OverlayHint
                        style={{
                            marginTop: '-7px',
                            borderRadius: '50px',
                            boxShadow: 'box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.01)',
                        }}
                        extraWidth={15}
                        extraHeight={15}
                        idOfElement={ElementId.BackToLobby}
                    />
                ) : (
                    <></>
                )}
                <PrimaryButton
                    id={ElementId.BackToLobby}
                    title={series.isDemo ? 'Finish' : 'Back to Lobby'}
                    fadeInDuration={series.isDemo ? undefined : cooldown}
                    onClick={restartMeeting}
                />

                <Notification
                    isOpen={isToastOpen}
                    containerStyle={{ bottom: containerStyleBottom }}
                    onClose={() => setIsToastOpen(false)}
                    message="Summary copied!"
                    icon={
                        <CheckCircleIcon
                            style={{ color: lightTheme.neutrals.white }}
                            htmlColor={lightTheme.neutrals.white}
                        />
                    }
                />
            </StandUpFooter>

            <DiscussionReactionButtons
                isDiscussionReactionVisible={isDiscussionReactionVisible}
                seriesSlug={series.slug}
                socket={socket}
            />
        </>
    );
}

export default SummaryView;
