import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { v4 } from 'uuid';

import { SpinachInsight, SpinachInsightKind } from '@spinach-shared/types';

import { useGlobalFacilitation } from '../../../hooks';
import { BodyRegular, lightTheme } from '../../../styles';
import { withFullStoryMasking } from '../../../utils';
import { Column, Row } from '../../common';
import './animation.css';

export type SpinachInsightProps = {
    highlightColor?: string;
    icon?: JSX.Element;
    kind?: string;
    title: string;
    context?: string;
    className?: string;
    onClick?: () => Promise<void>;
};

export function useSpinachBlockerOnClick() {
    const [, setFacilitation] = useGlobalFacilitation();

    return async (title: string, context?: string) => {
        setFacilitation((facilitation) => ({
            ...facilitation,
            discussionTopics: [
                ...facilitation.discussionTopics.filter((d) => !!d.title),
                {
                    notes: context ? [context] : [],
                    startStops: [],
                    viewId: v4(),
                    title: title,
                },
                ...facilitation.discussionTopics.filter((d) => !d.title),
            ],
        }));
    };
}

function getLabelFromKind(kind: string): string {
    switch (kind) {
        case SpinachInsightKind.Blocker:
            return 'Blocker';
        case SpinachInsightKind.HeySpinach:
            return 'Hey Spinach';
        case SpinachInsightKind.TimeCheck:
            return 'Time check';
        case SpinachInsightKind.MeetingTip:
            return 'Meeting tip';
        default:
            return kind;
    }
}

export function GenericSpinachInsight({
    highlightColor,
    icon,
    kind,
    title,
    context,
    onClick,
    className,
}: SpinachInsightProps): JSX.Element {
    return (
        <Column
            className={className}
            style={{
                maxHeight: '500px',
                backgroundColor: '#fff',
                borderRadius: '4px',
                marginBottom: '15px',
                boxShadow: '0px 4px 46px 0px #0000001C',
            }}
        >
            {highlightColor ? (
                <Row style={{ height: '2px', width: '100%', backgroundColor: highlightColor }}></Row>
            ) : null}

            <Row style={{ padding: '10px' }}>
                <Row style={{ width: 'calc(100% - 20px)' }}>
                    {icon ? (
                        <Column style={{ width: '40px', marginLeft: '-10px', alignItems: 'center' }}>{icon}</Column>
                    ) : null}

                    <Column>
                        {kind ? (
                            <Row style={{ marginBottom: '5px' }}>
                                <BodyRegular style={{ fontSize: '11px' }}>
                                    <b>{getLabelFromKind(kind)}</b>
                                </BodyRegular>
                            </Row>
                        ) : null}

                        <Row>
                            <b className={withFullStoryMasking()}>{title}</b>
                        </Row>

                        {context ? (
                            <Row style={{ marginTop: '5px' }}>
                                <BodyRegular className={withFullStoryMasking()}>{context}</BodyRegular>
                            </Row>
                        ) : null}
                    </Column>

                    {onClick ? (
                        <Column
                            style={{
                                width: '40px',
                                borderLeft: '1px solid lightgray',
                                justifyContent: 'center',
                                marginRight: '-10px',
                                marginLeft: '5px',
                            }}
                        >
                            <IconButton onClick={onClick} size="small">
                                <AddCircleIcon fontSize="small" htmlColor={lightTheme.primary.midnight} />
                            </IconButton>
                        </Column>
                    ) : null}
                </Row>
            </Row>
        </Column>
    );
}
