import moment from 'moment';
import { useEffect } from 'react';

import { ClientEventType, SpinachInsightKind } from '@spinach-shared/types';

import { postExperienceEvent } from '../apis';
import { useGlobalNullableBotIdPair, useGlobalNullableFacilitation } from './useGlobalFacilitation';
import { useGlobalUser } from './useGlobalUser';
import { useGlobalNullableLiveSeries } from './useLiveSeries';
import { useGlobalNullableStoredSeries } from './useSeriesData';

/**
 * @returns a `track(eventType, extraData)` function that auto-populates userIdentity metadata
 * Reduces boilerplate of common analytics needs on the front-end
 * Usage: const track = useExperienceTracking();
 * track(ClientEventType.SupportTabClicked)
 * track(ClientEventType.SupportTabClicked, { Count: count });
 *
 * can be awaited if desired.
 */
export function useExperienceTracking(): (eventType: ClientEventType, extraData?: any) => Promise<void> {
    const [user] = useGlobalUser();
    const [storedSeries] = useGlobalNullableStoredSeries();
    const [liveSeries] = useGlobalNullableLiveSeries();

    return async (eventType: ClientEventType, extraData: any = {}) => {
        await postExperienceEvent({
            eventType,
            payload: {
                ...user?.toUserIdentityPayload(),
                SeriesId: storedSeries?.slug,
                IsAISeries: storedSeries?.isStandupScribeSeries,
                ...extraData,
                CurrentDemoGuide: liveSeries?.currentDemoGuide,
                CurrentURL: location.href,
                BrowserWidth: window.innerWidth,
                BrowserHeight: window.innerHeight,
                DemoVersion: user?.metadata.demoVersion,
                ...(user?.aiSubscriptionMetadata || {}),
            },
        });
    };
}

export function useLandingAnalytic(eventType: ClientEventType, extraData?: any) {
    const track = useExperienceTracking();

    useEffect(() => {
        track(eventType, extraData);
    }, []);
}

export function useFacilitationTracking(): (eventType: ClientEventType, extraData?: any) => Promise<void> {
    const [facilitation] = useGlobalNullableFacilitation();
    const [botIdPair] = useGlobalNullableBotIdPair();
    const [user] = useGlobalUser();
    const [storedSeries] = useGlobalNullableStoredSeries();

    return async (eventType: ClientEventType, extraData: any = {}) => {
        await postExperienceEvent({
            eventType,
            payload: {
                ...user?.toUserIdentityPayload(),
                SeriesId: storedSeries?.slug,
                IsAISeries: storedSeries?.isStandupScribeSeries,
                ...extraData,
                CurrentURL: location.href,
                BrowserWidth: window.innerWidth,
                BrowserHeight: window.innerHeight,
                HasAgendaStarted: !!facilitation?.actualMeetingTime?.startTime,
                BotId: botIdPair?.botId,
                PreviousBotId: botIdPair?.previousBotId,
                ParticipantCount: facilitation?.participants.length,
                DiscussionTopicsCount: facilitation?.discussionTopics.length,
                InMeetingInsightsCount: facilitation?.inMeetingInsights.length,
                InMeetingBlockerInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.Blocker
                ),
                InMeetingHeySpinachInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.HeySpinach
                ),
                InMeetingTimeCheckInsightsCount: facilitation?.inMeetingInsights.filter(
                    (i) => i.kind === SpinachInsightKind.TimeCheck
                ),
                AiGapInsightsCount: facilitation?.roundtableWrapup.insights.length,
                ScheduledMeetingDuration: moment(facilitation?.projectedMeetingTime.endTime).diff(
                    moment(facilitation?.projectedMeetingTime.startTime),
                    'minutes'
                ),
                CurrentViewId: facilitation?.currentViewId,
                IsIcebreakerEnabled: !!facilitation?.icebreaker.enabled,
                IcebreakersShuffledCount: facilitation?.icebreaker.usedIndexes.length,
                ...(user?.aiSubscriptionMetadata || {}),
            },
        });
    };
}
