import { IClientUser, SpinachAPIPath, UpsertNotionPageRequest } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postNotionUserParentPage(
    pageId: string,
    pageName: string
): Promise<{ user: IClientUser | null } | undefined> {
    const request: UpsertNotionPageRequest = {
        pageId,
        pageName,
    };

    return await postSpinachAPI<{ user: IClientUser | null } | undefined>(SpinachAPIPath.NotionUserParentPage, request);
}
