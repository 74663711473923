import { useRecoilState } from 'recoil';

import { TicketSource } from '@spinach-shared/types';

import { GlobalModal, GlobalModalFrom, GlobalModalState, atomModalState } from '../atoms';

type GlobalModalOptions = {
    location?: string;
    from?: GlobalModalFrom;
    metadata?: {
        botId: string | null;
        seriesId: string | null;
        teamTicketIntegration: TicketSource;
    };
};

export type GlobalModalSetter = (modal: GlobalModal | null, options?: GlobalModalOptions) => void;

/**
 *
 * @returns a ref to the current open modal or null if none is open, and a setter to this state
 */
export function useGlobalModal(): [GlobalModal | null, GlobalModalSetter] {
    const [modalState, setModalState] = useRecoilState(atomModalState);

    function setModal(modal: GlobalModal | null, options?: GlobalModalOptions) {
        setModalState({
            modal,
            ...options,
        });
    }

    return [modalState.modal, setModal];
}

/**
 *
 * @returns all state related to global modals. Ideally, this gets baked into useGlobalModal in a separate refactor PR
 */
export function useGlobalModalState(): GlobalModalState {
    const [modalState] = useRecoilState(atomModalState);

    return modalState;
}
