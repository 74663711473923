import styled from 'styled-components';

import { FacilitatedViewId, icebreakers } from '@spinach-shared/constants';
import { FacilitatedStartStops } from '@spinach-shared/models';
import {
    BotIdPair,
    DiscussionTopic,
    FacilitatedIcebreaker,
    FacilitatedParticipant,
    FacilitatedStartStop,
    Facilitation,
    RoundtableWrapup,
    SpinachAPIPath,
} from '@spinach-shared/types';
import { getUniques } from '@spinach-shared/utils';

import { postSpinachAPI } from '../../../../apis';
import { HeaderThree } from '../../../../styles';
import { ScrollArea } from '../../../stand-up';

export const Header = styled(HeaderThree)`
    text-align: left;
    user-select: none;
`;

export const Padding = styled.div`
    padding: 20px;
    height: calc(100% - 40px);
`;

export const ScrollView = styled(ScrollArea)`
    background-color: unset;
    background: unset;
`;

export function getNextIcebreakerIndex(icebreaker: FacilitatedIcebreaker): {
    currentIndex: number;
    usedIndexes: number[];
} {
    let usedIndexes = getUniques<number>([...icebreaker.usedIndexes]);

    if (typeof icebreaker.currentIndex === 'number') {
        usedIndexes.push(icebreaker.currentIndex);
    }

    if (usedIndexes.length === icebreakers.length) {
        usedIndexes = [];
    }

    const allIndexes = icebreakers.map((_, i) => i);
    const availableIndexes = allIndexes.filter((i) => !usedIndexes.includes(i));

    const randomIndex = Math.floor(Math.random() * availableIndexes.length);

    const currentIndex = availableIndexes[randomIndex];
    return { currentIndex, usedIndexes };
}

export function getAgendaIds(facilitation: Facilitation): string[] {
    const { discussionTopics, participants, icebreaker } = facilitation;

    if (icebreaker.enabled) {
        const ids = [
            FacilitatedViewId.MeetingPrep,
            FacilitatedViewId.Icebreaker,
            ...participants.map(({ viewId }) => viewId),
            FacilitatedViewId.GapAnalysis,
            ...discussionTopics.filter((t) => !!t.title).map(({ viewId }) => viewId),
            FacilitatedViewId.Finale,
        ];

        return ids;
    } else {
        const ids = [
            FacilitatedViewId.MeetingPrep,
            ...participants.map(({ viewId }) => viewId),
            FacilitatedViewId.GapAnalysis,
            ...discussionTopics.filter((t) => !!t.title).map(({ viewId }) => viewId),
            FacilitatedViewId.Finale,
        ];

        return ids;
    }
}

export type TimedView =
    | { viewId: string; startStops: FacilitatedStartStop[] }
    | FacilitatedParticipant
    | DiscussionTopic;

export function getTimedViews(facilitation: Facilitation): TimedView[] {
    return [...facilitation.participants, ...facilitation.discussionTopics, facilitation.roundtableWrapup];
}

export function getCurrentTimedView(facilitation: Facilitation): TimedView | undefined {
    return getTimedViews(facilitation).find((p) => p.viewId === facilitation.currentViewId);
}

export type UpdatedFacilitationNavigation = Pick<
    Facilitation,
    'participants' | 'discussionTopics' | 'roundtableWrapup' | 'currentViewId'
>;

export function getNextAgendaFacilitation(
    facilitation: Facilitation,
    botIdPair: BotIdPair,
    starting?: boolean
): UpdatedFacilitationNavigation {
    const { currentViewId } = facilitation;

    const agendaIds = getAgendaIds(facilitation);

    const agendaIndex = agendaIds.indexOf(currentViewId);

    if (agendaIndex === -1) {
        console.error('no current agenda index found');
        return facilitation;
    }

    if (agendaIndex >= agendaIds.length) {
        console.error('exceeding agenda list');
        return facilitation;
    }

    const nextAgendaId = agendaIds[agendaIndex + 1];

    return getUpdatedTimesAndCurrentView(facilitation, nextAgendaId, botIdPair, starting);
}

export function getPreviousAgendaFacilitation(
    facilitation: Facilitation,
    botIdPair: BotIdPair
): UpdatedFacilitationNavigation {
    const { currentViewId } = facilitation;

    const agendaIds = getAgendaIds(facilitation);

    const agendaIndex = agendaIds.indexOf(currentViewId);

    if (agendaIndex === -1) {
        console.error('no current agenda index found');
        return facilitation;
    }

    if (agendaIndex === 0) {
        console.error('exceeding agenda list backwards');
        return facilitation;
    }

    const prevAgendaId = agendaIds[agendaIndex - 1];

    return getUpdatedTimesAndCurrentView(facilitation, prevAgendaId, botIdPair);
}

export function getUpdatedTimesAndCurrentView(
    facilitation: Facilitation,
    upcomingViewId: string,
    botIdPair: BotIdPair,
    starting?: boolean
): UpdatedFacilitationNavigation {
    const { currentViewId } = facilitation;
    const views = getTimedViews(facilitation);

    let currentView: TimedView | undefined = views.find(({ viewId }) => viewId === currentViewId);
    let upcomingView: TimedView | undefined = views.find(({ viewId }) => viewId === upcomingViewId);

    if (facilitation.actualMeetingTime?.startTime || starting) {
        if (currentView && currentViewId !== upcomingViewId) {
            const updatedStartStops = new FacilitatedStartStops([...currentView.startStops]);
            updatedStartStops.finishLatest(new Date().toISOString());
            currentView = {
                ...currentView,
                startStops: updatedStartStops.toJSON(),
            };

            const MIN_SECONDS_TO_TRIGGER_GAP_ANALYSIS = 10;
            if (
                'displayName' in currentView &&
                updatedStartStops.segments.length === 1 &&
                updatedStartStops.totalTime >= MIN_SECONDS_TO_TRIGGER_GAP_ANALYSIS
            ) {
                // call the post event

                postSpinachAPI(SpinachAPIPath.FacilitationEvent, {
                    botId: botIdPair.botId,
                    kind: 'person-update-complete',
                    userDisplayName: currentView.displayName,
                    userEmail: currentView.email,
                    previousBotId: botIdPair.previousBotId,
                });
            }
        }

        if (upcomingView) {
            const updatedStartStops = new FacilitatedStartStops([...upcomingView.startStops]);
            updatedStartStops.startNewSegment(new Date().toISOString());
            upcomingView = {
                ...upcomingView,
                startStops: updatedStartStops.toJSON(),
            };
        }
    }

    // finish current item startstop and start next item start stop

    let roundtableWrapup = facilitation.roundtableWrapup;
    if (currentViewId === FacilitatedViewId.GapAnalysis) {
        roundtableWrapup = currentView as RoundtableWrapup;
    }
    if (upcomingViewId === FacilitatedViewId.GapAnalysis) {
        roundtableWrapup = upcomingView as RoundtableWrapup;
    }

    const updatedState: UpdatedFacilitationNavigation = {
        participants: facilitation.participants.map((participant) => {
            if (participant.viewId === upcomingViewId) {
                return upcomingView as FacilitatedParticipant;
            }

            if (participant.viewId === currentViewId) {
                return currentView as FacilitatedParticipant;
            }

            return participant;
        }),
        discussionTopics: facilitation.discussionTopics.map((discussionTopic) => {
            if (discussionTopic.viewId === upcomingViewId) {
                return upcomingView as DiscussionTopic;
            }

            if (discussionTopic.viewId === currentViewId) {
                return currentView as DiscussionTopic;
            }

            return discussionTopic;
        }),
        roundtableWrapup,
        currentViewId: upcomingViewId,
    };
    return updatedState;
}
