import { IconButton } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { CSSProperties } from 'react';

export function RemoveIntegrationButton({
    onClick,
    style = {},
    isVisible,
}: {
    onClick: () => void;
    style?: CSSProperties;
    isVisible?: boolean;
}): JSX.Element {
    if (!isVisible) {
        return <></>;
    }

    return (
        <IconButton size="small" onClick={onClick} style={style}>
            <Cancel />
        </IconButton>
    );
}
