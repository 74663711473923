import { MicrosoftVerificationResponse, SpinachAPIPath } from '@spinach-shared/types';

import { postSpinachAPI } from '.';

export async function postHandleMicrosoftLoginWithCode(
    code: string,
    state?: string
): Promise<MicrosoftVerificationResponse> {
    try {
        const response = await postSpinachAPI<MicrosoftVerificationResponse>(
            SpinachAPIPath.MicrosoftHandleLoginWithCode,
            { code, state }
        );
        if (!response) {
            return { user: null };
        }
        return response;
    } catch {
        return { user: null };
    }
}
