import moment from 'moment';

import { MILLIS_IN_DAY } from '@spinach-shared/constants';
import {
    AiMeetingCounterKey,
    AiMeetingCounters,
    CalendarEvent,
    ClientUserSlackSettings,
    DismissableHints,
    ExperimentKey,
    FeatureFlagValue,
    FeatureToggle,
    IClientUser,
    ISOString,
    IntegrationCode,
    IntegrationLabelMap,
    ScribeMeetingType,
    SendCalendarSuggestionsCohort,
    TICKET_SOURCE_MAP,
    TicketSource,
    UUID,
    UserIdentity,
    UserIdentityPayload,
    UserIntegrationSettings,
    UserMetadata,
    UserSeriesMetadata,
} from '@spinach-shared/types';
import { convertHyphenCaseToTitleCase, getLowercaseDomainFromEmail, isDemoSeries } from '@spinach-shared/utils';

export enum SubscriptionProperty {
    /** @NOTE this is actually used to indicate, from a comms side, whether a user is getting pro features */
    HasProFeaturesOld = 'isPaidAccount',
    HasProFeatures = 'HasProFeatures',
    IsOnPayingAccount = 'IsOnPayingAccount',
    HasBeenOnTrial = 'HasBeenOnTrial',
    IsOnLiveTrial = 'IsOnLiveTrial',
}

export class ClientUser {
    spinachUserId: UUID;
    email: string;
    zoomUserId?: string;
    googleId?: string;
    microsoftId?: string;
    metadata: UserMetadata;
    seriesMetadataList: UserSeriesMetadata[];
    integrationSettings?: UserIntegrationSettings;
    private _featureToggles?: Record<FeatureToggle, FeatureFlagValue>;

    constructor(props: IClientUser) {
        this.spinachUserId = props._id;
        this.email = props.email;
        this.seriesMetadataList = props.seriesMetadataList ?? [];
        this.integrationSettings = props.integrationSettings;
        this.zoomUserId = props.zoomUserId;
        this.googleId = props.googleId;
        this.microsoftId = props.microsoftId;
        this._featureToggles = props.featureToggles;

        this.metadata = { ...props.metadata } ?? {};
        this.metadata.preferredName = props.metadata?.preferredName ?? props.preferredName;
        this.metadata.companyName = props.metadata?.companyName;
        this.metadata.lastLoggedOn = props.metadata?.lastLoggedOn ?? props.lastLoggedOn;
        this.metadata.lastEditedOn = props.metadata?.lastEditedOn ?? props.lastEditedOn;
        this.metadata.intercomHash = props.metadata?.intercomHash ?? props.intercomHash;
        this.metadata.createdOn = props.metadata?.createdOn ?? props.createdOn;
    }

    get rootDomain(): string {
        return this.metadata.rootDomain || getLowercaseDomainFromEmail(this.email);
    }

    get preferredName(): string {
        return this.metadata.firstName?.trim() && this.metadata.lastName?.trim()
            ? `${this.metadata.firstName.trim()} ${this.metadata.lastName.trim()}`
            : this.metadata.preferredName ?? '';
    }

    get firstName(): string {
        return this.metadata.firstName?.trim() ?? this.metadata.preferredName?.split(' ')[0]?.trim() ?? '';
    }

    get lastName(): string {
        return this.metadata.lastName?.trim() ?? this.metadata.preferredName?.split(' ')[1]?.trim() ?? '';
    }

    get initials(): string {
        return `${this.firstName.charAt(0)}${this.lastName.charAt(0)}`;
    }

    get companyName(): string {
        return this.metadata.companyName ?? '';
    }

    get howDidYouHear(): string {
        return this.metadata.howDidYouHear ?? '';
    }

    get howDidYouHearOther(): string {
        return this.metadata.howDidYouHearOther ?? '';
    }

    get integrationsVideo(): string {
        return this.metadata.integrationsVideo ?? '';
    }

    get videoTool(): string {
        if (this.metadata.integrationsVideo && this.metadata.integrationsVideo !== IntegrationCode.Other) {
            return IntegrationLabelMap[this.metadata.integrationsVideo];
        }

        return 'your video tool';
    }

    get dismissedHints(): DismissableHints[] {
        return this.metadata.dismissedHints ?? [];
    }

    get featureToggles(): Record<FeatureToggle, FeatureFlagValue> {
        return (this._featureToggles || {}) as Record<FeatureToggle, FeatureFlagValue>;
    }

    get isActionItemTicketCreationEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.IsActionItemTicketCreationEnabled];
    }

    get actionItemCreationEnabledTicketSources(): TicketSource[] {
        const enabledTicketSources = this.featureToggles[FeatureToggle.ActionItemCreationEnabledTicketSources];
        if (!enabledTicketSources || !Array.isArray(enabledTicketSources) || !enabledTicketSources.length) {
            return [];
        }
        const validatedEnabledTicketSources = enabledTicketSources.filter((source) =>
            Object.values(TICKET_SOURCE_MAP).includes(source)
        );

        if (!validatedEnabledTicketSources.length) {
            return [];
        }
        return validatedEnabledTicketSources;
    }

    get isUserAuthedWithAValidTicketCreationSource(): boolean {
        if (!this.isAuthedForAnyTicketProvider) {
            return false;
        }
        const enabledTicketSources = this.actionItemCreationEnabledTicketSources;
        const isEnabledForOne = this.authedTicketSources.some((source) => {
            if (enabledTicketSources.includes(source)) {
                if (source === TICKET_SOURCE_MAP.Jira) {
                    return this.integrationSettings?.jiraSettings?.hasCreateScopes;
                }
                return true;
            }
            return false;
        });
        return isEnabledForOne;
    }

    get authedTicketSources(): TicketSource[] {
        return Object.values(TICKET_SOURCE_MAP).filter((source) => this.isAuthedForTicketSource(source));
    }

    get isEnabledForEditSummary(): boolean {
        return !!this.featureToggles[FeatureToggle.EditSummaryMVP] || !!this.metadata.isEditingAiEmailsByDefault;
    }

    get isEnabledForChunkBasedBlockers(): boolean {
        return !!this.featureToggles[FeatureToggle.ChunkBasedBlockers];
    }

    get isEnabledForChainedActionItemGrouping(): boolean {
        return !!this.featureToggles[FeatureToggle.ChainedActionItemGrouping];
    }

    get isEnabledForChainedBlockerGrouping(): boolean {
        return !!this.featureToggles[FeatureToggle.ChainedBlockerGrouping];
    }

    get isEnabledForFullTranscriptPlansAndProgress(): boolean {
        return !!this.featureToggles[FeatureToggle.FullTranscriptPlansAndProgress];
    }

    get isEnabledForPostGenerationPerPersonTicketMatching(): boolean {
        return !!this.featureToggles[FeatureToggle.PostGenerationPerPersonTicketMatching];
    }

    get isIndividualEmailEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SendIndividualEmails];
    }

    get maxAttendeesForPerPersonJiraTicketFetch(): number {
        return this.featureToggles[FeatureToggle.PerPersonJiraTicketFetchMaxAttendees] as number;
    }

    get isEnabledForPerPersonJiraTicketFetch(): boolean {
        return !!this.featureToggles[FeatureToggle.PerPersonJiraTicketFetch];
    }

    get isFreeTierLimited(): boolean {
        return !!this.featureToggles[FeatureToggle.FreeTierLimited];
    }

    get isAiQualityLimited(): boolean {
        return !!this.featureToggles[FeatureToggle.LimitAIQuality];
    }

    get isCalendarSuggestionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.CalendarSuggestions];
    }

    get calendarSuggestionCohort(): SendCalendarSuggestionsCohort {
        return this.featureToggles[FeatureToggle.CalendarSuggestions] as SendCalendarSuggestionsCohort;
    }

    get isPublicFollowupsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackPublicFollowups];
    }

    get isSlackPrivateFollowUpsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackDMFollowUpsEnabled];
    }

    get isPublicIssueActionsEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.SlackPublicIssueActions];
    }

    get useEnhancedDiarization(): boolean {
        return !!this.featureToggles[FeatureToggle.EnhancedDiarizationEnabled];
    }

    get hasProFeatures(): boolean {
        return !!this.metadata.isPaidAi || !!this.featureToggles[FeatureToggle.ProAccount] || this.isOnLiveReverseTrial;
    }

    get isPreMeetingNotificationEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.PreMeetingFacilitationNotification];
    }

    get aiSubscriptionMetadata() {
        return {
            [SubscriptionProperty.HasProFeaturesOld]: this.hasProFeatures,
            [SubscriptionProperty.HasProFeatures]: this.hasProFeatures,
            [SubscriptionProperty.IsOnPayingAccount]:
                !!this.metadata.isPaidAi || !!this.featureToggles[FeatureToggle.ProAccount],
            [SubscriptionProperty.HasBeenOnTrial]: !!this.metadata.accountReverseTrialStartDate,
            [SubscriptionProperty.IsOnLiveTrial]: !!this.isOnLiveReverseTrial,
        };
    }

    /** @NOTE - only to be used in the rarer cases where its important to distinguish between isPaidAccount and Trialing, like a Trial UX change */
    get isOnLiveReverseTrial(): boolean {
        const daysLeft = this.reverseTrialDaysLeft;

        const isPayingUser = !!this.metadata.isPaidAi || !!this.featureToggles[FeatureToggle.ProAccount];

        return daysLeft > 0 && !!this.featureToggles[FeatureToggle.ReverseTrialAiEnabled] && !isPayingUser;
    }

    get reverseTrialDaysLeft(): number {
        const trialStart = this.metadata.accountReverseTrialStartDate;
        const trialLength = (this.featureToggles[FeatureToggle.ReverseTrialLengthInDays] || 0) as number;
        let daysLeft = 0;

        if (!!trialStart && !!trialLength) {
            const trialEnd = moment(trialStart).add(trialLength, 'days').valueOf();
            const now = moment().valueOf();
            const timeLeftInMs = trialEnd - now;

            // show at least 1 day left
            if (timeLeftInMs > 0) {
                daysLeft = Math.ceil(timeLeftInMs / MILLIS_IN_DAY);
                // ensure ceil does not exceed the actual trial length
                if (daysLeft > trialLength) {
                    daysLeft = trialLength;
                }
            } else {
                return 0;
            }
        }

        return daysLeft;
    }

    get isRetroSummaryEmbeddingEnabled(): boolean {
        return !!this.featureToggles[FeatureToggle.RetroSummaryEmbedding];
    }

    get selectedSlackAsMessagingIntegraton(): boolean {
        return this.integrationsMessaging === IntegrationCode.Slack;
    }

    get integrationsMessaging(): string {
        return this.metadata.integrationsMessaging ?? '';
    }

    get integrationsProjectMgmt(): string[] {
        return this.metadata.integrationsProjectMgmt ?? [];
    }

    get integrationsVideoOther(): string {
        return this.metadata.integrationsVideoOther ?? '';
    }

    get integrationsMessagingOther(): string {
        return this.metadata.integrationsMessagingOther ?? '';
    }

    get integrationsProjectMgmtOther(): string {
        return this.metadata.integrationsProjectMgmtOther ?? '';
    }

    get createdOn(): ISOString {
        return this.metadata.createdOn!;
    }

    get realSeries(): UserSeriesMetadata[] {
        return this.seriesMetadataList.filter((series) => !isDemoSeries(series.id));
    }

    get isOnFirstPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 0;
    }

    get isOnSecondPracticeRound(): boolean {
        return this.metadata.practiceRoundsComplete === 1;
    }

    get isAnonymous(): boolean {
        return !!this.metadata.isAnonymousUser;
    }

    get isAuthedForNotion(): boolean {
        return !!this.integrationSettings?.notionSettings?.isAuthed;
    }

    get isAuthedForConfluence(): boolean {
        return !!this.integrationSettings?.confluenceSettings?.isAuthed;
    }

    get isAuthedForJira(): boolean {
        return !!this.integrationSettings?.jiraSettings?.isAuthed;
    }

    get isAuthedForTrello(): boolean {
        return !!this.integrationSettings?.trelloSettings?.isAuthed;
    }

    get isAuthedForAsana(): boolean {
        return !!this.integrationSettings?.asanaSettings?.isAuthed;
    }

    get isAuthedForLinear(): boolean {
        return !!this.integrationSettings?.linearSettings?.isAuthed;
    }

    get isAuthedForClickUp(): boolean {
        return !!this.integrationSettings?.clickUpSettings?.isAuthed;
    }

    /**
     * @NOTE this could be written to be more abstract to
     * follow the integrationSettings type (e.g. `${TicketSource}}Settings`
     * but was not to try and create more readable and stable code
     *
     * This means, however, that when a new ticket source is added that this
     * method should be updated to include it, otherwise the default case
     * will be returned
     *
     * Using the more dynamic approach would also mean that TS would catch this
     * case for us
     * */
    isAuthedForTicketSource(ticketSource: TicketSource): boolean {
        switch (ticketSource) {
            case TICKET_SOURCE_MAP.Jira:
                return this.isAuthedForJira;
            case TICKET_SOURCE_MAP.Linear:
                return this.isAuthedForLinear;
            case TICKET_SOURCE_MAP.ClickUp:
                return this.isAuthedForClickUp;
            case TICKET_SOURCE_MAP.Asana:
                return this.isAuthedForAsana;
            case TICKET_SOURCE_MAP.Trello:
                return this.isAuthedForTrello;
            default:
                return false;
        }
    }

    get isAuthedForAnyTicketProvider(): boolean {
        return (
            !!this.isAuthedForJira ||
            !!this.isAuthedForLinear ||
            !!this.isAuthedForClickUp ||
            !!this.isAuthedForAsana ||
            !!this.isAuthedForTrello
        );
    }

    /** @note We use the bot token for Slack integration, so there is no `isAuthed` value here */
    get isAuthedForSlack(): boolean {
        return !!this.integrationSettings?.slackSettings;
    }

    get isAuthedForGoogleDrive(): boolean {
        return (
            !!this.integrationSettings?.googleSettings?.isAuthed &&
            !!this.integrationSettings?.googleSettings?.isDriveEnabled
        );
    }

    get isAuthedForGoogleCalendar(): boolean {
        return (
            !!this.integrationSettings?.googleSettings?.isAuthed &&
            !!this.integrationSettings?.googleSettings?.isCalendarEnabled
        );
    }

    get isAuthedForMicrosoftCalendar(): boolean {
        return (
            !!this.integrationSettings?.microsoftSettings?.isAuthed &&
            !!this.integrationSettings?.microsoftSettings?.isCalendarEnabled
        );
    }

    get calendarProvider(): 'google' | 'microsoft' | 'unknown' {
        if (this.isAuthedForGoogleCalendar) {
            return 'google';
        } else if (this.isAuthedForMicrosoftCalendar) {
            return 'microsoft';
        } else {
            return 'unknown';
        }
    }

    get isAuthedForAnyCalendar(): boolean {
        return this.isAuthedForGoogleCalendar || this.isAuthedForMicrosoftCalendar;
    }

    get slackSettings(): ClientUserSlackSettings | undefined {
        return this.integrationSettings?.slackSettings;
    }

    get shouldAuthBeforeDemo(): boolean {
        return this.metadata.signupBeforeDemo === true;
    }

    get isDemoing(): boolean {
        return (
            this.metadata.isAnonymousUser === true ||
            (this.shouldAuthBeforeDemo && !this.metadata.isOnboarded && this.metadata.isAnonymousUser === false)
        );
    }

    get isScribeUser(): boolean {
        return !!this.metadata.experimentCodes?.includes(ExperimentKey.StandupScribe);
    }

    get defaultChannelSelection(): { label: string; code: string } | null {
        if (this.isAuthedForSlack && this.slackSettings?.defaultChannelId && this.slackSettings?.defaultChannelName) {
            return { label: this.slackSettings.defaultChannelName, code: this.slackSettings.defaultChannelId };
        }
        return null;
    }

    get totalAiSessionCount(): number {
        return this.metadata.totalAiSessionCount || 0;
    }

    areIntegrationsUpdated(latestIntegrations: UserIntegrationSettings | undefined) {
        const serializedLatest = JSON.stringify(latestIntegrations);
        const serializedPrevious = JSON.stringify(this.integrationSettings);
        return serializedLatest !== serializedPrevious;
    }

    getSeriesById(seriesId: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.id === seriesId);
    }

    getSeriesByName(seriesName: string): UserSeriesMetadata | undefined {
        return this.seriesMetadataList.find((s) => s.name === seriesName);
    }

    getSeriesIdOfOnlySeries(): string | undefined {
        if (this.realSeries.length === 1) {
            return this.realSeries[0].id;
        } else {
            return undefined;
        }
    }

    isUserTheOrganizer(event: CalendarEvent): boolean {
        return event.organizer?.email?.toLocaleLowerCase() === this.email.toLocaleLowerCase();
    }

    // Should we really be passing all this information back and forth?
    toUserIdentityPayload(): UserIdentityPayload {
        return {
            Email: this.email,
            UserId: this.spinachUserId,
            FirstName: this.firstName,
            LastName: this.lastName,
            RootDomain: this.rootDomain,
            Company: this.companyName,
            HowDidYouHearSource: this.howDidYouHear,
            UserName: this.preferredName,
            UserMetadata: this.metadata,
            FeatureToggles: this.featureToggles,
        };
    }

    getUpdatedAiHistory(meetingType: ScribeMeetingType | undefined): AiMeetingCounters {
        const meetingCounters: Required<AiMeetingCounters> = {
            totalAiSessionCount: this.metadata.totalAiSessionCount ?? 0,
        };

        Object.values(ScribeMeetingType).forEach((mtgType) => {
            // turn backlog-grooming into BacklogGrooming and standup into Standup
            const keyPart = convertHyphenCaseToTitleCase(mtgType);

            const key: AiMeetingCounterKey = `totalAi${keyPart}SessionCount`;
            meetingCounters[key] = (this.metadata as Record<string, any>)[key] ?? 0;

            if (meetingType === mtgType && meetingCounters[key] !== undefined) {
                meetingCounters[key]!++;
            }
        });

        /** Should exist, this is making TS happy */
        if (meetingCounters.totalAiSessionCount !== undefined) {
            meetingCounters.totalAiSessionCount++;
        }

        return meetingCounters;
    }

    toUserIdentity(): UserIdentity {
        return {
            userId: this.spinachUserId,
            firstName: this.firstName,
            lastName: this.lastName,
            company: this.companyName,
            rootDomain: this.rootDomain,
            howDidYouHearSource: this.howDidYouHear,
            userName: this.preferredName,
            userEmail: this.email,
            seriesMetadataList: this.seriesMetadataList,
            metadata: this.metadata,
        };
    }

    toIClientUser(): IClientUser {
        return {
            _id: this.spinachUserId,
            email: this.email,
            metadata: this.metadata,
            seriesMetadataList: this.seriesMetadataList,
            preferredName: this.preferredName,
            createdOn: this.metadata.createdOn!,
            zoomUserId: this.zoomUserId,
            lastEditedOn: this.metadata.lastEditedOn,
            lastLoggedOn: this.metadata.lastLoggedOn,
            integrationSettings: this.integrationSettings,
            intercomHash: this.metadata.intercomHash,
            googleId: this.googleId,
            featureToggles: this.featureToggles,
        };
    }
}
