import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MILLIS_IN_SECONDS } from '@spinach-shared/constants';
import { CalendarEvent, ClientEventType } from '@spinach-shared/types';

import { useLandingAnalytic } from '../../../hooks';
import { BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../styles';
import { MeetingsAndSuggestions } from '../../../utils';
import { Column, Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { SelectCalendarEventsForSpinach } from '../ScribeCalendarPage';
import { BackButtonRow, Stationary, StepProps } from './common';

export type SelectEventForMeetingProps = StepProps & {
    isFetchingEvents: boolean;
    onBack: () => void;
    hasError: boolean;
    updatingEvents: string[];
    onEventClick: (event: CalendarEvent) => void;
    data: MeetingsAndSuggestions | undefined;
    onboardingEventsToAddScribeTo: CalendarEvent[];
    onSkip: () => void;
    onOptForManual: () => void;
};

const ErrorMessage = styled.div`
    background: #f6cbcb;
    padding: 16px;
    border-radius: 12px;
`;

export function AddToMeetingsStep({
    onBack,
    onSkip,
    direction,
    onSubmit,
    loadingMessage,
    hasError,
    data,
    onEventClick,
    onboardingEventsToAddScribeTo,
    isFetchingEvents,
    onOptForManual,
    updatingEvents,
}: SelectEventForMeetingProps): JSX.Element {
    const eventsToDisplay = data?.events;
    useLandingAnalytic(ClientEventType.UserViewedAddSpinachToMeetingsStep);

    const [shouldShowNavButtons, setShouldShowNavButtons] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setShouldShowNavButtons(true);
        }, 10 * MILLIS_IN_SECONDS);
    }, []);

    let subtitle = '';
    if (eventsToDisplay) {
        subtitle =
            'Select the meetings you’d like Spinach to join and summarize. The meeting organizer can remove Spinach at any time.';
    }
    const fetchingEventsMessage = eventsToDisplay ? '' : 'Analyzing events...';
    const noEventsFound = eventsToDisplay?.length === 0;
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>Add Spinach to your team meetings</HeaderThreeOnboard>
            <Spacing factor={1 / 2} />

            <ScrollView sidePadding={0} style={{ backgroundColor: 'rgba(0,0,0,0)', background: 'unset' }}>
                <SlidingColumn centered direction={direction}>
                    {!!eventsToDisplay?.length ? (
                        <>
                            <BodyRegularOnboard style={{ textAlign: 'center' }}>{subtitle}</BodyRegularOnboard>

                            <Spacing factor={1 / 2} />
                            <SelectCalendarEventsForSpinach
                                updatingEvents={updatingEvents}
                                isOnboardingFlow={true}
                                hasError={hasError}
                                setShouldShowNotification={() => undefined}
                                shouldShowNotification={false}
                                onEventClick={onEventClick}
                                relevantEvents={eventsToDisplay}
                                onboardingEventsToAddScribeTo={onboardingEventsToAddScribeTo}
                                meetingsAndSuggestions={data}
                            />
                        </>
                    ) : noEventsFound ? (
                        <BodyRegularOnboard style={{ textAlign: 'center' }}>
                            It looks like your calendar is empty. Let's try adding Spinach manually.
                        </BodyRegularOnboard>
                    ) : null}
                </SlidingColumn>
            </ScrollView>
            {!!fetchingEventsMessage || hasError ? (
                <>
                    {hasError ? (
                        <ErrorMessage style={{ marginTop: '5px' }}>
                            We're having some trouble with your calendar. Keep going and you can try again later.
                        </ErrorMessage>
                    ) : !!fetchingEventsMessage ? (
                        <Row centered>
                            <Column centered>
                                <CircularProgress
                                    size={'30px'}
                                    style={{
                                        marginRight: '5px',
                                        marginBottom: '5px',
                                        color: lightTheme.primary.orangeDark,
                                    }}
                                />
                                <BodyRegularOnboard>
                                    <b>{fetchingEventsMessage}</b>
                                </BodyRegularOnboard>
                            </Column>
                        </Row>
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
            {!!eventsToDisplay?.length ? (
                <>
                    <Row centered>
                        <PrimaryButton
                            style={{ zIndex: 1000, marginTop: '30px' }}
                            title={'Done'}
                            onClick={onSubmit}
                            loadingText={loadingMessage}
                            isLoading={!!loadingMessage}
                            disabled={!onboardingEventsToAddScribeTo.length}
                        />
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row centered>
                        <SecondaryButton title="Skip" onClick={onSkip} disabled={!!loadingMessage} />
                    </Row>
                </>
            ) : noEventsFound || !isFetchingEvents || shouldShowNavButtons ? (
                <Row centered style={{ marginTop: '30px' }}>
                    <PrimaryButton title="Add Spinach manually" onClick={onOptForManual} disabled={!!loadingMessage} />
                </Row>
            ) : null}
        </Stationary>
    );
}
