import * as FullStory from '@fullstory/browser';
import { useEffect, useState } from 'react';

import { ClientUser } from '@spinach-shared/models';
import { isLocalStage } from '@spinach-shared/utils';

import { AnonymousUserTracker } from '../utils';
import { RunningContext } from '../zoom';

type FullStoryIdentifyOpts = {
    meetingID?: string;
    where?: RunningContext;
};

export function useUserIdentification(user: ClientUser, options: FullStoryIdentifyOpts = {}) {
    const [hasIdentified, setHasIdentified] = useState(false);

    useEffect(() => {
        if (!isLocalStage() && !hasIdentified) {
            try {
                FullStory.identify(user.spinachUserId, {
                    email: user.email,
                    displayName: user.preferredName,
                    ...options,
                });

                AnonymousUserTracker.trackUser(user.spinachUserId);

                setHasIdentified(true);
            } catch (error) {}
        }
    }, [user]);
}
