import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { StoredSpinachSeries } from '@spinach-shared/utils';

import { getAllStoredSeries } from '../apis';
import { atomStoredSeriesList } from '../atoms';
import { useGlobalAuthedUser } from './useGlobalUser';

export function useGlobalStoredSeriesList() {
    const [storedSeriesListState, setStoredSeriesListState] = useRecoilState(atomStoredSeriesList);

    function setStoredSeriesList(storedSeriesList: StoredSpinachSeries[]) {
        setStoredSeriesListState({
            ...storedSeriesListState,
            storedSeriesList,
        });
    }

    return {
        storedSeriesListState,
        setStoredSeriesListState,
        setStoredSeriesList,
    };
}

export function useStoredSeriesListFetcher({ recurringOnly }: { recurringOnly: boolean }) {
    const { storedSeriesListState, setStoredSeriesListState } = useGlobalStoredSeriesList();

    async function fetch() {
        setStoredSeriesListState({
            ...storedSeriesListState,
            isFetching: true,
        });

        const list = await getAllStoredSeries({ recurringOnly });
        const instances = list.filter((series) => !!series).map((series) => new StoredSpinachSeries(series));

        setStoredSeriesListState({
            ...storedSeriesListState,
            storedSeriesList: instances,
            lastFetched: new Date().toISOString(),
            isFetching: false,
        });
    }

    return { fetch, isLoading: storedSeriesListState.isFetching };
}

export function useFetchStoredSeriesList({ recurringOnly }: { recurringOnly: boolean }) {
    const [user] = useGlobalAuthedUser();
    const { storedSeriesListState, setStoredSeriesListState } = useGlobalStoredSeriesList();
    const { fetch, isLoading } = useStoredSeriesListFetcher({ recurringOnly });

    useEffect(() => {
        async function exec() {
            await fetch();
        }
        exec();
    }, []);

    // Update this local state when the user removes a series from their account
    useEffect(() => {
        setStoredSeriesListState({
            ...storedSeriesListState,
            storedSeriesList: storedSeriesListState.storedSeriesList.filter((series) =>
                user.seriesMetadataList.find((meta) => meta.id === series.id)
            ),
        });
    }, [user.seriesMetadataList]);

    return {
        isLoading,
        storedSeriesListState,
    };
}
