import { CallMade } from '@material-ui/icons';
import styled, { keyframes } from 'styled-components';

import {
    ClientEventType,
    SpinachAPIPath,
    SpinachIntegration,
    TICKET_SOURCE_MAP,
    WebUrlQuery,
} from '@spinach-shared/types';

import { jiraAuth } from '../../../../apis/jira';
import { ReactComponent as AsanaLogo } from '../../../../assets/Asana-logo.svg';
import { ReactComponent as ClickUpLogo } from '../../../../assets/clickup-icon.svg';
import { ReactComponent as JiraLogo } from '../../../../assets/jira-logo.svg';
import { ReactComponent as LinearLogo } from '../../../../assets/linear-logo.svg';
import { ReactComponent as TrelloLogo } from '../../../../assets/trello-logo.svg';
import { ElementId } from '../../../../constants';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection } from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { AiModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';

const TicketCreationBanner = styled(BodyRegularOnboard)`
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
    background-color: ${() => lightTheme.secondary.greenLight};
    color: ${() => lightTheme.neutrals.white};
`;

const glow = keyframes`
    50% { 
        box-shadow: 0px 0px 20px 5px ${lightTheme.primary.orangeLight};
    }
`;
const FocusedHeader = styled(HeaderThree)`
    &:focus {
        animation: ${glow} 1s;
        animation-iteration-count: 2;
    }
`;

export function TicketingIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: AiModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection();

    const track = useExperienceTracking();

    const shouldShowSupportBanner = !!user.isActionItemTicketCreationEnabled;

    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    return (
        <>
            <Row>
                <FocusedHeader tabIndex={-1} id={ElementId.AISettingsTicketingSection}>
                    Ticketing
                </FocusedHeader>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Automatically link and create tickets</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Jira)}>
                <JiraLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Jira</Label>
                {user.isAuthedForJira ? (
                    <ConnectedRowContents
                        label={`Connected: ${user.integrationSettings?.jiraSettings?.jiraAccountUrl?.replace(
                            'https://',
                            ''
                        )}`}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Jira}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveJira);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Jira Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Jira Connect`,
                            });
                            startDetection();
                            jiraAuth(user.spinachUserId, true);
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}

                <span style={{ flexGrow: 1 }} />
                {shouldShowSupportBanner &&
                user.actionItemCreationEnabledTicketSources.includes(TICKET_SOURCE_MAP.Jira) ? (
                    <Row style={{ width: 'unset' }}>
                        <TicketCreationBanner>Now supports ticket creation!</TicketCreationBanner>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Asana)}>
                <AsanaLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Asana</Label>
                {user.isAuthedForAsana ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Asana}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveAsana);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Asana Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Asana Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.AsanaAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
                <span style={{ flexGrow: 1 }} />
                {shouldShowSupportBanner &&
                user.actionItemCreationEnabledTicketSources.includes(TICKET_SOURCE_MAP.Asana) ? (
                    <Row style={{ width: 'unset' }}>
                        <TicketCreationBanner>Now supports ticket creation!</TicketCreationBanner>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Linear)}>
                <LinearLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Linear</Label>
                {user.isAuthedForLinear ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Linear}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveLinear);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Linear Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Linear Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.LinearAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
                <span style={{ flexGrow: 1 }} />
                {shouldShowSupportBanner &&
                user.actionItemCreationEnabledTicketSources.includes(TICKET_SOURCE_MAP.Linear) ? (
                    <Row style={{ width: 'unset' }}>
                        <TicketCreationBanner>Now supports ticket creation!</TicketCreationBanner>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Clickup)}>
                <ClickUpLogo
                    style={{
                        width: '40px',
                        height: '40px',
                        marginLeft: '-7px',
                        marginTop: '-5px',
                        marginRight: '6px',
                        marginBottom: '-5px',
                    }}
                />
                <Label>ClickUp</Label>
                {user.isAuthedForClickUp ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Clickup}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveClickUp);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove ClickUp Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `ClickUp Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.ClickUpAuth}/?${WebUrlQuery.Suid}=${user.spinachUserId}`
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
                <span style={{ flexGrow: 1 }} />
                {shouldShowSupportBanner &&
                user.actionItemCreationEnabledTicketSources.includes(TICKET_SOURCE_MAP.ClickUp) ? (
                    <Row style={{ width: 'unset' }}>
                        <TicketCreationBanner>Now supports ticket creation!</TicketCreationBanner>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
            <Spacing factor={1 / 2} />
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Trello)}>
                <TrelloLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Trello</Label>
                {user.isAuthedForTrello ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Trello}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveTrello);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Trello Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Trello Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.IntegrationAuth.replace(
                                    ':oauthProvider',
                                    SpinachIntegration.Trello
                                )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                                { redirectThroughBackendWhenOnZoom: true }
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
                <span style={{ flexGrow: 1 }} />
                {shouldShowSupportBanner &&
                user.actionItemCreationEnabledTicketSources.includes(TICKET_SOURCE_MAP.Trello) ? (
                    <Row style={{ width: 'unset' }}>
                        <TicketCreationBanner>Now supports ticket creation!</TicketCreationBanner>
                    </Row>
                ) : (
                    <></>
                )}
            </Row>
        </>
    );
}
