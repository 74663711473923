import { CircularProgress } from '@material-ui/core';
import { CallMade, ErrorOutline } from '@material-ui/icons';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, MeetingActionItem, WebUrlQuery, isTicketSource } from '@spinach-shared/types';

import { getAiSummaryJson } from '../../../../apis';
import { postCreateTicket } from '../../../../apis/postCreateTicket';
import { postEditSummaryActionItem } from '../../../../apis/postEditSummaryActionItem';
import { GlobalModal, GlobalModalFrom } from '../../../../atoms';
import { useExperienceTracking, useGlobalAiDashboard, useGlobalAuthedUser, useGlobalModal } from '../../../../hooks';
import { BodyRegular, BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Column, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';

export function ActionItemSummaryComponent({
    actionItem,
    botId,
    setActionItem,
}: {
    actionItem: MeetingActionItem;
    setActionItem: (actionItem: MeetingActionItem) => void;
    botId: string;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [isLoading, setIsLoading] = useState(false);
    const [params] = useSearchParams();
    const [_, setGlobalModal] = useGlobalModal();
    const [failed, setFailed] = useState(false);
    const track = useExperienceTracking();
    const { openIntegrationsSection } = useGlobalAiDashboard();

    const createdTicket = actionItem.ticketData;

    const isAuthedWithTicketSource = user.isUserAuthedWithAValidTicketCreationSource;
    const onClick = async () => {
        if (isLoading) {
            return;
        }
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Action Item Create Ticket',
            IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
            KnownTicketSource: params.get(WebUrlQuery.TicketSource),
        });

        if (!isAuthedWithTicketSource) {
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Authorize User',
                Where: 'Actions Section',
                IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                KnownTicketSource: params.get(WebUrlQuery.TicketSource),
            });
            setGlobalModal(GlobalModal.ConnectTicketSource, { from: GlobalModalFrom.ActionItemSummaryComponent });
            // If no ticket source is available we should open the integraton section for the user
            const ticketSourceFromParams = params.get(WebUrlQuery.TicketSource);
            if (
                !ticketSourceFromParams ||
                !isTicketSource(ticketSourceFromParams) ||
                !user.actionItemCreationEnabledTicketSources.includes(ticketSourceFromParams)
            ) {
                openIntegrationsSection();
            }
            return;
        }

        setIsLoading(true);

        const latestSummary = await getAiSummaryJson(botId, false);
        if ('summaryJson' in latestSummary) {
            const latestActionItem = latestSummary.summaryJson.actionItems?.find((item) => item.id === actionItem.id);
            if (latestActionItem?.ticketData) {
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Sync Ticket Data',
                    Where: 'Actions Section',
                    IsTicketSourceKnown: !!params.get(WebUrlQuery.TicketSource),
                    KnownTicketSource: params.get(WebUrlQuery.TicketSource),
                    LatestActionItemTicketSource: latestActionItem.ticketData.ticketSource,
                });
                setActionItem(latestActionItem);
                setIsLoading(false);
                return;
            }
        }

        track(ClientEventType.AIDashboardActivity, {
            Action: 'Create Ticket',
            Where: 'Actions Section',
            UserAuthedTicketSources: user.authedTicketSources.join(', '),
        });

        const createdTicket = await postCreateTicket({
            title: actionItem.title,
            description: actionItem.context ?? '',
        });

        if (createdTicket?.id) {
            track(ClientEventType.AIDashboardActivity, {
                Action: 'Created Ticket',
                Where: 'Actions Section',
                ActionItemId: actionItem.id,
                TicketSource: createdTicket.ticketSource,
                TicketId: createdTicket.id,
                UserAuthedTicketSources: user.authedTicketSources.join(', '),
            });
            setActionItem({ ...actionItem, ticketData: createdTicket });
            const response = await postEditSummaryActionItem({
                actionItem: {
                    ...actionItem,
                    ticketData: {
                        title: createdTicket.title,
                        id: createdTicket.id,
                        displayName: createdTicket.displayName,
                        ticketSource: createdTicket.ticketSource,
                        ticketUrl: createdTicket.ticketUrl,
                    },
                },
                botId,
            });
            if (!response.success) {
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed To Update Action Item',
                    Where: 'Actions Section',
                    BotId: botId,
                    ActionItemId: actionItem.id,
                    TicketSource: createdTicket.ticketSource,
                    TicketId: createdTicket.id,
                });
                setFailed(true);
            } else {
                track(ClientEventType.AIDashboardActivity, {
                    Action: 'Action Item Ticket Associated',
                    Where: 'Actions Section',
                    ActionItemId: actionItem.id,
                    TicketSource: createdTicket.ticketSource,
                    TicketId: createdTicket.id,
                    BotId: botId,
                    UserAuthedTicketSources: user.authedTicketSources.join(', '),
                });
            }
        } else {
            track(ClientEventType.AIDashboardActivity, {
                Activity: 'Failed To Create Ticket',
                Where: 'Actions Section',
                BotId: botId,
                ActionItemId: actionItem.id,
            });
            setFailed(true);
        }

        setIsLoading(false);
    };

    return (
        <Column>
            <Row style={{ paddingBottom: '5px' }}>
                <BodyRegularOnboard>
                    <span style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                        {actionItem.users.map((user) => user.username).join(', ')}:
                    </span>
                    {actionItem.title}
                </BodyRegularOnboard>
            </Row>
            <BodyRegular>{actionItem.context}</BodyRegular>
            <Spacing factor={1 / 3} />
            {createdTicket ? (
                <SecondaryButton
                    style={{ alignItems: 'center', display: 'flex' }}
                    size={ButtonSize.Small}
                    title={createdTicket.displayName ?? `${createdTicket.id} ${createdTicket.title}`}
                    endIcon={
                        <CallMade style={{ fontSize: '14px', color: lightTheme.primary.green, marginLeft: '5px' }} />
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Action Item Ticket Link',
                            TicketSource: createdTicket.ticketSource,
                            TicketId: createdTicket.id,
                        });
                        URLUtil.openURL(createdTicket.ticketUrl);
                    }}
                />
            ) : failed ? (
                <Row
                    style={{
                        backgroundColor: '#FFA87729',
                        borderRadius: '4px',
                        alignItems: 'center',
                        padding: '10px 8px 10px 2px',
                    }}
                >
                    <ErrorOutline style={{ color: '#F26E24', padding: '0px 8px 3px 3px' }} />
                    <BodyRegularOnboard style={{ fontWeight: 400 }}>
                        Failed to create ticket. Feel free to reach out to support@spinach.io and we'll help fix this!
                    </BodyRegularOnboard>
                </Row>
            ) : (
                <OutlinedButton
                    title={isLoading ? 'Creating Ticket' : '+ Create Ticket'}
                    disabled={isLoading}
                    size={ButtonSize.Mini}
                    preIcon={isLoading ? <CircularProgress size={'14px'} style={{ marginRight: '4px' }} /> : <></>}
                    style={{
                        cursor: isLoading ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                    }}
                    labelStyles={{
                        fontStyle: isLoading ? 'italic' : 'normal',
                    }}
                    isLoading={isLoading}
                    onClick={onClick}
                />
            )}
            <Spacing />
        </Column>
    );
}
