import { TextField } from '@material-ui/core';
import { CallMade, EmailOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

import { ClientEventType, SpinachIntegration } from '@spinach-shared/types';

import { deleteSlackUserDefaultChannel, getUser, patchAllSeriesSettings, patchUser } from '../../../../apis';
import { postSlackDefaultUserChannel } from '../../../../apis/postSlackDefaultUserChannel';
import { ReactComponent as SlackLogo } from '../../../../assets/slack-logo.svg';
import {
    useEmailSummaryToInviterOnly,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useIntegrationDetection,
    usePaymentUrl,
    useProFeatures,
} from '../../../../hooks';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { BodyRegularOnboard, BodySmallOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { ClientLogger, URLUtil, withFullStoryMasking } from '../../../../utils';
import { Anchor, Column, Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { EmailOptions } from '../EmailOptions';
import { AiModalKind, DashboardSection, UseHoverRowProps } from '../types';
import { RemoveIntegrationButton } from './RemoveIntegrationButton';
import { Label } from './common';

export function CommunicationsIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: AiModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setActiveSection, setToastText } = useGlobalAiDashboard();
    const startDetection = useIntegrationDetection();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const { slackState } = useGlobalSlack();
    const { slackChannels } = slackState;
    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(
        user.defaultChannelSelection
    );
    const { getHoverableRowProps, getVisibleRemoveProps } = hoverRowProps;
    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();
    const hasProFeatures = useProFeatures();
    const paymentUrl = usePaymentUrl();

    return (
        <>
            <Row>
                <HeaderThree>Communications</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>How do we communicate with you?</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <Column style={{}}>
                <Row vCenter>
                    <EmailOutlined style={{ fontSize: '25px', marginRight: '15px' }} />
                    <Label>Email</Label>
                </Row>
                <BodySmallOnboard>
                    {hasProFeatures || isEmailInviterSummaryInviterOnly ? (
                        <Row>
                            <span style={{ width: '45px' }} />
                            <Column>
                                <Row vCenter>
                                    <BodyRegularOnboard>Default Recipients:</BodyRegularOnboard>
                                    <EmailOptions
                                        isEmailingIcpOnlySetting={user.metadata.isEmailingIcpOnly}
                                        onSelect={async (isEmailingIcpOnly) => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Selected Email Recipients Option (Integration Section)`,
                                            });

                                            setToastText(
                                                'Your email recipients settings have been applied to all your existing Spinach meetings'
                                            );

                                            try {
                                                await patchUser({
                                                    metadata: {
                                                        isEmailingIcpOnly,
                                                    },
                                                });

                                                await patchAllSeriesSettings({ isEmailingIcpOnly });

                                                const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                                if (updatedUser.user) {
                                                    setUser(updatedUser.user);
                                                }
                                            } catch (error) {
                                                ClientLogger.error('failed to change email recipient settings', {
                                                    spinachUserId: user.spinachUserId,
                                                    isEmailingIcpOnly,
                                                });
                                                setToastText('Something went wrong when updating your email settings.');
                                            }
                                        }}
                                    />
                                </Row>
                                <BodyRegularOnboard>
                                    <i>
                                        You can change this for individual series on the{' '}
                                        <SecondaryButton
                                            title="Meetings"
                                            labelStyles={{ fontStyle: 'italic', fontWeight: 400 }}
                                            color={lightTheme.primary.midnight}
                                            onClick={() => {
                                                setActiveSection(DashboardSection.Meetings);
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Go to Change Series Email Settings',
                                                });
                                            }}
                                            containerStyles={{ display: 'inline-flex' }}
                                        />{' '}
                                        page
                                    </i>
                                </BodyRegularOnboard>
                            </Column>
                        </Row>
                    ) : (
                        <Row>
                            <span style={{ width: '45px' }} />
                            <Column>
                                <BodyRegularOnboard>Everyone on the invite receives the summary.</BodyRegularOnboard>
                                <BodyRegularOnboard>
                                    {' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Click Upgrade Email Options (Integrations Section)`,
                                            });
                                            URLUtil.openURL(paymentUrl, {
                                                redirectThroughBackendWhenOnZoom: true,
                                            });
                                        }}
                                    >
                                        Upgrade to Pro
                                    </Anchor>{' '}
                                    to change the recipients.
                                </BodyRegularOnboard>
                            </Column>
                        </Row>
                    )}
                </BodySmallOnboard>
            </Column>
            <Spacing factor={1 / 2} />
            <Row>
                <Column>
                    <Row>
                        <SlackLogo style={{ width: '25px', height: '25px', marginRight: '15px' }} />
                        <Label>Slack</Label>
                    </Row>
                    <Row>
                        {!user.isAuthedForSlack ? (
                            <OutlinedButton
                                title={'Connect'}
                                onClick={() => {
                                    startDetection();
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Slack Connect`,
                                    });
                                    URLUtil.openURL(slackState.installUrl);
                                }}
                                size={ButtonSize.Mini}
                                endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                labelStyles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        ) : (
                            <Row>
                                <span style={{ width: '45px' }} />
                                <Column>
                                    <Row vCenter {...getHoverableRowProps(SpinachIntegration.Slack)}>
                                        <BodyRegularOnboard>Default channel: </BodyRegularOnboard>
                                        <Autocomplete
                                            id="slack-channel-id-selection"
                                            options={slackChannels}
                                            className={withFullStoryMasking()}
                                            getOptionLabel={(option: { code: string; label: string }) =>
                                                `#${option.label.replaceAll('#', '')}`
                                            }
                                            value={selectedChannel}
                                            size="small"
                                            style={{ width: '280px', transform: 'scale(0.8)' }}
                                            autoComplete
                                            onChange={async (event, channel) => {
                                                setSelectedChannel(channel);

                                                let slackSettings = user.slackSettings;

                                                if (!slackSettings) {
                                                    const freshUserResponse = await getUser();
                                                    if (freshUserResponse.user?.integrationSettings?.slackSettings) {
                                                        slackSettings =
                                                            freshUserResponse.user?.integrationSettings?.slackSettings;
                                                    } else {
                                                        return;
                                                    }
                                                }

                                                if (channel) {
                                                    await postSlackDefaultUserChannel(
                                                        slackSettings.teamId,
                                                        slackSettings.teamType,
                                                        channel.code,
                                                        channel.label
                                                    );
                                                } else {
                                                    await deleteSlackUserDefaultChannel();
                                                }

                                                const updatedUser = await getUser();

                                                if (updatedUser?.user) {
                                                    setUser(updatedUser.user);
                                                }

                                                track(ClientEventType.UserSelectedSlackDefaultChannel, {
                                                    Action: !!channel ? 'setting-channel' : 'removing-channel',
                                                });
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: `Select Slack Default Channel`,
                                                });
                                            }}
                                            ListboxProps={{ style: { transform: 'scale(0.8)', borderRadius: '20px' } }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Pick a channel"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: {
                                                            borderRadius: '20px',
                                                            padding: '4px 20px',
                                                            borderColor: `${lightTheme.primary.green} !important`,
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                        <RemoveIntegrationButton
                                            onClick={() => {
                                                setModalKind(AiModalKind.RemoveSlack);
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: `Open Remove Slack Modal`,
                                                });
                                            }}
                                            {...getVisibleRemoveProps(SpinachIntegration.Slack)}
                                        />
                                    </Row>
                                </Column>
                            </Row>
                        )}
                    </Row>
                </Column>
            </Row>
        </>
    );
}
