import { Error } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, DemoVersion, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postExperienceEvent, postVerifyGoogle } from '../../apis';
import { DemoModal } from '../../atoms';
import {
    useGlobalDemoState,
    useGlobalModal,
    useGlobalUser,
    useLocationalSeriesId,
    useSeriesReality,
} from '../../hooks';
import { lightTheme } from '../../styles';
import { AnonymousUserTracker } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { Notification } from '../stand-up';
import { GoogleLogin } from './react-google-login';

export function LoginWithGoogle() {
    const [, setUser] = useGlobalUser();
    const [, setGlobalModal] = useGlobalModal();
    const [params] = useSearchParams();
    const { setDemoModal } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();
    const [isPrivate, setIsPrivate] = useState(false);
    const deepLinkedSeriesId = useLocationalSeriesId();
    const [notification, setNotification] = useState('');

    useEffect(() => {
        if (notification) {
            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [notification]);

    const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        setNotification('');
        if ('tokenId' in res) {
            const response = await postVerifyGoogle(
                res.tokenId,
                params.get(WebUrlQuery.Experiment),
                deepLinkedSeriesId,
                (params.get(WebUrlQuery.DemoVersion) as DemoVersion) ?? undefined
            );
            if (response.user) {
                setUser(response.user);

                const validatedUser = new ClientUser(response.user);

                AnonymousUserTracker.trackUser(response.user._id);
                AnonymousUserTracker.trackEvent(ClientEventType.UserEmailGoogleVerifySuccess);
                await postExperienceEvent({
                    eventType: ClientEventType.UserEmailGoogleVerifySuccessBackend,
                    payload: validatedUser.toUserIdentityPayload(),
                });

                if (response.isNewUser) {
                    TagManager.trackSignUp(validatedUser);
                    if (validatedUser.isScribeUser) {
                        TagManager.trackGoogleWebAppScribeSignup(validatedUser);
                    }
                }

                if (validatedUser.shouldAuthBeforeDemo) {
                    // hide auth modal and show context modal
                    setDemoModal(DemoModal.PrepareCheckIn);
                } else if (response.user?.metadata?.isAnonymousUser === false) {
                    window.location.href = getWebAppHost();
                }
            }

            // ensure that the web auth router is hidden for existing users logging in via play mode
            setGlobalModal(null);
        }
    };

    const onFailure = (res: { error: string }) => {
        if (res?.error === 'popup_closed_by_user' && isPrivate) {
            setNotification('Sign in with Google not supported on private browsing');
        } else {
            setNotification('Try signing in with Google again or using email verification');
        }
        AnonymousUserTracker.trackEvent(ClientEventType.UserEmailGoogleVerifyFailure, {
            responseCode: res.error,
        });
    };

    const buttonClick = () => {
        AnonymousUserTracker.trackEvent(ClientEventType.UserClickSignInWithGoogle);
    };

    const buttonText = isDemoSeries ? 'Sign up with Google' : 'Sign in with Google';

    return (
        <div
            id="signInButton"
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
            onClick={buttonClick}
        >
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}
                buttonText={buttonText}
                onSuccess={async (response) => {
                    await onSuccess(response);
                }}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                onScriptLoadFailure={(...args: any[]) => {
                    args.forEach((arg) => {
                        if (arg?.error === 'idpiframe_initialization_failed') {
                            setIsPrivate(true);
                        }
                    });
                }}
            />
            <div style={{ position: 'relative', width: '100%' }}>
                <Notification
                    containerStyle={{ position: 'absolute', bottom: 'unset', top: '15px' }}
                    isOpen={!!notification}
                    onClose={() => null}
                    message={notification}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            </div>
        </div>
    );
}
