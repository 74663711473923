import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, HeaderThreeOnboard } from '../../../styles';
import { Column, Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import { BackButtonRow, Stationary, StepProps } from './common';

export type FinishedFlowProps = StepProps & {
    onBack: () => void;
};

export function FinishedFlowReverseTrial({
    onBack,
    direction,
    onSubmit,
    loadingMessage,
}: FinishedFlowProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    useLandingAnalytic(ClientEventType.UserViewedFinishOnboardingReverseTrialStep);
    return (
        <Stationary>
            <BackButtonRow onBack={onBack} />
            <HeaderThreeOnboard>You're all set 🥳</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>
                        Spinach Pro features are available in your dashboard. <br />
                        You have access for the next {user.reverseTrialDaysLeft} days.
                    </BodyBigOnboard>
                    <Column style={{ width: '80%' }}>
                        <Spacing />
                        {/* @NOTE - if we keep this, convert to ul, li elements */}
                        <Row vCenter>
                            <BodyBigOnboard>• Access summaries and transcripts</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Customize who gets the notes</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Edit notes before they get shared</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                    </Column>
                </SlidingColumn>
            </ScrollView>
            <Spacing factor={2} />
            <Row centered>
                <PrimaryButton
                    title={'Go to my dashboard'}
                    onClick={onSubmit}
                    isLoading={!!loadingMessage}
                    loadingText={loadingMessage}
                />
            </Row>
        </Stationary>
    );
}
