import { Modal } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { ClientEventType, TicketSource, WebUrlQuery, isTicketSource } from '@spinach-shared/types';

import { GlobalModal, GlobalModalFrom } from '../../../atoms';
import { ElementId } from '../../../constants';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useGlobalRouting,
    useIntegrationDetection,
    useLandingAnalytic,
} from '../../../hooks';
import { BodyHuge, BodyRegularOnboard, lightTheme, responsiveness } from '../../../styles';
import { openTicketSourceAuth } from '../../../utils';
import { DashboardSection } from '../../spinach-ai/dashboard/types';
import { PrimaryButton } from '../../stand-up';
import { OutlinedButton } from '../../stand-up/OutlinedButton';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

const ResponsiveModalTitle = styled(BodyHuge)`
    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 18px;
    }
`;

function ConnectTicketModalContentFromActionSummaryComponent({ onClose }: { onClose: () => void }) {
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Action: 'Opened Connect Ticket Source Modal',
        FromActionSummaryComponent: true,
        FromEmail: false,
    });
    const track = useExperienceTracking();
    return (
        <>
            <Column style={{ flexGrow: 1, textAlign: 'start' }}>
                <BodyRegularOnboard style={{ width: '100%' }}>
                    Ready to create tickets? You just need to connect your ticketing system and you'll be ready to go.
                </BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard style={{ width: '100%' }}>
                    Choose from any of our supported integrations on this page
                </BodyRegularOnboard>
            </Column>
            <Row style={{ justifyContent: 'center' }}>
                <PrimaryButton
                    title="Got it"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Connect Ticket Source Integration Focus',
                        });
                        const ticketSourceHeader = document.getElementById(ElementId.AISettingsTicketingSection);
                        if (ticketSourceHeader) {
                            ticketSourceHeader.scrollIntoView({ behavior: 'smooth' });
                            ticketSourceHeader.focus();
                            onClose();
                        }
                    }}
                />
            </Row>
        </>
    );
}

function ConnectTicketSourceModalContentFromEmail({
    onClose,
    onConfirmation,
    teamTicketIntegration,
}: {
    onClose: () => void;
    onConfirmation: (ticketSource: TicketSource, spinachUserId: string) => void;
    teamTicketIntegration: TicketSource;
}) {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    useLandingAnalytic(ClientEventType.AIDashboardActivity, {
        Action: 'Opened Connect Ticket Source Modal',
        FromEmail: true,
        FromActionSummaryComponent: false,
        TicketSource: teamTicketIntegration,
    });

    const capitalizedTicketIntegration = (teamTicketIntegration.charAt(0).toUpperCase() +
        teamTicketIntegration.slice(1)) as Capitalize<TicketSource>;

    return (
        <>
            <Column style={{ flexGrow: 1, textAlign: 'start' }}>
                <BodyRegularOnboard style={{ width: '100%' }}>
                    It looks like your team is connected to <b>{capitalizedTicketIntegration}</b>. You just need to
                    connect your account and we can create tickets for you.
                </BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard style={{ width: '100%' }}>
                    Click <b style={{ color: lightTheme.primary.orangeDark }}>Next</b> and we'll connect you.
                </BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard style={{ width: '100%' }}>Want to choose a different system?</BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard style={{ width: '100%' }}>
                    Click <b style={{ color: lightTheme.primary.green }}>Cancel</b> and choose from any of our supported
                    integrations.
                </BodyRegularOnboard>
            </Column>

            <Row style={{ justifyContent: 'center' }}>
                <OutlinedButton
                    title="Cancel"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Close Connect Ticket Source',
                            TicketSource: teamTicketIntegration,
                        });
                        onClose();
                    }}
                />
                <Spacing factor={1 / 2} horizontal={true} />
                <PrimaryButton
                    title="Next"
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Connect Ticket Source',
                            TicketSource: teamTicketIntegration,
                        });
                        onConfirmation(teamTicketIntegration, user.spinachUserId);
                    }}
                />
            </Row>
        </>
    );
}

function ConnectTicketSourceModalContent({
    onClose,
    teamTicketIntegration,
    botId,
    seriesId,
    from,
}: {
    onClose: () => void;
    botId?: string;
    seriesId?: string;
    teamTicketIntegration?: TicketSource;
    from?: GlobalModalFrom;
}) {
    const [params] = useSearchParams();
    const { routeToAIDashboard } = useGlobalRouting();
    const { setActiveSection } = useGlobalAiDashboard();

    const startDetection = useIntegrationDetection(() => {
        if (botId && seriesId) {
            params.append(WebUrlQuery.BotId, botId);
            params.append(WebUrlQuery.SeriesId, seriesId);
        }
        setActiveSection(DashboardSection.Actions);
        routeToAIDashboard({ replace: true }, params);
        onClose();
    });
    function onConfirmation(ticketSource: TicketSource, spinachUserId: string) {
        startDetection();
        openTicketSourceAuth(ticketSource, spinachUserId);
    }
    return (
        <SpinachModalContent onClose={onClose} style={{ overflow: 'hidden', paddingBottom: 'calc(0.10 * 70vmin)' }}>
            <Row centered>
                <ResponsiveModalTitle>
                    <b>Connect Your Account</b>
                </ResponsiveModalTitle>
            </Row>

            {teamTicketIntegration ? (
                <ConnectTicketSourceModalContentFromEmail
                    onClose={onClose}
                    onConfirmation={onConfirmation}
                    teamTicketIntegration={teamTicketIntegration}
                />
            ) : from && from === GlobalModalFrom.ActionItemSummaryComponent ? (
                <ConnectTicketModalContentFromActionSummaryComponent onClose={onClose} />
            ) : (
                <></>
            )}
        </SpinachModalContent>
    );
}

export function ConnectTicketSource(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const modalState = useGlobalModalState();
    const track = useExperienceTracking();
    const [params] = useSearchParams();
    const { from, metadata: { botId, seriesId } = { botId: undefined, seriesId: undefined } } = modalState ?? {};
    let { metadata: { teamTicketIntegration } = { teamTicketIntegration: undefined } } = modalState ?? {};

    if (!teamTicketIntegration) {
        const ticketSourceQueryParam = params.get(WebUrlQuery.TicketSource);
        if (
            ticketSourceQueryParam &&
            isTicketSource(ticketSourceQueryParam) &&
            user.actionItemCreationEnabledTicketSources.includes(ticketSourceQueryParam)
        ) {
            teamTicketIntegration = ticketSourceQueryParam;
        }
    }

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Connect Ticket Source Modal',
            TicketSource: teamTicketIntegration,
            From: from,
        });
        setGlobalModal(null);
    };

    if (!user) {
        return <></>;
    }

    /**
     * @NOTE If there's no team ticket integration set, and there's no `from` value set,
     * or there is a `from` value set, but it's not from the action item summary component,
     * then don't show the modal
     * */
    if (!teamTicketIntegration && (!from || from !== GlobalModalFrom.ActionItemSummaryComponent)) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ConnectTicketSource} onClose={closeModal}>
            <ConnectTicketSourceModalContent
                onClose={closeModal}
                botId={botId ?? undefined}
                seriesId={seriesId ?? undefined}
                teamTicketIntegration={teamTicketIntegration}
                from={from}
            />
        </Modal>
    );
}
