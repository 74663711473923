import { SpinachAPIPath, SummaryJson } from '@spinach-shared/types';

import { postSpinachAPI } from '..';

export async function postEditedSummary(request: {
    summaryJSON: SummaryJson;
    botId: string;
}): Promise<{ success: boolean }> {
    try {
        const response = await postSpinachAPI<{ success: boolean }>(SpinachAPIPath.EditSummary, request);
        if (!response) {
            return { success: false };
        }
        return response;
    } catch {
        return { success: false };
    }
}
