import { CallMade } from '@material-ui/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postLogOut } from '../../../apis';
import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio-getstarted.svg';
import { useExperienceTracking, useGlobalAuthedUser, usePaymentUrl, useProFeatures } from '../../../hooks';
import { BodyRegularOnboard, lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { Anchor, CircleBullet, Column, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardComponent, DashboardSection } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

const ProText = styled(BodyRegularOnboard)`
    color: ${(props) => props.theme.tertiary.greenDark};
    font-weight: 600;
    user-select: none;
`;

const Email = styled(BodyRegularOnboard)`
    font-size: 12px;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export function Sidebar({
    activeSection,
    setActiveSection,
    setIsAddingAdditionalMeetings,
}: DashboardComponent & { setIsAddingAdditionalMeetings: (val: boolean) => void }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const email = user.email;
    const hasProFeatures = useProFeatures();
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
    const track = useExperienceTracking();
    const paymentUrl = usePaymentUrl();
    const daysLeftInTrial = user.reverseTrialDaysLeft;
    const dayOrDays = daysLeftInTrial > 1 ? 'days' : 'day';

    return (
        <Column style={{ height: '100%', width: '300px' }}>
            <SidebarColumn>
                <SpinachLogo
                    style={{
                        height: '40px',
                        marginLeft: '-13px',
                        marginBottom: '22px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                        setActiveSection(DashboardSection.Meetings);
                    }}
                />

                <Row>
                    <Column style={{ width: 'unset' }}>
                        <CircleBullet
                            style={{
                                backgroundColor: hasProFeatures
                                    ? lightTheme.tertiary.greenDark
                                    : lightTheme.primary.orangeDark,
                            }}
                        >
                            {email.charAt(0)}
                        </CircleBullet>
                    </Column>
                    <Column vCentered>
                        <Email>{email}</Email>
                        {user.isOnLiveReverseTrial ? (
                            <Column>
                                <ProText>
                                    Pro Trial - {daysLeftInTrial} {dayOrDays} left
                                </ProText>
                                <Anchor
                                    style={{ color: lightTheme.primary.midnight }}
                                    onClick={() => {
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Click Upgrade to Pro from Trial (Sidebar)`,
                                        });
                                        URLUtil.openURL(paymentUrl, {
                                            redirectThroughBackendWhenOnZoom: true,
                                        });
                                    }}
                                >
                                    Subscribe to Pro
                                </Anchor>
                            </Column>
                        ) : hasProFeatures ? (
                            <ProText>Pro Plan</ProText>
                        ) : (
                            <Anchor
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Click Upgrade to Pro (Sidebar)`,
                                    });
                                    URLUtil.openURL(paymentUrl, {
                                        redirectThroughBackendWhenOnZoom: true,
                                    });
                                }}
                            >
                                Upgrade to Pro
                            </Anchor>
                        )}
                    </Column>
                </Row>

                <Spacing />
                <SecondaryButton
                    title="Upcoming Meetings"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{
                        fontSize: '18px',
                        fontWeight: 700,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={
                        activeSection === DashboardSection.Meetings
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                        setActiveSection(DashboardSection.Meetings);
                        setIsAddingAdditionalMeetings(false);
                    }}
                />
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Meeting Notes"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{
                        fontSize: '18px',
                        fontWeight: 700,
                        display: 'flex',
                        width: '100%',
                        textAlign: 'left',
                    }}
                    color={
                        isSummariesAndTranscriptsSection(activeSection)
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                        setActiveSection(DashboardSection.Summaries);
                    }}
                />
                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Settings"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={
                        activeSection === DashboardSection.Integrations
                            ? lightTheme.primary.greenLight
                            : lightTheme.primary.midnight
                    }
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                        setActiveSection(DashboardSection.Integrations);
                    }}
                />

                <Spacing factor={1 / 2} />
                <SecondaryButton
                    title="Help Center"
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={lightTheme.primary.midnight}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                        URLUtil.openURL(INTERCOM_SUPPORT_URL);
                    }}
                    endIcon={
                        <CallMade
                            style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    }
                />
                <Spacing factor={2} />

                <SecondaryButton
                    title="Log Out"
                    disabled={isLoggingOut}
                    buttonStyles={{ textDecoration: 'none' }}
                    labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                    color={lightTheme.primary.midnight}
                    onClick={async () => {
                        track(ClientEventType.UserClickedLogOut, { Location: 'ai-dashboard' });
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Log Out Button' });
                        setIsLoggingOut(true);
                        const response = await postLogOut();
                        if (response.success) {
                            window.location.href = getWebAppHost();
                        }
                    }}
                />
            </SidebarColumn>
        </Column>
    );
}
