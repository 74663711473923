import { SpinachAPIPath } from '@spinach-shared/types';

import { NotionDatabase, NotionPage } from '@spinach-backend/types';

import { getSpinachAPI } from './getSpinachAPI';

export const fetchNotionParentPages = async () => {
    try {
        const response = await getSpinachAPI<NotionDatabase[]>(SpinachAPIPath.NotionParentPages);
        return response ?? [];
    } catch (error) {
        return [];
    }
};
