import { Modal, TextField, debounce } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    DetailedTicket,
    SpinachUpdateType,
    TICKET_SOURCE_MAP,
    Ticket,
    TicketSource,
    TypedUpdate,
} from '@spinach-shared/types';

import { getSpinachAPI } from '../../apis';
import { useExperienceTracking, useGlobalAsanaIssues, useGlobalSeriesId } from '../../hooks';
import { SetValue } from '../../types';
import { withFullStoryMasking } from '../../utils';
import { CloseButton, Spacer, Spacing } from '../common';
import { LeftBodyLarge, ModalContent } from '../series/common';
import { ScrollShadingCSS } from '../stand-up';
import { AsanaDetailsModal } from './AsanaDetailsModal';
import { AsanaPreviewContainer } from './AsanaPreviewContainer';
import { useSpinachInputStyles } from './BaseInput';

const AsanaModalContent = styled(ModalContent)`
    min-width: 265px;
    width: 80%;
    max-width: 600px; // do we want to start using rems, for that responsive goodness?
`;

const ModalScroll = styled.div`
    overflow-y: scroll;
    max-height: 300px;
    width: 100%;
    ${ScrollShadingCSS};
`;

export function AsanaPickerModal({
    typedUpdate,
    saveFullTypedUpdate,
    searchedAsanaIssue,
    setSearchedAsanaIssue,
    createUpdateEmitter,
    isModalOpen,
    onClose,
}: {
    typedUpdate: TypedUpdate;
    saveFullTypedUpdate?: (update: TypedUpdate) => void;
    searchedAsanaIssue: Ticket | null;
    setSearchedAsanaIssue: SetValue<Ticket | null>;
    createUpdateEmitter?: (update: TypedUpdate) => (text: string) => void;
    isModalOpen: boolean;
    onClose: () => void;
}): JSX.Element {
    const track = useExperienceTracking();
    const seriesId = useGlobalSeriesId();
    const [asanaIssues] = useGlobalAsanaIssues();
    const [suggestedAsanaIssues, setSuggestedAsanaIssues] = useState(asanaIssues);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const classes = useSpinachInputStyles({ value: '', disabled: false });
    const [detailedAsanaIssue, setDetailedAsanaIssue] = useState<DetailedTicket | null>(null);

    const filterDetails = (issue: DetailedTicket | null): Ticket | undefined => {
        if (!issue) {
            return;
        }

        const {
            avatarUrl,
            description,
            id,
            title,
            projectName,
            project = { name: '' },
            jiraAccountUrl,
            ticketUrl,
            displayName,
        } = issue;

        return {
            avatarUrl,
            description,
            id,
            title,
            ticketUrl,
            displayName,
            ticketSource: TICKET_SOURCE_MAP.Asana,
            projectName: projectName || project?.name,
            jiraAccountUrl,
        };
    };

    const onChange = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchParams = e.target.value;

        if (!searchParams) {
            setSearchedAsanaIssue(null);
            setSuggestedAsanaIssues(asanaIssues ?? []);
            return;
        }

        const response = await getSpinachAPI<{ issues: DetailedTicket[] }>(`/asana/suggestions`, {
            params: { query: searchParams },
        });

        if (!response) {
            setSearchedAsanaIssue(null);
            setSuggestedAsanaIssues(asanaIssues ?? []);
            return;
        }

        // we're pulling in the text-only description for now
        // there is a rich_media version if we want that instead
        const issues: DetailedTicket[] = response.issues;

        if (issues.length === 1 && issues[0].id) {
            setSearchedAsanaIssue(issues[0]);
            setSuggestedAsanaIssues(issues);

            return;
        }

        setSearchedAsanaIssue(null);
        setSuggestedAsanaIssues(issues);
    }, 200);

    const issuesToMap = searchedAsanaIssue ? [searchedAsanaIssue] : suggestedAsanaIssues;

    return (
        <Modal open={isModalOpen} onClose={onClose}>
            <AsanaModalContent>
                <Spacer style={{ height: '40px' }} />

                <CloseButton onClick={onClose} />

                <LeftBodyLarge>Add an Asana Task</LeftBodyLarge>
                <TextField
                    InputProps={{ classes: { root: classes.base } }}
                    className={withFullStoryMasking(classes.root)}
                    fullWidth
                    autoFocus
                    placeholder="Search by task name"
                    onChange={onChange}
                />

                {!searchedAsanaIssue ? (
                    <>
                        <Spacing />
                        <LeftBodyLarge>Or select from recents</LeftBodyLarge>
                        <Spacing factor={1 / 3} />
                    </>
                ) : (
                    <Spacing factor={1 / 3} />
                )}
                <ModalScroll>
                    {issuesToMap?.map((issue) => {
                        const onSelect = async () => {
                            onClose();

                            if (!saveFullTypedUpdate) {
                                return;
                            }

                            saveFullTypedUpdate({
                                ...typedUpdate,
                                asanaData: filterDetails(issue),
                                // this triggers a new, empty update to appear
                                text: !typedUpdate.text ? getHelpfulText(typedUpdate, issue) : typedUpdate.text,
                            });

                            if (seriesId) {
                                const SelectionType = searchedAsanaIssue ? 'linked' : 'suggested';

                                track(ClientEventType.AsanaIssueSelected, { SelectionType });
                            }

                            // autofocus the input after selecting a task for it
                            setTimeout(() => {
                                const relevantInput = document.getElementById(
                                    `TU-${typedUpdate.id}`
                                ) as HTMLInputElement | null;
                                relevantInput?.focus();

                                if (!typedUpdate.text) {
                                    relevantInput?.select();
                                }
                            }, 300);
                        };
                        return (
                            <AsanaPreviewContainer
                                key={issue.id}
                                issueData={issue}
                                createUpdateEmitter={createUpdateEmitter}
                                update={typedUpdate}
                                onDetailsClick={() => {
                                    setDetailedAsanaIssue(issue);
                                    setIsDetailsModalOpen(!isDetailsModalOpen);
                                }}
                                onClick={onSelect}
                                interactive={false}
                            />
                        );
                    })}
                </ModalScroll>
                {isDetailsModalOpen && detailedAsanaIssue && (
                    <AsanaDetailsModal
                        isOpen={isDetailsModalOpen}
                        issueData={detailedAsanaIssue}
                        onClose={() => setIsDetailsModalOpen(false)}
                    />
                )}
            </AsanaModalContent>
        </Modal>
    );
}

function getHelpfulText(typedUpdate: TypedUpdate, ticket: Ticket): string {
    switch (typedUpdate.updateType) {
        case SpinachUpdateType.Today:
            return `Working on ${ticket.title}`;
        case SpinachUpdateType.Yesterday:
            return `Made progress on ${ticket.title}`;
        case SpinachUpdateType.Challenge:
            return `Could use a hand on ${ticket.title}`;
        default:
            return `Let’s talk about ${ticket.title}`;
    }
}
