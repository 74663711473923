import { Modal } from '@material-ui/core';
import { useState } from 'react';

import { ClientEventType, IClientUser, SpinachAPIPath, SpinachIntegration } from '@spinach-shared/types';

import { deleteIntegration, deleteUserFromSeries, deleteUserSlackSettings, getUser } from '../../../apis';
import { deleteGoogleCalendarSettings } from '../../../apis/deleteGoogleCalendarSettings';
import { deleteSpinachAPI } from '../../../apis/deleteSpinachAPI';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalNullableStoredSeries } from '../../../hooks';
import { BodyBigOnboard, HeaderThree } from '../../../styles';
import { CloseButton, Column, Row, Spacing, SpinachModalContent } from '../../common';
import { PrimaryButton } from '../../stand-up';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { AiModalKind, ConfirmAiModalProps, UseAiModalProps } from './types';

function useAiModal(
    modalKind: AiModalKind | null,
    setModalKind: (modalKind: AiModalKind | null) => void
): UseAiModalProps {
    const [isLoading, setIsLoading] = useState(false);
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const [storedSeries, setStoredSeries] = useGlobalNullableStoredSeries();

    function onClose() {
        track(ClientEventType.AIDashboardClick, { ClickedOn: `Close ${modalKind} Modal` });
        setModalKind(null);
        setStoredSeries(undefined);
    }

    async function onConfirmHandler(handleConfirmation: () => Promise<IClientUser | null | undefined | void>) {
        setIsLoading(true);

        track(ClientEventType.AIDashboardClick, { ClickedOn: `Confirm ${modalKind} Modal` });

        await handleConfirmation();

        const updatedUser = (await getUser()).user;

        if (updatedUser) {
            setUser(updatedUser);
        }

        setIsLoading(false);
        setModalKind(null);
        setStoredSeries(undefined);
    }

    switch (modalKind) {
        case AiModalKind.RemoveSlack:
            return {
                header: 'Remove Slack',
                body: 'Are you sure you want to disconnect Slack?',
                onConfirm: () => onConfirmHandler(deleteUserSlackSettings),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveGoogleCalendar:
            return {
                header: 'Remove Google Calendar',
                body: 'Are you sure you want to disconnect Google Calendar?',
                onConfirm: () => onConfirmHandler(deleteGoogleCalendarSettings),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveJira:
            return {
                header: 'Remove Jira',
                body: 'Are you sure you want to disconnect Jira?',
                onConfirm: () =>
                    onConfirmHandler(async () => deleteSpinachAPI<undefined>(SpinachAPIPath.JiraDisconnect)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveAsana:
            return {
                header: 'Remove Asana',
                body: 'Are you sure you want to disconnect Asana?',
                onConfirm: () => onConfirmHandler(() => deleteSpinachAPI<undefined>(SpinachAPIPath.AsanaDisconnect)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveLinear:
            return {
                header: 'Remove Linear',
                body: 'Are you sure you want to disconnect Linear?',
                onConfirm: () => onConfirmHandler(() => deleteSpinachAPI<undefined>(SpinachAPIPath.LinearDisconnect)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveClickUp:
            return {
                header: 'Remove ClickUp',
                body: 'Are you sure you want to disconnect ClickUp?',
                onConfirm: () => onConfirmHandler(() => deleteSpinachAPI<undefined>(SpinachAPIPath.ClickUpDisconnect)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveTrello:
            return {
                header: 'Remove Trello',
                body: 'Are you sure you want to disconnect Trello?',
                onConfirm: () => onConfirmHandler(() => deleteIntegration(SpinachIntegration.Trello)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveConfluence:
            return {
                header: 'Remove Confluence',
                body: 'Are you sure you want to disconnect Confluence?',
                onConfirm: () => onConfirmHandler(() => deleteIntegration(SpinachIntegration.Confluence)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveNotion:
            return {
                header: 'Remove Notion',
                body: 'Are you sure you want to disconnect Notion?',
                onConfirm: () => onConfirmHandler(() => deleteIntegration(SpinachIntegration.Notion)),
                onClose,
                isLoading,
            };
        case AiModalKind.RemoveGoogleDrive:
            return {
                header: 'Stop sending to Google Drive',
                body: 'Afterward, we will no longer send your summaries to Google Drive',
                onConfirm: () => onConfirmHandler(() => deleteSpinachAPI(SpinachAPIPath.GoogleDriveDisconnect)),
                onClose,
                isLoading,
            };
        /** @NOTE - this was added pre-emtively on 7/18/2023 in anticipation of a organizer-based removal.  */
        // case AiModalKind.RemoveSpinachFromSeries:
        //     return {
        //         header: 'Remove Spinach from this meeting',
        //         body: (
        //             <>
        //                 <Row>Are you sure you want to remove Spinach from this meeting?</Row>
        //                 <Spacing factor={1 / 2} />
        //                 <Row>
        //                     Spinach will be uninvited from the meeting and this meeting will be hidden from all
        //                     invitees' dashboards.
        //                 </Row>
        //             </>
        //         ),
        //         onConfirm: () =>
        //             onConfirmHandler(async () => {
        //                 if (storedSeries?.scribeMetadata?.iCalUid) {
        //                     await updateScribeOnGoogleCalendarEvent(storedSeries?.scribeMetadata?.iCalUid, false);
        //                     await deleteAllUsersFromSeries(storedSeries.id);
        //                 }
        //             }),
        //         onClose,
        //         isLoading,
        //     };
        case null:
            return {
                header: '',
                body: '',
                onConfirm: async () => {},
                onClose,
                isLoading,
            };
    }
}

export function ConfirmAiModal({ modalKind, setModalKind }: ConfirmAiModalProps): JSX.Element {
    const isOpen = !!modalKind;
    const { onClose, onConfirm, header, body, isLoading } = useAiModal(modalKind, setModalKind);

    return (
        <Modal open={isOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    minWidth: '310px',
                    padding: '30px',
                    minHeight: '270px',
                    overflow: 'initial',
                }}
            >
                <CloseButton
                    style={{
                        left: '3px',
                        top: '9px',
                    }}
                    onClick={onClose}
                />
                <Column>
                    <Spacing factor={1 / 2} />

                    <HeaderThree style={{ textAlign: 'center' }}>{header}</HeaderThree>

                    <Spacing factor={3 / 2} />

                    <BodyBigOnboard style={{ textAlign: 'center' }}>{body}</BodyBigOnboard>
                    <Spacing factor={3 / 2} />

                    <PrimaryButton isLoading={isLoading} onClick={onConfirm} title="Confirm" />

                    <Spacing factor={2 / 3} />

                    <SecondaryButton title="Cancel" onClick={onClose} disabled={isLoading} />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
