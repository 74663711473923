import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import React, { useState } from 'react';

import { FacilitatedViewId } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { useFacilitationTracking, useGlobalFacilitation } from '../../../hooks';
import { BodyRegular, lightTheme } from '../../../styles';
import { Row } from '../../common';

const tooltipText = {
    shareScreen: 'Share this screen in your meeting',
    gaps: 'Here are the gaps identified per individual since the last update',
} as const;

export function Banner(): JSX.Element {
    const [facilitation] = useGlobalFacilitation();

    // type keys of tooltipText
    const [textKey, setTextKey] = useState<keyof typeof tooltipText>();

    const [dismissedKeys, setDismissedKeys] = useState<(keyof typeof tooltipText)[]>([]);

    const track = useFacilitationTracking();

    const currentView = facilitation.currentViewId;

    React.useEffect(() => {
        switch (true) {
            case currentView === FacilitatedViewId.MeetingPrep:
            case currentView === FacilitatedViewId.Icebreaker:
            case !!facilitation.discussionTopics.find((t) => t.viewId === currentView):
            case !!facilitation.participants.find((p) => p.viewId === currentView):
                setTextKey('shareScreen');
                break;
            case currentView === FacilitatedViewId.GapAnalysis:
                setTextKey('gaps');
                break;
            default:
                setTextKey(undefined);
        }
    }, [currentView]);

    const textKeyToDisplay = textKey && dismissedKeys.includes(textKey) ? undefined : textKey;

    return (
        <Row
            className={textKeyToDisplay ? undefined : 'sliding-banner'}
            style={{
                justifyContent: 'center',
            }}
        >
            {textKeyToDisplay && (
                <>
                    <Row
                        style={{
                            padding: '10px',
                            backgroundColor: 'rgba(179, 222, 211, 0.5)',
                            position: 'relative',
                            justifyContent: 'space-between',
                            borderRadius: '5px',
                            width: '90%',
                            alignItems: 'center',
                        }}
                    >
                        <BodyRegular
                            style={{ color: lightTheme.primary.greenDark, alignItems: 'center', display: 'flex' }}
                        >
                            <EmojiObjectsOutlinedIcon style={{ marginRight: '10px' }} fontSize={'small'} />
                            <b>{tooltipText[textKeyToDisplay]}</b>
                        </BodyRegular>
                        <IconButton
                            onClick={() => {
                                track(ClientEventType.AIFacilitationClick, {
                                    ClickedOn: 'Close Banner',
                                    BannerKind: textKeyToDisplay,
                                });
                                setDismissedKeys((k) => [...k, textKeyToDisplay]);
                                setTextKey(undefined);
                            }}
                            size={'small'}
                        >
                            <ClearIcon htmlColor={lightTheme.primary.greenDark} fontSize={'small'} />
                        </IconButton>
                    </Row>
                </>
            )}
        </Row>
    );
}
