import { CalendarEvent, CalendarEventListOpts, SpinachAPIPath } from '@spinach-shared/types';
import { convertHyphenCaseToTitleCase } from '@spinach-shared/utils';

import { MeetingClassification, MeetingEventsAndSuggestions, MeetingSuggestionResults } from '@spinach-backend/types';

import { getSpinachAPI } from '../apis';
import { getAllScribeEmails } from '../hooks/useScribe';

export const fetchCalendarEvents = async (opts?: Partial<CalendarEventListOpts>) => {
    const response = await getSpinachAPI<{ events: CalendarEvent[] }>(SpinachAPIPath.CalendarEvents, {
        params: opts,
        throwOnError: true,
    });

    if (!response?.events?.length || !response.events[0].id) {
        return [];
    }

    return response.events;
};

export const fetchCalendarEventSuggestions = async (
    opts?: Partial<CalendarEventListOpts>
): Promise<MeetingsAndSuggestions> => {
    const response = await getSpinachAPI<MeetingEventsAndSuggestions>(SpinachAPIPath.CalendarEventSuggestions, {
        params: opts,
        throwOnError: true,
    });

    if (!response?.events?.length || !response.events[0].id) {
        return new MeetingsAndSuggestions({
            events: [],
            suggestions: { recurringEventSuggestions: [], oneOffMeetingSuggestions: [] },
        });
    }

    return new MeetingsAndSuggestions(response);
};

export class MeetingsAndSuggestions {
    private _events: CalendarEvent[];
    private _suggestions: MeetingSuggestionResults;
    timedOut: boolean;

    constructor(json: MeetingEventsAndSuggestions) {
        this._events = json.events;
        this._suggestions = json.suggestions;
        this.timedOut = json.suggestions.timedOut ?? false;
    }

    get events(): CalendarEvent[] {
        return this._events;
    }

    get eventsWithoutSuggestions(): CalendarEvent[] {
        return this._events.filter((event) => !!event.iCalUID && !this.suggestionIds.includes(event.iCalUID));
    }

    get combinedMinimalSuggestions(): Required<MeetingClassification>[] {
        return [
            ...this._suggestions.oneOffMeetingSuggestions.filter(
                (sugg): sugg is Required<MeetingClassification> => !!sugg.meetingId && !!sugg.meetingType
            ),
            ...this._suggestions.recurringEventSuggestions.filter(
                (sugg): sugg is Required<MeetingClassification> => !!sugg.meetingId && !!sugg.meetingType
            ),
        ];
    }

    get suggestionIds(): string[] {
        return this.combinedMinimalSuggestions.map((sugg) => sugg.meetingId).filter((id): id is string => !!id);
    }

    get suggestionsWithoutSpinach(): CalendarEvent[] {
        const ids = this.suggestionIds;

        const scribeEmails = getAllScribeEmails();

        const suggestedEvents = this._events.filter((e) => !!e.iCalUID && ids.includes(e.iCalUID));
        const suggestedEventsWithoutSpinach = suggestedEvents.filter(
            (e) =>
                !e.attendees
                    ?.map((a) => a.email)
                    .filter((email): email is string => !!email)
                    .some((email) => scribeEmails.includes(email))
        );
        return suggestedEventsWithoutSpinach;
    }

    isICalUidSuggested(iCalUid: string): boolean {
        return this.suggestionIds.includes(iCalUid);
    }

    getAcceptedSuggestionsByMeetingType(acceptedSuggestions: CalendarEvent[]): Record<string, number> {
        const map = acceptedSuggestions.reduce<Record<string, number>>((acc, curr) => {
            const matchingSuggestion = this.combinedMinimalSuggestions.find((sugg) => sugg.meetingId === curr.iCalUID);

            if (matchingSuggestion) {
                const meetingType = matchingSuggestion.meetingType.toString();
                const titleCaseMeetingType = convertHyphenCaseToTitleCase(meetingType);
                const key = `total${titleCaseMeetingType}MeetingsAdded`;
                acc[key] = (acc[key] ?? 0) + 1;
            }
            return acc;
        }, {});

        return map;
    }

    toJSON(): MeetingEventsAndSuggestions {
        return {
            events: this._events,
            suggestions: this._suggestions,
        };
    }
}
