import { ScribeMeetingType } from '@spinach-shared/types';

export function isMeetingTypeEnabledForEditing(meetingType: ScribeMeetingType | undefined): boolean {
    if (!meetingType) {
        return false;
    }

    const isMeetingTypeSupported =
        meetingType === ScribeMeetingType.Standup || meetingType === ScribeMeetingType.Generic;

    return isMeetingTypeSupported;
}
