import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@material-ui/core';
import { CallMade, ExpandMore, Warning } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';

import { INTERCOM_SUPPORT_URL } from '@spinach-shared/constants';
import {
    ClientEventType,
    KnowledgeBaseIntegration,
    SlackTeamType,
    SpinachIntegration,
    SpinachIntegrations,
} from '@spinach-shared/types';
import { StoredSpinachSeries, isMeetingTypeEnabledForEditing } from '@spinach-shared/utils';

import { getUser, patchSeries } from '../../../../apis';
import { deleteSlackSeriesSettings } from '../../../../apis/deleteSlackSeriesSettings';
import { postSlackChannel } from '../../../../apis/postSlackChannel';
import { GlobalModal } from '../../../../atoms';
import {
    useEmailSummaryToInviterOnly,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useIntegrationDetection,
    usePaymentUrl,
    useProFeatures,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import {
    BodyBigOnboard,
    BodyRegularOnboard,
    BodySmallOnboard,
    ButtonSize,
    Hyperlink,
    lightTheme,
} from '../../../../styles';
import { URLUtil, withFullStoryMasking } from '../../../../utils';
import { Anchor, BootstrapTooltip, Column, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { EmailOptions } from '../EmailOptions';
import { AiModalKind } from '../types';

export function KnowledgeBaseIntegrationDetails({
    storedSeries,
    knowledgeBase,
}: {
    storedSeries: StoredSpinachSeries;
    knowledgeBase: KnowledgeBaseIntegration;
}): JSX.Element {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();

    const confluenceSettings = storedSeries.getIntegrationSettings(SpinachIntegration.Confluence);
    const notionSettings = storedSeries.getIntegrationSettings(SpinachIntegration.Notion);
    const googleDriveSettings = storedSeries.getIntegrationSettings(SpinachIntegration.GoogleDrive);

    const confluenceUrl = user.integrationSettings?.confluenceSettings?.confluenceAccountUrl;
    const confluenceParentPageId = storedSeries.getSummaryParentPageId(SpinachIntegration.Confluence);
    const confluenceParentPageUrl =
        confluenceUrl && confluenceSettings
            ? `${confluenceUrl}/wiki/spaces/${confluenceSettings.defaultSpaceKey}/pages/${confluenceParentPageId}`
            : '';

    const notionParentPageId = notionSettings?.summaryParentPageId;
    const notionParentPageUrl = notionParentPageId
        ? `https://www.notion.so/${notionParentPageId.replaceAll('-', '')}`
        : '';

    const googleDriveParentFolderId = googleDriveSettings?.summaryParentPageId;
    const googleDriveParentPageUrl = googleDriveParentFolderId
        ? `https://drive.google.com/drive/u/0/folders/${googleDriveParentFolderId}`
        : '';

    const knowledgeBaseUrlMap: Record<
        KnowledgeBaseIntegration,
        { url: string; isAuthed: boolean; title: string; parentPageId?: string | number }
    > = {
        [SpinachIntegration.Confluence]: {
            url: confluenceParentPageUrl,
            parentPageId: confluenceParentPageId,
            isAuthed: user.isAuthedForConfluence,
            title: SpinachIntegrations[SpinachIntegration.Confluence].displayName,
        },
        [SpinachIntegration.Notion]: {
            url: notionParentPageUrl,
            parentPageId: notionParentPageId,
            isAuthed: user.isAuthedForNotion,
            title: SpinachIntegrations[SpinachIntegration.Notion].displayName,
        },
        [SpinachIntegration.GoogleDrive]: {
            url: googleDriveParentPageUrl,
            parentPageId: googleDriveParentFolderId,
            isAuthed: user.isAuthedForGoogleDrive,
            title: SpinachIntegrations[SpinachIntegration.GoogleDrive].displayName,
        },
    };

    const { url, parentPageId, title, isAuthed } = knowledgeBaseUrlMap[knowledgeBase];

    return isAuthed || parentPageId ? (
        <>
            <Spacing factor={1 / 3} />
            <Row style={{ alignItems: 'center', paddingBottom: '5px' }}>
                <BodyRegularOnboard>
                    <b>{title}:</b>
                </BodyRegularOnboard>
            </Row>
            <Row>
                <BodySmallOnboard style={{ marginLeft: '10px' }}>
                    <span>
                        Summaries will be saved to{' '}
                        {url && parentPageId ? (
                            <Hyperlink
                                style={{ fontWeight: 'bold' }}
                                onClick={() => {
                                    track(ClientEventType.KnowledgeBaseParentPageUrlClick, {
                                        parentPageId,
                                        knowledgeBase,
                                    });
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Knowledge Base URL Click',
                                        parentPageId,
                                        knowledgeBase,
                                    });
                                    URLUtil.openURL(url);
                                }}
                            >
                                this page
                            </Hyperlink>
                        ) : (
                            'a page prefixed with your meeting name'
                        )}
                        .
                    </span>
                </BodySmallOnboard>
            </Row>
        </>
    ) : (
        <></>
    );
}

export function MeetingDetails({
    isOpen,
    setIsOpen,
    storedSeries,
}: {
    isOpen: boolean;
    storedSeries: StoredSpinachSeries;
    setIsOpen: () => void;
    setStoredSeriesList: (value: StoredSpinachSeries[]) => void;
    storedSeriesList: StoredSpinachSeries[];
    setModalKind: (modalKind: AiModalKind | null) => void;
}): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();

    const hasProFeatures = useProFeatures();
    const { slackState } = useGlobalSlack();
    const startDetection = useIntegrationDetection();
    const {
        slackState: { slackChannels },
    } = useGlobalSlack();
    const track = useExperienceTracking();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();
    let preseelectedChannel: { code: string; label: string } | null = null;
    if (storedSeries.slackChannel?.channelId && storedSeries.slackChannel?.channelName) {
        preseelectedChannel = {
            code: storedSeries.slackChannel?.channelId,
            label: storedSeries.slackChannel?.channelName,
        };
    }

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string } | null>(preseelectedChannel);
    const [, setGlobalStoredSeries] = useGlobalNullableStoredSeries();
    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();
    const [, setGlobalModal] = useGlobalModal();
    const paymentUrl = usePaymentUrl();
    const [currentSeriesEditingFlag, setCurrentSeriesEditingFlag] = useState(
        !!storedSeries.metadata.scribeMetadata?.isHostEditing
    );

    const isMeetingTypeEnabledForEdit = isMeetingTypeEnabledForEditing(storedSeries.scribeMetadata?.meetingType);

    return (
        <Row
            style={{
                marginBottom: '20px',
                marginLeft: '10px',
            }}
        >
            <Accordion expanded={isOpen} onChange={setIsOpen} elevation={4} style={{ width: '80%' }}>
                <AccordionSummary>
                    <Row style={{ alignItems: 'center', padding: '5px' }}>
                        <ExpandMore
                            style={{ transition: '100ms', transform: !isOpen ? 'rotate(270deg)' : 'rotate(360deg)' }}
                        />
                        <BodyBigOnboard
                            style={{ marginRight: '15px', marginLeft: '15px' }}
                            className={withFullStoryMasking()}
                        >
                            <b className={withFullStoryMasking()}>{storedSeries.scribeMetadata?.seriesTitle}</b>
                        </BodyBigOnboard>
                        <span style={{ display: 'flex', flexGrow: 1 }} />
                        {storedSeries.isBotInvitedWithoutLink ? (
                            <BootstrapTooltip title="Add a video link to this calendar event so Spinach can join.">
                                <Warning htmlColor={lightTheme.primary.orangeLight} style={{ zIndex: 100 }} />
                            </BootstrapTooltip>
                        ) : null}
                    </Row>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '20px' }}>
                    <Column style={{ width: '100%' }}>
                        <Row>
                            <BodyRegularOnboard>
                                <b style={{ marginRight: '10px', marginTop: '2px' }}>Edit Summary:</b>
                                <>
                                    {hasProFeatures ? (
                                        <BootstrapTooltip
                                            title={
                                                !isMeetingTypeEnabledForEdit
                                                    ? 'This meeting type is not yet supported for editing.'
                                                    : ''
                                            }
                                            placement="top"
                                        >
                                            <span>
                                                <SpinachSwitch
                                                    checked={
                                                        isMeetingTypeEnabledForEdit ? currentSeriesEditingFlag : false
                                                    }
                                                    disabled={!isMeetingTypeEnabledForEdit}
                                                    onChange={async () => {
                                                        setCurrentSeriesEditingFlag(!currentSeriesEditingFlag);
                                                        track(ClientEventType.AIDashboardClick, {
                                                            ClickedOn: 'Toggle Enable Edit Summary (Meeting Settings)',
                                                            ToggleTo: currentSeriesEditingFlag ? 'Off' : 'On',
                                                        });
                                                        const updatedSeries = await patchSeries(storedSeries.id, {
                                                            metadata: {
                                                                scribeMetadata: {
                                                                    isHostEditing: !currentSeriesEditingFlag,
                                                                },
                                                            },
                                                        });

                                                        if (updatedSeries) {
                                                            const instance = new StoredSpinachSeries(updatedSeries);
                                                            const newList = storedSeriesListState.storedSeriesList.map(
                                                                (series) => {
                                                                    if (series.id === instance.id) {
                                                                        return instance;
                                                                    } else {
                                                                        return series;
                                                                    }
                                                                }
                                                            );
                                                            setStoredSeriesList(newList);
                                                        }
                                                    }}
                                                />
                                                <BodySmallOnboard style={{ marginLeft: '15px', marginTop: '2px' }}>
                                                    Let me edit summaries before they publish.
                                                </BodySmallOnboard>
                                            </span>
                                        </BootstrapTooltip>
                                    ) : (
                                        <Column style={{ marginLeft: '15px' }}>
                                            <BodyRegularOnboard>
                                                Summaries are immediately sent out.{' '}
                                                <Anchor
                                                    onClick={() => {
                                                        track(ClientEventType.AIDashboardClick, {
                                                            ClickedOn: `Click Upgrade Edit Summary (Settings Section)`,
                                                        });
                                                        URLUtil.openURL(paymentUrl, {
                                                            redirectThroughBackendWhenOnZoom: true,
                                                        });
                                                    }}
                                                >
                                                    Upgrade to Pro
                                                </Anchor>{' '}
                                                to edit beforehand.
                                            </BodyRegularOnboard>
                                        </Column>
                                    )}
                                </>
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 3} />
                        <Row>
                            <BodyRegularOnboard>
                                <b>Email:</b>{' '}
                                <>
                                    {/* if some folks received early access to email inviter only but havent paid yet, still give them access */}
                                    {hasProFeatures || isEmailInviterSummaryInviterOnly ? (
                                        <EmailOptions
                                            series={storedSeries}
                                            isEmailingIcpOnlySetting={storedSeries.isEmailingIcpOnly}
                                            onSelect={async (isEmailingIcpOnly) => {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: `Selected Email Recipients Option (Meeting Section)`,
                                                });

                                                await patchSeries(storedSeries.id, {
                                                    metadata: {
                                                        scribeMetadata: {
                                                            isEmailingIcpOnly,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <span>All participants</span> (
                                            <Anchor
                                                onClick={() => {
                                                    track(ClientEventType.AIDashboardClick, {
                                                        ClickedOn: `Click Upgrade Email Options (Meeting Section)`,
                                                    });
                                                    URLUtil.openURL(paymentUrl, {
                                                        redirectThroughBackendWhenOnZoom: true,
                                                    });
                                                }}
                                            >
                                                Upgrade to Pro
                                            </Anchor>{' '}
                                            to limit)
                                        </>
                                    )}
                                </>
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 4} />
                        <Row>
                            <span style={{ width: '70px' }} />
                            <SecondaryButton
                                size={ButtonSize.Small}
                                title={`Manage Additional Recipients (${storedSeries.dailySubscribedEmails.length})`}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Manage additional recipients (Meeting Section)',
                                    });
                                    setGlobalStoredSeries(storedSeries);
                                    setGlobalModal(GlobalModal.SubscribeSeriesOutput);
                                }}
                            />
                        </Row>
                        <Spacing />
                        <Row style={{ alignItems: 'center' }}>
                            <BodyRegularOnboard>
                                <b>Slack:</b>
                            </BodyRegularOnboard>
                            {user.isAuthedForSlack ? (
                                <Autocomplete
                                    id="slack-channel-id-selection"
                                    className={withFullStoryMasking()}
                                    options={slackChannels}
                                    getOptionLabel={(option: { code: string; label: string }) => option.label}
                                    value={selectedChannel}
                                    size="small"
                                    style={{
                                        width: '300px',
                                        marginBottom: '2px',
                                        transform: 'scale(0.8)',
                                    }}
                                    ListboxProps={{ style: { transform: 'scale(0.8)' } }}
                                    autoComplete
                                    onChange={async (event, channel) => {
                                        setSelectedChannel(channel);

                                        track(ClientEventType.SelectSlackChannelInSettingsClick, {
                                            Action: !!channel ? 'setting-channel' : 'removing-channel',
                                        });

                                        let slackSettings = user.slackSettings;

                                        if (!slackSettings?.teamId || !slackSettings?.teamType) {
                                            const freshUserRes = await getUser();
                                            if (freshUserRes.user?.integrationSettings?.slackSettings) {
                                                slackSettings = freshUserRes.user?.integrationSettings?.slackSettings;
                                            } else {
                                                return;
                                            }
                                        }

                                        let updatedSeries;

                                        if (channel) {
                                            updatedSeries = await postSlackChannel(
                                                slackSettings.teamId,
                                                slackSettings.teamType as SlackTeamType,
                                                channel.code,
                                                channel.label.replaceAll('#', ''),
                                                storedSeries.slug
                                            );
                                        } else {
                                            updatedSeries = await deleteSlackSeriesSettings(storedSeries.slug);
                                        }

                                        if (updatedSeries) {
                                            const instance = new StoredSpinachSeries(updatedSeries);
                                            const newList = storedSeriesListState.storedSeriesList.map((series) => {
                                                if (series.id === instance.id) {
                                                    return instance;
                                                } else {
                                                    return series;
                                                }
                                            });
                                            setStoredSeriesList(newList);
                                        }

                                        const updatedUser = await getUser();

                                        if (updatedUser?.user) {
                                            setUser(updatedUser.user);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Pick a channel"
                                            InputProps={{
                                                ...params.InputProps,
                                                style: {
                                                    borderRadius: '20px',
                                                    padding: '4px 20px',
                                                    borderColor: `${lightTheme.primary.green} !important`,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            ) : (
                                <OutlinedButton
                                    containerStyles={{ marginLeft: '10px' }}
                                    title={'Connect'}
                                    disabled={!slackState.installUrl}
                                    onClick={() => {
                                        if (!slackState.installUrl) {
                                            return;
                                        }
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Connect to Slack via Meeting Details`,
                                        });
                                        startDetection();
                                        URLUtil.openURL(slackState.installUrl);
                                    }}
                                    size={ButtonSize.Mini}
                                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                    labelStyles={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            )}
                        </Row>
                        {user.isAuthedForSlack ? (
                            <Row>
                                <BodySmallOnboard style={{ marginLeft: '10px' }}>
                                    Don’t see your channel? To connect a private channel refer to{' '}
                                    <Anchor
                                        onClick={() => {
                                            track(ClientEventType.HowToConnectSlackPrivateChannelClick);
                                            URLUtil.openURL(
                                                `${INTERCOM_SUPPORT_URL}/articles/7967741-slack-integration`,
                                                {
                                                    redirectThroughBackendWhenOnZoom: true,
                                                }
                                            );
                                        }}
                                    >
                                        these instructions
                                    </Anchor>
                                </BodySmallOnboard>
                            </Row>
                        ) : null}
                        <KnowledgeBaseIntegrationDetails
                            knowledgeBase={SpinachIntegration.Confluence}
                            storedSeries={storedSeries}
                        />
                        <KnowledgeBaseIntegrationDetails
                            knowledgeBase={SpinachIntegration.Notion}
                            storedSeries={storedSeries}
                        />
                        <KnowledgeBaseIntegrationDetails
                            knowledgeBase={SpinachIntegration.GoogleDrive}
                            storedSeries={storedSeries}
                        />
                    </Column>
                </AccordionDetails>
            </Accordion>
        </Row>
    );
}
