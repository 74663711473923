import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { NotionDatabase } from '@spinach-backend/types';

import { fetchNotionParentPages } from '../apis';
import { notionPagesAtom } from '../atoms/notion';
import { SetValue } from '../types';
import { useGlobalAuthedUser } from './useGlobalUser';

// reference fetchJiraIssues
export const useGlobalNotionPages = (): [
    notionPages: NotionDatabase[] | null,
    setNotionPages: SetValue<NotionDatabase[] | null>
] => {
    return useRecoilState(notionPagesAtom);
};

export const useNotionPages = (isLoaded: boolean) => {
    const [notionPages, setNotionPages] = useGlobalNotionPages();

    const [user] = useGlobalAuthedUser();

    useEffect(() => {
        async function fetchPages() {
            const pages = await fetchNotionParentPages();
            setNotionPages(pages);
        }
        if (user.isAuthedForNotion && isLoaded) {
            fetchPages();
        }
    }, [user.isAuthedForNotion, isLoaded]);

    return [notionPages];
};
