import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
    BotIdPair,
    DiscussionTopic,
    FacilitatedParticipant,
    Facilitation,
    FacilitationComputedMetadata,
    FacilitationSchema,
    SpinachAPIPath,
    SpinachInsight,
    SummaryJson,
    TeamTicketingMap,
    WebUrlQuery,
} from '@spinach-shared/types';
import { getUniques, isProductionStage } from '@spinach-shared/utils';

import { getAiSummaryJson, postSpinachAPI } from '../apis';
import { getAiFacilitation } from '../apis/getAiFacilitation';
import { patchSpinachAPI } from '../apis/patchSpinachAPI';
import {
    atomAiSummaryJson,
    atomFacilitation,
    atomFacilitationBotIds,
    atomFacilitationComputedMetadata,
    atomFacilitationJiraChangelog,
} from '../atoms';
import { SetValue } from '../types';

export function useGlobalNullableFacilitation(): [Facilitation | null, SetValue<Facilitation | null>] {
    const [facilitation, setFacilitation] = useRecoilState(atomFacilitation);
    React.useEffect(() => {
        const result = FacilitationSchema.safeParse(facilitation);
        if (!result.success) {
            console.error(result.error);
        }
    }, [facilitation]);
    return [facilitation, setFacilitation];
}

export function useGlobalNullableFacilitationComputedMetadata(): [
    FacilitationComputedMetadata | null,
    SetValue<FacilitationComputedMetadata | null>
] {
    const [facilitationComputedMetadata, setFacilitationComputedMetadata] = useRecoilState(
        atomFacilitationComputedMetadata
    );
    return [facilitationComputedMetadata, setFacilitationComputedMetadata];
}

export function useGlobalNullableBotIdPair(): [BotIdPair | null, SetValue<BotIdPair | null>] {
    const [botIdPair, setBotIdPair] = useRecoilState(atomFacilitationBotIds);
    return [botIdPair, setBotIdPair];
}

export function useGlobalNullableJiraChangelog(): [TeamTicketingMap | null, SetValue<TeamTicketingMap | null>] {
    const [botIdPair, setBotIdPair] = useRecoilState(atomFacilitationJiraChangelog);
    return [botIdPair, setBotIdPair];
}

export function useGlobalFacilitation(): [Facilitation, SetValue<Facilitation>] {
    const [facilitation, setFacilitation] = useRecoilState(atomFacilitation);
    React.useEffect(() => {
        const result = FacilitationSchema.safeParse(facilitation as Facilitation);
        if (!result.success) {
            console.error(result.error);
        }
    }, [facilitation]);
    return [facilitation, setFacilitation] as [Facilitation, SetValue<Facilitation>];
}

export function useGlobalBotIdPair(): [BotIdPair, SetValue<BotIdPair>] {
    const [botIdPair, setBotIdPair] = useRecoilState(atomFacilitationBotIds);
    return [botIdPair, setBotIdPair] as [BotIdPair, SetValue<BotIdPair>];
}

let interval: NodeJS.Timeout;

export function useFacilitationSyncing(seriesId: string): {
    facilitation: Facilitation | null;
    botIdPair: BotIdPair | null;
} {
    const [facilitation, setFacilitation] = useGlobalNullableFacilitation();
    const [, setFacilitationComputedMetadata] = useGlobalNullableFacilitationComputedMetadata();
    const [botIdPair, setBotIdPair] = useGlobalNullableBotIdPair();
    const [, setTicketing] = useGlobalNullableJiraChangelog();
    const [, setLastSummaryJson] = useGlobalLastAiSummary();
    const [search] = useSearchParams();
    const testPreviousBotId = isProductionStage() ? null : search.get('previousBotId');
    const testBotId = isProductionStage() ? null : search.get(WebUrlQuery.BotId);

    useEffect(() => {
        async function fetch() {
            const response = await getAiFacilitation(seriesId);

            const previousBotId = testPreviousBotId || response?.previousBotId;
            const botId = testBotId || response?.botId;

            if (response?.facilitation) {
                setFacilitation({
                    ...response?.facilitation,
                    // while we stub this object out on the backend, mongo does not return it as defined since its empty
                    actualMeetingTime: response?.facilitation.actualMeetingTime?.startTime
                        ? response.facilitation.actualMeetingTime
                        : {},
                });
                setFacilitationComputedMetadata(response?.facilitationComputedMetadata);
            }

            if (botId) {
                setBotIdPair({
                    botId,
                    previousBotId,
                });
            }

            if (previousBotId) {
                // const jsonRes = await getAiSummaryJson(response?.previousBotId, false);
                // const jsonRes = await getAiSummaryJson('fd6499c2-0846-4986-8c28-840b383f0ecc', false);
                const jsonRes = await getAiSummaryJson(previousBotId, false);
                if ('summaryJson' in jsonRes) {
                    setLastSummaryJson(jsonRes.summaryJson);
                }
            }

            const ticketResponse = await postSpinachAPI<TeamTicketingMap>(SpinachAPIPath.JiraChangelog, {
                seriesId,
            });

            if (ticketResponse) {
                setTicketing(ticketResponse);
            }
        }

        fetch();
    }, []);

    useEffect(() => {
        if (interval) {
            clearInterval(interval);
        }

        async function syncFacilitationWithBackend(
            facilitation: Facilitation,
            setFacilitation: SetValue<Facilitation | null>,
            botIdPair: BotIdPair
        ) {
            const response = await getAiFacilitation(seriesId, botIdPair.botId, botIdPair.previousBotId);

            if (response?.facilitation) {
                // const updatedFacilitation: Facilitation = {
                //     ...facilitation,
                //     inMeetingInsights: inMeetingInsightsToUpdate,
                //     roundtableWrapup: {
                //         ...facilitation.roundtableWrapup,
                //         insights: response.facilitation.roundtableWrapup.insights,
                //     },
                //     participants: participantsToUpdate,
                //     discussionTopics: topicsToUpdate,
                // };

                setFacilitation((facilitation) => {
                    if (!facilitation) {
                        return null;
                    }

                    const inMeetingInsightsToUpdate = getReconciledInMeetingInsights(
                        response.facilitation.inMeetingInsights,
                        facilitation.inMeetingInsights
                    );

                    const participantsToUpdate: FacilitatedParticipant[] = getReconciledParticipants(
                        response.facilitation.participants,
                        facilitation.participants
                    );

                    const topicsToUpdate: DiscussionTopic[] = getReconciledTopics(
                        response.facilitation.discussionTopics,
                        facilitation.discussionTopics
                    );

                    return {
                        ...facilitation,
                        inMeetingInsights: inMeetingInsightsToUpdate,
                        roundtableWrapup: {
                            ...facilitation.roundtableWrapup,
                            insights: response.facilitation.roundtableWrapup.insights,
                        },
                        discussionTopics: topicsToUpdate,
                        participants: participantsToUpdate,
                    };
                });

                setFacilitationComputedMetadata(response.facilitationComputedMetadata);

                // only sync the properties that the UI can update, omitting pure insights that are send from front-end
                // const persistFacilitationData: Partial<Facilitation> = {
                //     discussionTopics: updatedFacilitation.discussionTopics,
                //     roundtableWrapup: updatedFacilitation.roundtableWrapup,
                //     icebreaker: updatedFacilitation.icebreaker,
                //     participants: updatedFacilitation.participants,
                //     actualMeetingTime: updatedFacilitation.actualMeetingTime,
                //     currentViewId: updatedFacilitation.currentViewId,
                // };

                // await patchSpinachAPI(SpinachAPIPath.Facilitation, persistFacilitationData, {
                //     params: {
                //         botId: botIdPair.botId,
                //     },
                // });
            }
        }

        /** @TODO - only update non-editable fields like insights */
        /** we seem to be overwriting state with old state and need a way to cancel if we update facilitation
         * or if there was a way to keep the facilitation reference up to date
         */
        interval = setInterval(() => {
            if (facilitation && botIdPair) {
                syncFacilitationWithBackend(facilitation, setFacilitation, botIdPair);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [facilitation, setFacilitation, botIdPair]);

    const [lastTimePatched, setLastTimePatched] = useState(0);
    useEffect(() => {
        if (!botIdPair?.botId || !facilitation) {
            return;
        }

        const now = new Date().getTime();

        // if timeout lock is disabling this within a second, bail
        if (now - lastTimePatched < 2000) {
            return;
        }

        setLastTimePatched(new Date().getTime());

        const persistFacilitationData: Partial<Facilitation> = {
            discussionTopics: facilitation.discussionTopics,
            roundtableWrapup: facilitation.roundtableWrapup,
            icebreaker: facilitation.icebreaker,
            participants: facilitation.participants,
            actualMeetingTime: facilitation.actualMeetingTime,
            currentViewId: facilitation.currentViewId,
            inMeetingInsights: facilitation.inMeetingInsights,
        };

        patchSpinachAPI(SpinachAPIPath.Facilitation, persistFacilitationData, {
            params: {
                botId: botIdPair.botId,
            },
        });
    }, [facilitation, botIdPair, lastTimePatched, setLastTimePatched]);

    return { facilitation, botIdPair };
}

/**
 * Some insights come from the server, and some come from the front-end, so we need to combine them properly.
 * This approach assumes that if we have insights on the client that the backend doesnt have, then they can remain
 * at the end of the list (what's presented up top). These will then get synced to the backend and no longer need special treatment
 */
function getReconciledInMeetingInsights(serverSideInsights: SpinachInsight[], clientSideInsights: SpinachInsight[]) {
    const serverSideIds = getUniques(serverSideInsights.map((i) => i.id));
    const clientSideIds = getUniques(clientSideInsights.map((i) => i.id));

    const clientSideIdsNotOnServer = clientSideIds.filter((id) => !serverSideIds.includes(id));
    const clientSideInsightsToPrepend: SpinachInsight[] = clientSideInsights.filter((insight) =>
        clientSideIdsNotOnServer.includes(insight.id)
    );

    const inMeetingInsightsToUpdate = [...serverSideInsights, ...clientSideInsightsToPrepend];

    return inMeetingInsightsToUpdate;
}

// update participant data based on what can be latest on front-end and backend respectively
function getReconciledParticipants(
    serverSideParticipants: FacilitatedParticipant[],
    clientSideParticipants: FacilitatedParticipant[]
) {
    let participantsToUpdate: FacilitatedParticipant[] = clientSideParticipants;
    if (serverSideParticipants.length) {
        participantsToUpdate = serverSideParticipants.map((p) => {
            const existingParticipant = participantsToUpdate.find((ep) => ep.viewId === p.viewId);

            if (!existingParticipant) {
                return p;
            }

            const participantCopy: FacilitatedParticipant = {
                ...p,
                startStops: existingParticipant.startStops,
                addedUpdates: existingParticipant.addedUpdates,
            };
            return participantCopy;
        });
    }
    return participantsToUpdate;
}

// update participant data based on what can be latest on front-end and backend respectively
function getReconciledTopics(serverSideTopics: DiscussionTopic[], clientSideTopics: DiscussionTopic[]) {
    let topicsToUpdate: DiscussionTopic[] = clientSideTopics;
    if (serverSideTopics.length) {
        topicsToUpdate = serverSideTopics.map((p) => {
            const existingTopics = topicsToUpdate.find((ep) => ep.viewId === p.viewId);

            if (!existingTopics) {
                return p;
            }

            const topicCopy: DiscussionTopic = {
                ...p,
                startStops: existingTopics.startStops,
                notes: existingTopics.notes,
            };
            return topicCopy;
        });
    }
    return topicsToUpdate;
}

export function useGlobalLastAiSummary(): [SummaryJson | null, SetValue<SummaryJson | null>] {
    return useRecoilState(atomAiSummaryJson);
}
