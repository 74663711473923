import { ClientEventType } from '@spinach-shared/types';

import { useGlobalAuthedUser, useLandingAnalytic } from '../../../hooks';
import { BodyBigOnboard, BodySmallOnboard, HeaderThreeOnboard } from '../../../styles';
import { Column, Row, SlidingColumn, Spacing } from '../../common';
import { ScrollView } from '../../series';
import { PrimaryButton } from '../../stand-up';
import { Stationary, StepProps } from './common';

export function WelcomeToReverseTrial({ direction, onSubmit, loadingMessage }: StepProps): JSX.Element {
    const [user] = useGlobalAuthedUser();
    useLandingAnalytic(ClientEventType.UserViewedWelcomeToReverseTrialStep);
    return (
        <Stationary>
            <HeaderThreeOnboard>Nice to meet you {user.firstName || ''} 👋</HeaderThreeOnboard>
            <ScrollView sidePadding={0} stretch>
                <SlidingColumn centered direction={direction}>
                    <Spacing />
                    <BodyBigOnboard style={{ fontWeight: 600, width: '100%', textAlign: 'center' }}>
                        Your team has {user.reverseTrialDaysLeft} days of Spinach Pro
                    </BodyBigOnboard>
                    <Spacing factor={1 / 2} />
                    <Column style={{ width: '80%', marginLeft: '10vw' }}>
                        {/* @NOTE - if we keep this, convert to ul, li elements */}
                        <Row vCenter>
                            <BodyBigOnboard>• Unlimited meetings</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Unlimited users</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Access to full transcripts</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Control of who gets your meeting notes</BodyBigOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <BodyBigOnboard>• Edit the notes before sharing</BodyBigOnboard>
                        </Row>
                    </Column>

                    <Spacing factor={2} />
                    <Row centered>
                        <PrimaryButton
                            title={'Got it!'}
                            onClick={onSubmit}
                            isLoading={!!loadingMessage}
                            loadingText={loadingMessage}
                        />
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row vCenter centered>
                        <BodySmallOnboard>
                            <b>What happens when our Pro trial ends?</b>
                        </BodySmallOnboard>
                    </Row>
                    <Row vCenter centered>
                        <BodySmallOnboard>
                            Don't worry. You can still use Spinach free with limited features.
                        </BodySmallOnboard>
                    </Row>
                </SlidingColumn>
            </ScrollView>
        </Stationary>
    );
}
