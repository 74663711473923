import { CallMade } from '@material-ui/icons';
import { useState } from 'react';

import { ClientEventType, SpinachAPIPath, SpinachIntegration, WebUrlQuery } from '@spinach-shared/types';

import ConfluenceLogo from '../../../../assets/integrations/confluence-logo.png';
import GoogleDriveLogo from '../../../../assets/integrations/google-drive.png';
import NotionLogo from '../../../../assets/integrations/notion-logo.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGoogleDriveEnablement,
    useIntegrationDetection,
} from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { Row, Spacing } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { AiModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { NotionIntegrationSettingsModal } from './NotionIntegrationSettings';
import { UnfinishedRowContents } from './UnfinishedRowContents';
import { Label } from './common';

export function KnowledgeBaseIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: AiModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection();
    const isDriveEnabled = useGoogleDriveEnablement();

    const track = useExperienceTracking();

    const [isNotionSettingsOpen, setIsNotionSettingsOpen] = useState(false);

    const startNotionDetection = useIntegrationDetection((updatedUser) => {
        if (updatedUser.isAuthedForNotion) {
            setIsNotionSettingsOpen(true);
        }
    });

    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    return (
        <>
            <Row>
                <HeaderThree>Knowledge Base</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Keep your summaries in a spot</BodyRegularOnboard>
            </Row>

            <Spacing factor={1 / 2} />

            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Confluence)}>
                <img src={ConfluenceLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Confluence</Label>
                {user.isAuthedForConfluence ? (
                    <ConnectedRowContents
                        label={`Connected${
                            user.integrationSettings?.confluenceSettings?.confluenceAccountUrl
                                ? `: ${user.integrationSettings?.confluenceSettings?.confluenceAccountUrl?.replace(
                                      'https://',
                                      ''
                                  )}`
                                : ''
                        }`}
                        isRemoveVisible={hoveredRow === SpinachIntegration.Confluence}
                        onRemoveClick={async () => {
                            setModalKind(AiModalKind.RemoveConfluence);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Confluence Modal`,
                            });
                        }}
                    />
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Confluence Connect`,
                            });
                            startDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.IntegrationAuth.replace(
                                    ':oauthProvider',
                                    SpinachIntegration.Confluence
                                )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                                { redirectThroughBackendWhenOnZoom: true }
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>

            <Spacing factor={1 / 2} />

            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Notion)}>
                <img src={NotionLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                <Label>Notion</Label>
                {user.isAuthedForNotion ? (
                    !user.integrationSettings?.notionSettings?.defaultDatabaseName ? (
                        <UnfinishedRowContents
                            label={`Unfinished`}
                            isRemoveVisible={hoveredRow === SpinachIntegration.Notion}
                            onRemoveClick={async () => {
                                setModalKind(AiModalKind.RemoveNotion);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Remove Notion Modal`,
                                });
                            }}
                            onSettingsClick={async () => {
                                setIsNotionSettingsOpen(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Notion Settings`,
                                });
                            }}
                        />
                    ) : (
                        <ConnectedRowContents
                            label={
                                user.integrationSettings?.notionSettings?.defaultDatabaseName
                                    ? `Connected: ${user.integrationSettings?.notionSettings?.defaultDatabaseName}`
                                    : 'Connected'
                            }
                            isRemoveVisible={hoveredRow === SpinachIntegration.Notion}
                            onRemoveClick={async () => {
                                setModalKind(AiModalKind.RemoveNotion);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Remove Notion Modal`,
                                });
                            }}
                            onSettingsClick={async () => {
                                setIsNotionSettingsOpen(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Notion Settings`,
                                });
                            }}
                        />
                    )
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Notion Connect`,
                            });
                            startNotionDetection();
                            URLUtil.openURL(
                                `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.IntegrationAuth.replace(
                                    ':oauthProvider',
                                    SpinachIntegration.Notion
                                )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                                { redirectThroughBackendWhenOnZoom: true }
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>
            {isDriveEnabled ? (
                <>
                    <Spacing factor={1 / 2} />

                    <Row vCenter {...getHoverableRowProps(SpinachIntegration.GoogleDrive)}>
                        <img src={GoogleDriveLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                        <Label>Google</Label>
                        {user.isAuthedForGoogleDrive ? (
                            <ConnectedRowContents
                                label={'Connected'}
                                isRemoveVisible={hoveredRow === SpinachIntegration.GoogleDrive}
                                onRemoveClick={async () => {
                                    setModalKind(AiModalKind.RemoveGoogleDrive);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Open Remove Google Drive Modal`,
                                    });
                                }}
                            />
                        ) : (
                            <OutlinedButton
                                title={'Connect'}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Google Drive Connect`,
                                    });
                                    track(ClientEventType.UserAuthorizeGoogleDriveClick);
                                    startDetection();
                                    URLUtil.openURL(
                                        `${process.env.REACT_APP_AUTH_URL}${SpinachAPIPath.GoogleDriveAuth}`
                                    );
                                }}
                                size={ButtonSize.Mini}
                                endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                labelStyles={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        )}
                    </Row>
                </>
            ) : null}

            <NotionIntegrationSettingsModal
                isNotionSettingsOpen={isNotionSettingsOpen}
                setIsNotionSettingsOpen={setIsNotionSettingsOpen}
            />
        </>
    );
}
